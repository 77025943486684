import { login, logout } from '@/api/login'
import { getUserInfo } from '@/api/common'
import { addAuth, removeAuth } from '@/utils/auth'
import { configList } from '@/api/selectionOfRecord.js'
import Lockr from 'lockr'
const user = {
  state: {
    permission: {}, // 权限信息
    userInfo: null, // 用户信息
    countMyTask: 0
  },

  mutations: {
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_PERMISSIN: (state, permission) => {
      state.permission = permission
    },
    SET_COUNTTASK: (state, countMyTask) => {
      state.countMyTask = countMyTask
    },
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then(data => {
            Lockr.set('access_pp_token', data.data['access_token'])
            addAuth(data.data['access_token'])
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 获取用户信息
    GetUserInfo ({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(response => {
            console.log('==============>>>>>>>>>>>>', response.user)
            commit('SET_USERINFO', response.user)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}

export default user
