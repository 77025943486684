<template>
  <div style="height: 100%">
    <flexbox v-loading="loading"
             direction="column"
             align="stretch"
             class="d-container">
      <c-r-m-detail-head :title="detailData.flowNo"
                         :head-details="headDetails"
                         @hideView="hideView">
        <div slot="right"
             class="header">
          <el-button v-if="
              (detailData.status == '0' || detailData.status == '5') &&
              detailData.applicantId == userInfo.userId
            "
                     type="primary"
                     size="medium"
                     icon="el-icon-extendshouquan1"
                     @click="openEditView">Edit</el-button>
        </div>
        <div class="busi-line" />
      </c-r-m-detail-head>
      <div id="follow-log-content"
           class="t-loading-content">
        <basic-info :detail="detailData"
                    :list="channelInfoList"
                    itemWidth="25%"
                    labelWidth="200px">
        </basic-info>
        <basic-info :detail="detailData"
                    :list="projectInfoList"
                    itemWidth="25%"
                    labelWidth="200px">
        </basic-info>
        <!-- <create-sections
          v-if="detailData.depositStatus == '0'"
          title="凭证附件"
          style="margin-top: 30px"
        >
          <el-table
            :data="detailData.sysFileEntityList"
            stripe
            border
            style="width: 100% margin-top: 5px"
          >
            <el-table-column
              prop="fileName"
              min-width="200px"
              :label="$t('components.c2')"
            ></el-table-column>
            <el-table-column
              prop="createdByName"
              :label="$t('components.c3')"
            ></el-table-column>
            <el-table-column prop="createdDate" :label="$t('components.c4')">
              <template slot-scope="scope">{{
                scope.row.createdDate | dateformat
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('components.c5')">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-if="scope.row.fileUrl"
                  @click="down(scope.row)"
                  size="small"
                  >{{ $t("components.c6") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </create-sections> -->
        <create-sections title="Product List"
                         style="margin-top: 30px">
          <el-table :data="detailData.list"
                    stripe
                    border
                    highlight-current-row
                    class="productInfo"
                    style="width: 100%; margin-top: 5px">
            <el-table-column label="Product Line"
                             prop="productLine">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.productLine || "--" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Special Requirement & Settings"
                             prop="settings">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.settings || "--" }}
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="Quantity" prop="quantity">
            </el-table-column> -->
          </el-table>
        </create-sections>
        <!-- <create-sections :title="$t('approval.a14')" style="margin-top: 30px">
          <flexbox align="stretch" justify="flex-start">
            <el-timeline v-if="commentList.length != 0" style="width: 100%">
              <div v-for="(item, index) in commentList" :key="index">
                <el-timeline-item
                  v-for="(activity, index) in item.child"
                  :key="index"
                  :icon="activity.icon"
                  :type="activity.type"
                  :color="activity.color"
                  :size="activity.size"
                  :hide-timestamp="activity.show"
                  :timestamp="activity.timestamp"
                  placement="top"
                >
                  <el-card
                    v-if="activity.show"
                    style="border: 1px solid #d7e8f4; background: #f7fbfe"
                  >
                    <div slot="header" class="clearfix">
                      <p
                        style="
                          width: 940px;
                          display: inline-block;
                          line-height: 20px;
                        "
                      >
                        <span>{{ activity.userName }}</span>
                        <span style="float: right">{{
                          activity.createdDate
                        }}</span>
                      </p>
                    </div>
                    <div class="card-content">
                      <p>{{ activity.fullMessage }}</p>
                    </div>
                  </el-card>
                </el-timeline-item>
              </div>
            </el-timeline>
            <div
              v-if="commentList.length == 0"
              style="
                color: #909399;
                margin-top: 20px;
                text-align: center;
                width: 100%;
              "
            >
              {{ $t("task.44") }}
            </div>
          </flexbox>
        </create-sections> -->
      </div>
    </flexbox>
    <c-r-m-create-view :crmType="rowType"
                       :visible.sync="showCreate"
                       :action="createActionInfo"
                       @save-success="createEditSuccess"></c-r-m-create-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CRMDetailHead from "@c/CRMDetailHead";
import CreateView from "@c/CreateView";
import BasicInfo from "@c/BasicInfo";
import moment from "moment";
import { findDIctName, moneyFormat, findFullName } from "@/utils";
import { getProjectdetail, getEpaProjectdetail } from "@/api/allProject";
import { listCommentByBusinessKey } from "@/api/approval";
export default {
  name: "projectSaveDetails",
  components: {
    CRMDetailHead,
    CreateView,
    BasicInfo,
  },
  props: {
    // 详情信息id
    id: [String, Number],
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data () {
    return {
      loading: false,
      detailData: [], // 当前页面详情数据
      crmType: "ProjectSaveDetil",
      channelInfoList: [], // basicinfo的基本信息
      projectInfoList: [],//项目信息
      headDetails: [],
      rowType: "",
      commentList: [],
      showCreate: false,
      createActionInfo: {
        type: "update",
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted () {
    this.getDetail();
    console.log(this.userInfo);


  },
  methods: {
    //旧流程若获取不到distributorReseller则不i展示
    setChannelInfoList () {
      const distributorResellerIndex = 3
      const lastIndex = 4
      if (!this.channelInfoList[0].child[distributorResellerIndex][0].label) {
        //若流程无法获取distributorReseller字段，删除该字段html中占位
        this.channelInfoList[0].child[distributorResellerIndex].splice(0, 1)
        //将下项字段递补
        this.channelInfoList[0].child[distributorResellerIndex].splice(1, 0, this.channelInfoList[0].child[lastIndex][0])
        //删除递补字段
        this.channelInfoList[0].child[lastIndex].splice(0, 1)
      }
    },
    hideView () {
      this.$emit("hide-view");
    },
    infoFormat (list) {
      list.map((ele) => {
        ele.child &&
          ele.child.map((olo) => {
            olo.map((e) => {
              e.name = e.name || "--";
            });
          });
      });
    },
    getDetail () {
      this.loading = true;
      let params = {
        id: this.id,
        channelId: this.$store.state.user.userInfo.channelId,
        userName: this.$store.state.user.userInfo.username,
        flowNo: this.row.flowNo
      };
      if (
        this.row.flowName &&
        this.row.flowName == "Renew Project Registration"
      ) {
        getEpaProjectdetail(params)
          .then((res) => {
            this.headDetails = [
              {
                title: this.$t("approval.a4"),
                value: res.rows.applicant,
              },
              {
                title: this.$t("detail.d12"),
                value: findDIctName("flow_status", res.rows.status),
              },
              {
                title: this.$t("approval.a6"),
                value: res.rows.currentName,
              },
            ];
            this.detailData = res.rows;
            this.loading = false;
            // if (res.rows.flowId) {
            //   this.getComment();
            // }
            this.getBaiscInfo();
          })
          .catch((err) => {
            // this.$message({
            //   type: "error",
            //   message: err.msg,
            // });
            this.loading = false;
          });
      } else {
        getProjectdetail(params)
          .then((res) => {
            console.log(res);
            this.headDetails = [
              {
                title: this.$t("approval.a4"),
                value: res.rows.applicant,
              },
              {
                title: this.$t("detail.d12"),
                value: findDIctName("flow_status", res.rows.status),
              },
              {
                title: this.$t("approval.a6"),
                value: res.rows.currentName,
              },
            ];
            this.detailData = res.rows;
            this.loading = false;
            // if (res.rows.flowId) {
            //   this.getComment();
            // }
            this.getBaiscInfo();
          })
          .catch((err) => {
            // this.$message({
            //   type: "error",
            //   message: err.msg,
            // });
            this.loading = false;
          });
      }
    },
    totalDeposit () {
      if (this.detailData.depositStatus == "0") {
        return (
          this.detailData.productEntities &&
          this.detailData.productEntities.reduce(
            (money, item) => (money += item.itemDeposit * item.itemNum),
            0
          )
        );
      } else {
        return 0;
      }
    },
    getBaiscInfo () {
      console.log(this.detailData);
      this.channelInfoList = [
        {
          title: "Channel Information",
          child: [
            [
              {
                label: this.$t('allEquipment.partner'),
                name: this.detailData.applicantChannelName
              },
              {
                label: this.$t('allEquipment.applicant'),
                name: this.detailData.applicant
              }
            ],
            [
              {
                // label: 'Address',
                label: this.$t('allEquipment.address'),
                name: this.detailData.address
              },
              {
                label: this.$t('allEquipment.mobile'),
                name: this.detailData.mobile
              }
            ],
            [
              {
                label: this.$t('allEquipment.email'),
                name: this.detailData.applicantEmail
              },
              {
                label: this.$t('allEquipment.sangforOffice'),
                name: findFullName(this.detailData.deptId, this.$store.state.dict.dict.deptOptions,
                  'deptId',
                  'list',
                  true, true)
              }
            ],
            [
              {
                label: this.detailData.distributorReseller === 'Distributor' ? this.$t('allEquipment.distribute') : this.$t('allEquipment.reseller'),
                name: this.detailData.distributorReseller ? this.detailData.distributorReseller === 'Distributor' ? this.detailData.distributor : this.detailData.channel : ''
              },
              {
                label: this.detailData.distributorReseller === 'Distributor' ? this.$t('allEquipment.distribute') + this.$t('allEquipment.email') : this.$t('allEquipment.reseller') + this.$t('allEquipment.email'),
                name: this.detailData.channelEmail
              },

            ],
            [
              {
                label: this.$t('allEquipment.channelProjectOwner'),
                name: this.detailData.channelProjectBy
              },
              {
                label: this.$t('allEquipment.channelProjectOwnerEmail'),
                name: this.detailData.channelProjectEmail
              }
            ]
          ]
        }
        // this.detailData.depositStatus == "1" && {
        //   title: "项目资料",
        //   itemWidth: "50%",
        //   child: [
        //     [
        //       {
        //         label: this.$t("detail.customerName"), // 客户
        //         name: this.detailData.customerName,
        //       },
        //       {
        //         label: this.$t("detail.projectName"), // 项目
        //         name: this.detailData.businessName,
        //       },
        //     ],
        //     [
        //       {
        //         label: "项目所属阶段", // 项目所属阶段
        //         name: this.detailData.percent,
        //       },
        //       {
        //         label: "项目金额", // 项目金额
        //         name: moneyFormat(this.detailData.money),
        //       },
        //     ],
        //     [
        //       {
        //         label: "申请原因", // 申请原因
        //         name: this.detailData.pursueReason,
        //       },
        //     ],
        //   ],
        // },
        // {
        //   title: this.$t("allEquipment.logisticsInformaion"), // "物流信息",
        //   itemWidth: "50%",
        //   child: [
        //     [
        //       {
        //         label: this.$t("allEquipment.postcode"), // "邮编",
        //         name:
        //           this.detailData.logisticsEntity &&
        //           this.detailData.logisticsEntity.recipientsPostcode,
        //       },
        //       {
        //         label: this.$t("allEquipment.shippingAddress"), // "收货地址",
        //         name:
        //           this.detailData.logisticsEntity &&
        //           this.detailData.logisticsEntity.recipientsAddress,
        //       },
        //     ],
        //     [
        //       {
        //         label: this.$t("allEquipment.deliveryMethod"), // "发货方式",
        //         name:
        //           this.detailData.logisticsEntity &&
        //           this.detailData.logisticsEntity.sendType == "0"
        //             ? this.$t("allEquipment.normalMode") // "正常发货"
        //             : this.$t("allEquipment.sfExpress"), // "顺丰发货",
        //       },
        //       {
        //         label: this.$t("allEquipment.recipient"), // "收件人",
        //         name:
        //           this.detailData.logisticsEntity &&
        //           this.detailData.logisticsEntity.recipients,
        //       },
        //     ],
        //     [
        //       {
        //         label: this.$t("other.76"), // "联系电话",
        //         name:
        //           this.detailData.logisticsEntity &&
        //           this.detailData.logisticsEntity.recipientsPhone,
        //       },
        //       {
        //         label: this.$t("detail.remark"), // "备注",
        //         name:
        //           this.detailData.logisticsEntity &&
        //           this.detailData.logisticsEntity.remark,
        //       },
        //     ],
        //   ],
        // },
      ];
      this.projectInfoList = [
        {
          title: "Project Information",
          child: [
            // [
            //   {
            //     label: "Project Name",
            //     name: this.detailData.projectName,
            //   },
            //   {
            //     label: "Status",
            //     name: findDIctName("flow_status", this.detailData.status),
            //   },
            // ],
            [
              {
                label: this.$t('allEquipment.customer'),
                name: this.detailData.company,
              },
              // {
              //   label: "Stage",
              //   name: findDIctName("PpProjectStage", this.detailData.stage),
              // },
            ],
            [
              {
                label: this.$t('allEquipment.customerBudget'),
                name: this.detailData.customerBudget,
              },
              {
                label: this.$t('allEquipment.expectedDate'),
                name: moment(this.detailData.expectedPoDate).format(
                  "YYYY-MM-DD"
                ),
              },
            ],
            [
              {
                label: this.$t('allEquipment.industry'),// Industry
                name: findFullName(
                  this.detailData.industry,
                  this.$store.state.dict.dict.customer_business,
                  "value",
                  "list"
                ),
              },
              {
                label: this.$t('allEquipment.address'),
                name: this.detailData.address,
              },
            ],
            [
              {
                label: this.$t('allEquipment.countryRegion'),
                name: findDIctName("country", this.detailData.country),
              },
              {
                label: this.$t("detail.scene"),
                name: findDIctName('project_scene',
                  this.detailData.businessScene,
                ),
              },
            ],
            [
              {
                label: this.$t('allEquipment.contactPerson'),
                name: this.detailData.contactPerson,
              },
              {
                label: this.$t('allEquipment.position'),
                name: this.detailData.position,
              },
            ],
            [
              {
                label: this.$t('allEquipment.email'),
                name: this.detailData.email,
              },
              {
                label: this.$t('allEquipment.telFax'),
                name: this.detailData.tel,
              },
            ],
            [
              {
                label: this.$t('allEquipment.mobile'),
                name: this.detailData.mobile,
              },
              {
                label: this.$t('allEquipment.reason'),
                name: this.detailData.reason ? this.detailData.reason : "--",
              },
            ],
            [
              {
                label: this.$t('allEquipment.isRenew'),
                name: findDIctName('commonType', this.detailData.isRenew)
              },
            ],
          ],
        },
      ];
      this.setChannelInfoList()
      if (this.detailData.depositStatus == "0") {
        this.channelInfoList[0].child[0].push({
          label: "所需押金", // 所需押金
          name: this.totalDeposit(),
        });
      }
      this.infoFormat(this.channelInfoList);
    },
    getComment () {
      listCommentByBusinessKey(this.detailData.flowId).then((res) => {
        this.commentList = [];
        let commentList = res.rows;
        _.uniq(
          commentList.map((e) => {
            e.sign = e.time.slice(0, 10);
            return e.time.slice(0, 10);
          })
        ).map((ele) => {
          let obj = {
            temp: ele,
            child: [
              {
                timestamp: ele,
                show: false,
                size: "large",
              },
            ],
          };
          commentList.map((e) => {
            if (e.sign == ele) {
              e.show = true;
              e.createdDate = moment(e.time).format("YYYY-MM-DD HH:mm:ss");

              (e.showComment = false), obj.child.push(e);
            }
          });
          this.commentList.push(obj);
        });
      });
    },
    down (item) {
      var a = document.createElement("a");
      var filename = item.fileName;
      a.href = location.protocol + "//" + item.fileUrl;
      a.download = filename;
      a.click();
    },
    openEditView () {
      this.showCreate = true;
      this.rowType = "ppProject";
      this.createActionInfo.data = this.detailData;
      this.createActionInfo.deptId = this.detailData.deptId;
    },
    createEditSuccess (type) {
      window.console.log("xxx");
      this.getDetail();
      this.detailHeadHandle(type);
    },
    /** 顶部头 操作 */
    detailHeadHandle (data) {
      window.console.log("data", data);
      if (data.saveType == "save") {
        this.hideView();
      }
      this.$emit("handle", data);
    },
    down (item) {
      var a = document.createElement("a");
      var filename = item.fileName;
      // a.href = "http://" + item.fileUrl;
      a.href = location.protocol + "//" + item.fileUrl;
      a.download = filename;
      a.click();
    },
  },
};
</script>

<style lang="less" scoped>
.d-container {
  height: 100%;

  .header {
    display: flex;
    align-items: center;
  }

  .t-loading-content {
    flex: 1;
    overflow: auto;
    margin-top: 15px;
  }
}

.el-button {
  padding: 10px 11px;
}
.busi-line {
  position: absolute;
  bottom: 0;
  left: 17px;
  right: 17px;
  height: 1px;
  background-color: #e6e6e6;
}
</style>