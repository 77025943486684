module.exports = {
  login: {
    userId: 'Log in with username',
    username: 'Sign in with your ID(Email address)',
    usernamePlaceholder: 'Email address',
    password: 'Password',
    passwordPlaceholder: 'Please enter your password',
    validCode: 'Captcha',
    validCodePlaceholder: 'Please enter the Captcha',
    signIn: 'Sign In'
  },
  common: {
    btn: {
      edit: 'Edit',
      update: 'Update',
      delete: 'Delete',
      more: 'More',
      editFiles: 'Edit Files',
      addFiles: 'Add Files',
      detail: 'Details',
      // new
      sure: 'Confirm',
      cancel: 'Cancel',
      save: 'Save',
      down: 'Download',
      editFollow: 'Edit',
      delFollow: 'Delete',
      handle: 'Operate',
      reset: 'Reset',
      won: "Won",
      lost: 'Lost',
      projectWon: 'Project won',
      porjectLost: 'Porject lost',
      inProgress: 'Project in progress',
      overdue: 'Overdue'
    }
  },
  PersonCenter: {
    1: 'Back',
    2: 'Dept.：',
    3: 'Title：',
    4: 'Basic info.',
    5: 'Male',
    6: 'Female',
    7: 'Name',
    8: 'Employee ID',
    9: 'Email',
    10: 'Dept.',
    11: 'Title',
    12: 'Phone'
  },
  sceneCreate: {
    1: 'Scenario',
    2: 'There are duplicates in the filter！',
    3: 'Filter Management',
    4: 'Set as default',
    5: 'Cancel',
    6: 'Confirm',
    7: 'Edit Scenario',
    8: 'Please enter scenario (maxi. 30 bits)',
    9: 'Please select the field name to filter',
    10: 'Please select a range',
    11: 'Please select a filter',
    12: 'Please enter filter',
    14: 'Equal to',
    15: 'Not equal to',
    16: 'Include',
    17: 'Not include',
    18: 'Start Date',
    19: 'End Date',
    27: 'Scenario cannot be null！',
    28: 'The field name to be filtered cannot be null!',
    29: 'Please enter the value of the filter！',
    30: 'Successfully edited',
    31: 'Successfully created',
    32: 'Confirm to delete?',
    33: 'Must be not null'
  },
  sceneSet: {
    1: 'You can manage labels by dragging',
    2: 'Labels displayed',
    3: 'Set as default label',
    4: 'Hidden label',
    5: 'Cancel',
    6: 'Save',
    7: 'Scenario Management',
    8: 'Successfully done',
    9: 'Successfully deleted',
    10: 'Failed to delete the equipment application approval',
    11: 'Failed to delete draft'
  },
  filter: {
    scene: 'Scenario',
    advancedFilter: 'Advanced Filter',
    addFilter: 'Filter Management',
    saveScene: 'Save As Scenario',
    addScene: 'New Scenario',
    manage: 'Manage',
    //new
    condition: 'select terms',
    f11: 'Successfully done',
    f13: 'Prompt',
    f68: ' Null is not allowed',
    f69: 'Please enter number',
    f73: 'Please chooes product',
    f79: 'Pending',
    f80: 'Approved',
    f81: 'Reject',
    f100: 'Upload failed'
  },
  detail: {
    customerName: 'Customer Name',
    projectName: 'Project Name',
    remark: 'Remarks',
    productName: 'Product',
    productType: 'Product Type',
    accountCustomer: 'Associated Customer',
    associatedProject: 'Associated Project',
    projectInfo: 'Product List',
    basicInfo: 'Primary Information',
    addProduct: 'Add Product',
    files: 'Attachments',
    requirement: "Requirements and Scenario",
    scene: 'Scenario',
    d12: 'Status',
    d21: 'Number',
    s8: 'Product Model',
    s10: 'Description',
    s13: 'Unit',
    s25: 'Submit',
    s26: 'Save Draft',
    productText: 'The product is not enabled, please contact the sales assistant if there is any need.',
  },
  components: {
    c1: 'Attachment Management',
    c2: 'File Title',
    c3: 'Uploaded By',
    c4: 'Uploaded Time',
    c5: 'Operation',
    c6: 'Download',
    c7: 'Delete',
    c8: 'Drag the file here',
    c9: 'Click And Upload',
    c10: 'Edit Attachment',
    c11: 'Format：.rar .zip .doc .docx .pdf ，single file cannot exceed 20MB',
    c12: 'Title',
    c13: 'Time',
    c14: 'Handled By',
    c15: 'Record'
  },
  task: {
    16: 'Choose Date',
    44: 'No Data',
    57: 'Prompt'
  },
  approval: {
    a1: 'Approval Number',
    a2: 'Title',
    a3: 'Status',
    a4: 'Applicant',
    a5: 'Application Time',
    a6: 'Current Approver',
    a7: 'Current Approval Status',
    a8: 'Input Approval NO',
    a9: 'Start Date',
    a10: 'End Date',
    a11: 'Input applicant',
    a12: 'Submit',
    a13: 'Application Reason',
    a14: 'Approval Status',
    a15: 'Approve',
    a16: 'Reject',
    a17: 'Edit',
    a18: 'Reject To Applicant',
    a19: 'Approval Type',
    a20: 'Approval Reason',
    a21: 'Successfully approved',
    a22: 'Successfully rejected',
    a23: 'Delete Approval',
    a24: 'Abandoned Reason',
    a25: 'Successfully Abandoned',
    a26: 'Transfer'
  },
  allEquipment: {
    message:'The project information has not changed, and it is unnecessary to Re-register',
    isRenew: 'Renewal',
    reseller: 'Reseller ',
    distribute: 'Distribute ',
    customer: 'Company/ Organization',
    expectedDate: 'Expected PO Date',
    customerBudget: 'Customer Budget(USD)',
    industry: 'Industry',
    countryRegion: 'Country/Region',
    applicant: 'Applicant',
    address: 'Address',
    mobile: "Mobile",
    email: "Email",
    sangforOffice: 'Sangfor Office',
    projectName: "Project Name",
    contactPerson: 'Contact Person',
    position: 'Position',
    telFax: "Tel/ Fax",
    reason: 'Reason',
    productLine: "Product Line",
    specialSetting: "Special Requirement Settings",
    action: 'Action',
    addProduct: "Add Product Line",
    header: {
      mainTitle: 'Add',
      eidtTitle: 'Edit',
      NO: 'NO.'
    },
    sn: 'SN',
    status: 'Status',
    manageQuota: 'Manage Quota',
    quota: 'Quota',
    consumedQuota: 'Consumed Quota',
    totalEquipments: 'Total Equipments',
    rentedEquipments: 'Rented Equipments',
    availableEquipments: 'Available Equipments',
    toBeReturnCM: 'To Be Return CM',
    overdue: 'Overdue',
    allEquipments: 'Hardwares',
    allAccessories: 'Accessories',
    equipmentName: 'Equipment Name',
    unitQuota: 'Unit Quota($)',
    channelsCurrentlyUsed: 'Channels Currently Used',
    expectedReturnDate: 'Expected Return Date',
    accessoryName: 'Accessory Name',
    totalInventory: 'Total Inventory',
    noOfRented: 'NO. Of Rented',
    applyEquipment: 'Apply Equipment',
    quotaInformation: 'Quota Information',
    availableQuota: 'Available Quota',
    consumedQuotaOfApplication: 'Consumed Quota',
    remainingQuota: 'Remaining Quota',
    logisticsInformaion: 'Logistics Information',
    postcode: 'Postcode',
    shippingAddress: 'Shipping Address',
    deliveryMethod: 'Delivery Method',
    normalMode: 'Normal Mode',
    sfExpress: 'SF Express',
    sfOther:
      '(Shipping cost is high, please do not use it in non-emergency situations, and Sangfor will not bear the cost)',
    selfPickUp: "Self Pick Up",
    recipient: 'Recipient',
    product: 'Product',
    accessory: 'Accessory',
    productType: 'Type',
    courierCompany: 'Courier Company',
    shipmentNumber: 'Shipment Number',
    day: ' days',
    firstAuthorizationPeriod: 'First Authorization Period',
    renewalPeriod: 'Renewal Period',
    scrapReason: 'Scrap Reason',
    totalQuantity: 'Total Quantity',
    availableQuantity: 'Available Quantity',
    deposit: 'Deposit',
    confirm: 'Confirm',
    unconfirmed: 'Unconfirmed',
    allConfirmed: 'All Confirmed',
    modify: 'Modify',
    packageStatus: 'Package Status',
    scrap: 'Scrap',
    packageOwner: 'Package Owner',
    depositStatus: 'Deposit Status',
    equipmentOwner: 'Equipment Owner',
    release: 'Release',
    rentEquipment: 'Rent Equipment',
    lockingReason: 'Locking Reason',
    rentHistory: 'Rent History',
    rentalStartDate: 'Rental Start Date',
    rentalEndDate: 'Rental End Date',
    rentalChannel: 'Rental Channel',
    authorizationStartDate: 'Authorization Start Date',
    authorizationEndDate: 'Authorization End Date',
    authorizationChannel: 'Authorization Channel',
    salesRep: 'Sales Rep',
    projectStatus: 'Project Status',
    projectAmount: 'Project Amount',
    noOfAuthorization: 'NO Of Authorization',
    licenseFile: 'License File',
    view: 'View',
    authorizationHistory: 'Authorization History',
    channel: 'Channel',
    rentalPeriod: 'Rental Period',
    projectInformation: 'Project Information',
    projectStage: 'Project Stage',
    financialInformation: 'Financial Information',
    paid: 'Paid',
    noDepositRequired: 'No Deposit Required',
    toBeRefuned: 'To Be Refuned',
    totalDeposit: 'Total Deposit',
    allocateEquipment: 'Allocate Equipment',
    EPstatus: 'Status',
    authorizationStatus: 'Authorization Status',
    unauthorizated: 'Unauthorizated',
    authorized: 'Authorized',
    authorizateInProgress: 'Authorizate In Progress',
    reAuthorize: 'Re-Authorize',
    authorize: 'Authorize',
    inventoryStatus: 'Inventory Status',
    shortage: 'Shortage',
    adequate: 'Adequate',
    equipmentType: 'EquipmentType',
    equipmentApply: 'Equipment Apply',
    applyReason: 'Apply Reason',
    applyLicense: 'Apply License',
    itemId: 'Gateway ID',
    renewLicense: 'Renew License',
    previousAuthorizations: 'Previous Authorizations',
    confirmReturn: 'Confirm Return',
    equipmentReturn: 'Equipment Return',
    requiredDeposit: 'Required Deposit',
    requiredRefundAmount: 'Required Refund Amount',
    actualRefundAmount: 'Actual Refund Amount',
    applyDepositRefund: 'Apply Deposit Refund',
    borrowType: 'Borrow Type',
    noDepositRequired: 'No Deposit Required',
    depositRequired: 'Deposit Required',
    uploadVoucher: 'Upload Voucher',
    projectRegistration: "Project Registration",
    partner: "Partner",
    fields: 'Fields',
    oldValue: 'Old value',
    newValue: 'New value',
    channelProjectOwner: 'Channel Project Owner',
    channelProjectOwnerEmail: 'Channel Project Owner Email',
    resellerEmail: 'Reseller Email',
    distributeEmail: 'Distribute Email',
    depositVoucher: "Deposit Voucher"
  },
  placeholder: {
    sceneOne: 'Please select a field to filter',
    sceneTwo: 'Please type filter condition',
    select: 'Please select',
    input: 'Please type',
    search: 'Type key words, remote search',
    selectDate: 'Please select date'
  },
  other: {
    1: 'Prompt',
    2: 'Log Out',
    3: 'Confirm to log out？',
    4: 'Confirm',
    5: 'Cancel',
    6: 'Confirm to delete',
    7: "Personal Center",
    13: 'Confirm to delete those data?',
    52: 'Please select or enter keyword search',
    76: 'Contact Phone',
    102: 'Input package name',
    103: 'SN、Equipment model',
    105: 'Server internal error, please contact the administrator',
    106: 'You are logged out, please log in again.',
    107: 'Log in again',
  },
  caselist: {
    porduct: 'Product',
    status: 'Status',
    issustitle: 'Case NO./ Title / Description',
    // issusdescription:'Issue Description',
    startdate: 'Start Date',
    enddate: 'End Date',
    search: 'Search',
    reset: 'Reset',
    create: 'Create',
    searchUser: "Name、Email、Phone",
    detail: 'Detail',
    resetPassword: 'Reset Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    Email: 'Email',
    Phone: 'Phone',
    Role: 'Role',
    Status: 'Status',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    Cancel: 'Cancel',
    Submit: 'Submit',
    checkPassword: "Please input the password again",
    checkPassword2: "Please input the password",
    checkPassword2: "Please check whether twice password is the same?",
    name: 'Name',
    operation: 'Operation',
    registrationDate: 'Registration Date',
    snid: 'SN/Gateway ID/Key ID',

  },
  createcase: {
    createcase: 'Create Case',
    companyname: 'Company Name',
    companynameplaceholder: ' Please input a company name',
    checkcompanyname: ' Please input a company name',
    customer: 'Customer',
    customerplaceholder: 'Please input a customer. ',
    regionplaceholder: 'Please input region.',
    checkccustomer: 'Please input customer.',
    product: 'Product',
    productplaceholder: 'Please select product.',
    checkproduct: 'Please select product.',
    version: 'Version',
    versionplaceholder: 'Please select version.',
    checkversion: 'Please select version.',
    gatewayid: 'Gateway ID/ Key ID',
    gatewayplaceholder: 'Please input Gateway ID/ Key ID.',
    checkgateway: 'Please input correct Gateway ID/ Key ID.',
    sn: 'SN (Optional)',
    snPlaceholder: 'Please input SN.',
    snCorrectPlaceholder: 'Please input correct SN.',
    checksn: 'Please input correct SN.',
    poc: 'POC',
    checkpoc: 'Please select POC',
    issuetitle: 'Issue Title',
    issusplaceholder: 'Please input issue title.',
    checkissustitle: 'Please input issue title.',
    issuedescriptions: 'Issue Description',
    descriptionsplaceholder: 'Please input issue description.',
    checkissuedescriptions: 'Please input issue description.',
    checkissuedescriptions2: 'Issue Decriptions cannot exceed 4000 characters.',
    checkissuedescriptions3: 'Issue title cannot exceed 255 characters.',
    enter: 'Go To',
    article: 'Related Articles',
    moreraticles: 'Go To',
    contact: 'Contact',
    contactplaceholder: 'Please input contact.',
    checkcontact: 'Please input contact.',
    tel: 'Telephone',
    telplaceholder: 'Please input telephone.',
    checktel: 'Please input telephone or select Region Code.',
    checktel2: 'Please input the number.',
    email: 'Email',
    emailplaceholder: 'Please input email.',
    checkemail: 'Please input correct email format.',
    ccemail: "CC Email",
    timezone: 'Time Zone',
    zoneplaceholder: 'Please select time zone.',
    checkzone: 'Please select time zone.',
    dete: 'Date & Time',
    dateplaceholder: 'Please select date & time.',
    checkdate: 'Please select date & time.',
    cancel: 'Cancel',
    submit: 'Submit',
    contactadmin: 'Please contact TAC (+6012 711 7129 (7511) to make sure the problem will be solved in time.',
    uploadtext1: 'Drag the file here, or',
    uploadtext2: 'click upload',
    uploadtext3: 'Only cxg, jpg, gif, bmp, tiff, png, jpeg, rm, mvb, avi, asf, wmv, mov, mpg, mpeg, vob, fiv, mp4, zip, rar, gz, bz2, tgz, cab, 7z, bz, Z, doc, docx, xls, xlsx, pdf, txt can be uploaded with a maximum size of 10mb for each attachment.',
    islt10M: 'File cannot be larger than 10M.',
    Preferredcontactway: 'Preferred Contact Way',
    Preferredcontactway2: 'Please select preferred contact way.',
    caseNo: 'Case NO.',
    issueSeverity: 'Issue Severity',
    status: 'Status',
    uploadFiles: 'Upload Files',
    submitter: 'Submitter',
    updateTime: 'Update Time',
    createdTime: 'Created Time',
    escalationTime: "Escalation Time",
    closedTime: "Closed Time",
    dailyContent: "Daily Content",
    handler: 'Handler',
    operation: 'Operation',
    date: 'Date',
    from: 'From',
    to: "To",
    cc: "CC",
    emailTitle: 'Subject',
    emailContent: 'Email Content',
    Attachments: 'Attachments',
    Point: 'Point',
    veryDissatisfied: 'very dissatisfied',
    verySatisfed: 'very satisfed',
    PleaseRateUs: 'Please rate us',
    ReasonsOfLow: 'Reasons of low scores：（Up to three options are available）',
    Comment: 'Comment',
    ishelpful: 'Is it helpful to your future maintenance of the device?',
    yes: 'Yes',
    no: 'NO',
    dailySummariesDetail: 'Daily Summaries Detail',
    emailDetail: 'Email Detail',
    escalationReasons: 'Escalation Reasons',
    detailReason: 'Detail Reason',
    title: 'Title',
    description: 'Description',
    Severity: "Severity",
    Evaluation: 'Evaluation',
    userDetails: 'User Details',
    checkCountryCode: 'Please input telephone or select Region Code.'
  },
  casetitle: {
    1: 'Hi, how can I help you?',
    2: 'What is your product?',
    3: 'What is your issue?',
    4: 'How does this issue impact your business?',
    5: 'How can I contact you?',
    6: 'When do you prefer to contact you?（Optional）',
  },
  case: {
    back: 'Back',
    search: 'Search',
    product: 'Product',
    status: 'Status',
    refesh: 'Refesh',
    export: 'Export',
    search: 'Search',
    delete: 'Delete',
    cancel: 'Cancel',
    save: 'Save',
    submit: 'Submit',
    create: 'Create',
    more: 'More',
    assigning: 'Assign Role',
    upgrade: 'Upgrade',
    dateplaceholder: 'Please select date and time',
    gateplaceholder: 'Please input correct Gateway ID.',
    gateplaceholder2: 'Please input Gateway ID.',
    article: 'Related Articles',
    records: 'Email Records',
    daily: 'Daily Summaries',
    evaluation: 'Evaluation',
    rate: 'Rate',
    rated: 'Rated',
    updateTime: 'Update Time',
    closedTime: 'Closed Time',
    escalationTime: 'Escalation Time',
    severity: 'Severity',
    submitter: 'Submitter',
    createcase: 'Create Case',
    orderdetail: 'Case Detail',
    productDetail: 'Product Detail',
    contactDetail: 'Contact Detail',
    othersDetail: 'Others Detail',
    preferredDate: 'Preferred Date & Time',
    uploadFiles: 'Upload Files',
    escalate: 'Escalate',
    escalated: 'Escalated',
    hasEscalated: 'The case has been escalated.',
    noData: 'No Data',
    fileTips: 'Please view the details attachment in your email.',
    uploadtext1: 'Drag the file here , or',
    uploadtext2: 'click upload',
    uploadtext3: 'only cxg,jpg,gif,bmp,tiff,png,jpeg,rm,mvb,avi,asf,wmv,mov,mpg,mpeg,vob,fiv,mp4,zip,rar,gz,bz2,tgz,cab,7z,bz,Z,doc，docx,xls,xlsx,pdf,txt can be uploaded with a maximum size of 10mb for each individual attachment',
    islt10M: 'maximum size of 10mb for each individual attachment',
    snplaceholder: 'Please input correct SN.',
    snplaceholder2: 'Please input SN.',
    productplaceholder: 'Please select Product.',
    versionplaceholder: 'Please select Version.',
    statusplaceholder: 'Please select Status.',
    pocplaceholder: 'Please select POC',
    issusplaceholder: 'Please input issue title.',
    descriptionsplaceholder: 'Please input issue description.',
    telplaceholder: 'Please input Tel.',
    zoneplaceholder: 'Please select Time zone.',
    contactplaceholder: 'Please input Contact.',
    emailplaceholder: 'Please input correct email format.',
    emailplaceholder2: 'Please input email',
    nameplaceholder: 'Please input Name',
    phoneplaceholder: 'Please input Phone Number',
    aoreraticles: 'More Articles',
    keyword: 'Issue Description',
    contactadmin: 'The problem will be solved in time. ',
    detailReason: 'Please input detail reason',
    detailReason2: 'Detail Reason cannot exceed 1000 characters.',
    upgradeReason: 'Please select Upgrade Reason',
    checksn: 'Please input the correct SN.',
    checkgatwayid: 'Please input the correct Gateway ID.',
    lastName: 'Please input lastName',
    lastName2: 'First Name cannot exceed 50 characters.',
    firstName: 'Last Name cannot exceed 50 characters.',
    role: 'Please select Role',
    serviceProviderNotice: 'Service Provider Information',
    confirmCancel: "Confirm to cancel?",
    confirmSubmit: "Confirm to submit?",
    notice: 'Notice',
    confirm: 'Confirm',
    escalated: 'Escalated',
    escalatedinfo: 'The case has been escalated.',
    escalate: 'Escalate',
    viewEmail: 'Please check the attachment in the email inbox.',
    findGateway: 'How to find Gateway ID/Key ID?',
    upgradeErro: 'The case escalation failed, please escalate again after  2 minutes.',
  },
  productSelection: {
    projectName: 'Project Name',
    Throughput: 'Throughput(Mbps)',
    IPsecThroughput: 'IPsec Throughput(Mbps)',
    SelectTheScenario: 'Please Select the Scenario',
    Consideration: 'You need the features below?',
    ThroughputRequired: 'Please input the Throughput Spec Required',
    Models: 'Recommended Models',
    notice: '*Please input the project name before start sizing',
    CompetingGoodsCompany: 'Company',
    CompetingGoodsModel: 'Model',
    ProductList: 'Product List',
    configuration: 'Configure the Product',
    ProductModel: 'Product',
    EquipmentSets: 'Hardware Qty',
    productName: 'Product Name',
    productType: 'Product Type',
    productDescription: 'Description',
    PurchaseQuantity: 'Quantity',
    TaketheNumber: 'Default Qty.',
    ProductDetails: 'Product Sizing',
    ReplaceTheProject: 'New Project',
    SelectionOfRecord: 'Sizing Records',
    beenDownloaded: 'Exported BOM',
    SaveSelectionResults: 'Save Cart to BOM',
    number: 'No.',
    SpecialEffects: 'Product Highlights',
    PerformanceParameters: 'Performance Specification',
    HardwareParameters: 'Hardware Specification',
    PreviewFormat: 'BOM Format Preview',
    BOMPreview: 'BOM Preview',
    PreviewNotice: 'The download format is showed as below, please check if the downloaded information is correct.',
    download: 'Confirm to Download',
    prompt: 'Do not Remind Again',
    save: 'Save',
    saveAdd: '保存并新增',
    productSelection: 'Product Sizing',
    Cart: 'Cart',
    submit: 'Submit',
    downloadBOM: 'Export BOM',
    deleteAll: 'Delete All',
    selectAll: 'Select All',
    Forward: 'Creation Time',
    Reverse: 'Creation Time',
    ExpandAll: 'Expand All',
    Customization: 'BOM Customization',
    OtherBOMPreview: 'BOM Preview',
    deleteProject: 'Delete Project',
    Edit: 'Edit',
    Downloadkit: 'Download product document kit',
    AddtoCart: 'Add to Cart',
    notAllowed: 'Null is not allowed',
    downloadTime: 'Latest Download Time',
    inputText: 'Please input the Project Name',
    nodata: 'No Data',
    enterSuccess: 'Add device to Cart Successfully!',
    Performance: 'Base on Scenario & Performance',
    Competitor: 'Base on Competitor',
    confirmMsg: "You're leaving the page, the unsaved cart will be cleared. Click confirm to continue?",
    prompt: 'Do not remind again',
    notAllowedMsg: 'The input number must be an integer, greater than 0 and less than 1 trillion',
    configureAllowedMsg: 'The input number must be an integer.',
    saveSuccess: ' Successfully done!',
    SelectResult: 'Select the Scenario that most match your requirement, This will affect the sizing result',
    clickTitle: 'Click to view product detailed information',
    noZip: 'No document available yet.',
    deleteProjectTips: 'Only selected projects will be deleted',
    showNoneTips: '<p>The system cannot find a match for you at this moment.</p> <p>Maybe your requirement needs a more experienced consultant. </p><p>Please contact Sangfor local office/distributor for further sizing recommendations.</p>',
    promptTips: 'Prompt',
    None: 'No Data',
    delete: 'Successfully deleted!',
  },
  formVaild: {
    head: 'Cannot exceed over',
    tail: 'characters'
  }
}
