<template>
  <div style="height: 100%">
    <flexbox
      v-loading="loading"
      direction="column"
      align="stretch"
      class="d-container"
    >
      <c-r-m-detail-head
        :title="detailData.flowNo"
        :head-details="headDetails"
        @hideView="hideView"
      >
        <div slot="right" class="header">
          <el-button
            type="primary"
            size="medium"
            @click="checkApproval('approve')"
          >
            {{ $t('approval.a15') }}
          </el-button>
          <el-button
            type="primary"
            size="medium"
            @click="checkApproval('reject')"
          >
            {{ $t('approval.a16') }}
          </el-button>
        </div>
        <div class="busi-line" />
      </c-r-m-detail-head>
      <div id="follow-log-content" class="t-loading-content">
        <basic-info
          :detail="detailData"
          :list="channelInfoList"
          itemWidth="25%"
          labelWidth="200px"
        ></basic-info>
        <basic-info
          :detail="detailData"
          :list="projectInfoList"
          itemWidth="25%"
          labelWidth="200px"
        ></basic-info>
        <create-sections
          :title="$t('detail.projectInfo')"
          style="margin-top: 30px"
        >
          <el-table
            :data="detailData.list"
            stripe
            border
            highlight-current-row
            class="productInfo"
            style="width: 100%; margin-top: 5px"
          >
            <el-table-column
              :label="$t('allEquipment.productLine')"
              prop="productLine"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.productLine || '--' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('allEquipment.specialSetting')"
              prop="settings"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.settings || '--' }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </create-sections>
        <create-sections :title="$t('approval.a14')" style="margin-top: 30px">
          <div style="padding: 10px; min-height: 150px">
            <flexbox align="stretch" justify="flex-start">
              <el-timeline v-if="commentList.length != 0" style="width: 100%">
                <div v-for="(item, index) in commentList" :key="index">
                  <el-timeline-item
                    v-for="(activity, index) in item.child"
                    :key="index"
                    :icon="activity.icon"
                    :type="activity.type"
                    :color="activity.color"
                    :size="activity.size"
                    :hide-timestamp="activity.show"
                    :timestamp="activity.timestamp"
                    placement="top"
                  >
                    <el-card
                      v-if="activity.show"
                      style="border: 1px solid #d7e8f4; background: #f7fbfe"
                    >
                      <div slot="header" class="clearfix">
                        <p
                          style="display:flex;
                          justify-content:space-between;
                          line-height: 20px;
                        "
                        >
                          <span>{{ activity.userName }}</span>
                          <span>
                            {{ activity.createdDate }}
                          </span>
                        </p>
                      </div>
                      <div class="card-content">
                        <p>{{ activity.fullMessage }}</p>
                      </div>
                    </el-card>
                  </el-timeline-item>
                </div>
              </el-timeline>
              <div
                v-if="commentList.length == 0"
                style="
                color: #909399;
                margin-top: 20px;
                text-align: center;
                width: 100%;
              "
              >
                {{ $t('task.44') }}
              </div>
            </flexbox>
          </div>
        </create-sections>
      </div>
    </flexbox>
    <check-dialog
      :dialog-visible="dialogVisible"
      :check-type="checkType"
      :detail="detailData"
      @close-dialog="closeDialog"
      @hide-view="hideView"
    ></check-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CRMDetailHead from '@c/CRMDetailHead'
import CreateView from '@c/CreateView'
import BasicInfo from '@c/BasicInfo'
import moment from 'moment'
import { findDIctName, moneyFormat, findFullName } from '@/utils'
import { listCommentByBusinessKey, getProjectdetail } from '@/api/approval'
import checkDialog from './checkDialog'
export default {
  name: 'MyApprovalDetail',
  components: {
    CRMDetailHead,
    CreateView,
    BasicInfo,
    checkDialog
  },
  props: {
    // 详情信息id
    id: [String, Number],
    row: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      detailData: {}, // 当前页面详情数据
      channelInfoList: [], // basicinfo的基本信息
      projectInfoList: [], //项目信息
      headDetails: [],
      commentList: [],
      dialogVisible: false,
      checkType: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.getDetail()
    console.log(this.userInfo)
  },
  methods: {
    closeDialog(val) {
      this.dialogVisible = val
    },
    hideView() {
      this.$emit('hide-view')
      this.$emit('handle')
    },
    checkApproval(type) {
      this.dialogVisible = true
      this.checkType = type
    },
    infoFormat(list) {
      list.map((ele) => {
        ele.child &&
          ele.child.map((olo) => {
            olo.map((e) => {
              e.name = e.name || '--'
            })
          })
      })
    },
    getDetail() {
      this.loading = true
      let params = {
        id: this.id
      }
      getProjectdetail(params)
        .then((res) => {
          this.headDetails = [
            {
              title: this.$t('approval.a4'),
              value: res.rows.applicant
            },
            {
              title: this.$t('detail.d12'),
              value: findDIctName('flow_status', res.rows.status)
            },
            {
              title: this.$t('approval.a6'),
              value: res.rows.currentName
            }
          ]
          this.detailData = res.rows
          this.loading = false
          this.getBaiscInfo()
          this.getComment(this.detailData.flowId)
        })
        .catch((err) => {
          this.loading = false
        })
    },
    getBaiscInfo() {
      this.channelInfoList = [
        {
          title: this.$t('detail.basicInfo'),
          child: [
            [
              {
                label: this.$t('allEquipment.applicant'),
                name: this.detailData.applicantChannelName
              },
              {
                label: this.$t('allEquipment.address'),
                name: findDIctName('country', this.detailData.applicantAddress)
              }
            ],
            [
              {
                label: this.$t('other.76'),
                name: this.detailData.applicantMobile
              },
              {
                label: this.$t('allEquipment.resellerEmail'),
                name: this.detailData.applicantEmail
              }
            ],
            [
              {
                label: this.$t('allEquipment.channelProjectOwner'),
                name: this.detailData.channelProjectBy
              },
              {
                label: this.$t('allEquipment.channelProjectOwnerEmail'),
                name: this.detailData.channelProjectEmail
              }
            ],
            [
              {
                label: this.$t('approval.a5'),
                name: moment(this.detailData.createdDate).format('YYYY-MM-DD')
              }
            ]
          ]
        }
      ]
      this.projectInfoList = [
        {
          title: this.$t('allEquipment.projectInformation'),
          child: [
            [
              {
                label: this.$t('allEquipment.customer'),
                name: this.detailData.company
              },
              {
                label: this.$t('allEquipment.customerBudget'),
                name: this.detailData.customerBudget
              }
            ],
            [
              {
                label: this.$t('allEquipment.expectedDate'),
                name: moment(this.detailData.expectedPoDate).format(
                  'YYYY-MM-DD'
                )
              },
              {
                label: this.$t('allEquipment.industry'), // Industry
                name: findFullName(
                  this.detailData.industry,
                  this.$store.state.dict.dict.customer_business,
                  'value',
                  'list'
                )
              }
            ],
            [
              {
                label: this.$t('allEquipment.address'),
                name: this.detailData.address
              },
              {
                label: this.$t('allEquipment.countryRegion'),
                name: findDIctName('country', this.detailData.country)
              }
            ],
            [
              {
                label: this.$t('detail.scene'),
                name: findDIctName(
                  'project_scene',
                  this.detailData.businessScene
                )
              },
              {
                label: this.$t('allEquipment.contactPerson'),
                name: this.detailData.contactPerson
              }
            ],
            [
              {
                label: this.$t('allEquipment.position'),
                name: this.detailData.position
              },
              {
                label: this.$t('allEquipment.email'),
                name: this.detailData.email
              }
            ],
            [
              {
                label: this.$t('allEquipment.telFax'),
                name: this.detailData.tel
              },
              {
                label: this.$t('allEquipment.mobile'),
                name: this.detailData.mobile
              }
            ],
            [
              {
                label: this.$t('allEquipment.reason'),
                name: this.detailData.reason ? this.detailData.reason : '--'
              },
              {
                label: this.$t('allEquipment.isRenew'),
                name: findDIctName('commonType', this.detailData.isRenew)
              }
            ]
          ]
        }
      ]
    },
    getComment(id) {
      listCommentByBusinessKey(id).then((res) => {
        this.commentList = []
        let commentList = res.rows
        _.uniq(
          commentList.map((e) => {
            e.sign = e.time.slice(0, 10)
            return e.time.slice(0, 10)
          })
        ).map((ele) => {
          let obj = {
            temp: ele,
            child: [
              {
                timestamp: ele,
                show: false,
                size: 'large'
              }
            ]
          }
          commentList.map((e) => {
            if (e.sign == ele) {
              e.show = true
              e.createdDate = moment(e.time).format('YYYY-MM-DD HH:mm:ss')
              ;(e.showComment = false), obj.child.push(e)
            }
          })
          this.commentList.push(obj)
        })
        console.log(this.commentList)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.d-container {
  height: 100%;

  .header {
    display: flex;
    align-items: center;
  }

  .t-loading-content {
    flex: 1;
    overflow: auto;
    margin-top: 15px;
  }
}

.el-button {
  padding: 10px 11px;
}
.busi-line {
  position: absolute;
  bottom: 0;
  left: 17px;
  right: 17px;
  height: 1px;
  background-color: #e6e6e6;
}
</style>
