import router from './router'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import { getAuth } from '@/utils/auth' // 验权
import Vue from  'vue'
let loadAsyncRouter = false
const whiteList = ['/login'] // 不重定向白名单
router.beforeEach((to, from, next) => {
  // localStorage.setItem('routerBefore', from.path)
  NProgress.start()
  /** 请求头包含授权信息 并且 页面必须授权 直接进入 */
  if (getAuth()) {
    if (to.path === '/login') {
      next()
      NProgress.done()
    } else {
      if (!loadAsyncRouter) {
        // 判断当前用户是否获取权限
        store.dispatch('GenerateRoutes').then(() => {
          loadAsyncRouter = true
          router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
          console.log(to,'to');
          if (to.path === '/404') {
              next({
                path: to.redirectedFrom || '/',
                replace: true
              })       
          } else {
            next({
              ...to,
              replace: true
            })
          }
        })
      } else{
          next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      NProgress.done()
      if (process.env.NODE_ENV == 'development') {
        next(`/login`) // 否则全部重定向到登录页
      } else {
        window.location.replace(process.env.VUE_APP_JUMP_URL)
      }
    }
  } 
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})

router.onError(error => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})
