<template>
  <el-cascader ref="elCascader"
               :options="options"
               :props="defaultProps"
               :clearable="clearable"
               :placeholder="placeholder"
               v-model="dataValue"
               size="small"
               style="width: 100%"
               collapse-tags
               @change="valueChange" />
</template>
<script type="text/javascript">
import arrayMixin from "./arrayMixin";
export default {
  name: "XhProducCate", // 新建 产品分类
  components: {},
  mixins: [arrayMixin],
  props: {
    crmType: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    placeholder: {
      type: String,
      default: () => {
        return "Select";
      },
    },
  },
  data () {
    return {
      val: [],
      options: [],
      defaultProps: {
        multiple: this.multiple,
        children: "list",
        label: "name",
        value: "deptId",
        checkStrictly: false,
        disabled: "status",
      },
    };
  },
  computed: {},
  watch: {
    name: {
      handler (val) {
        this.getOptions();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted () {
    this.getOptions();
  },
  methods: {
    getOptions () {
      if (this.dictName == "customer_business") {
        this.options = this.$store.state.dict.dict[this.dictName];
        this.setListNull(this.options);
        this.defaultProps = {
          children: "list",
          label: "name",
          value: "value",
          multiple: this.multiple,
        };
      } else if (this.dictName == "project_scene") {
        this.options = this.$store.state.dict.dict[this.dictName].map(
          (item) => {
            item.list = item.list && item.list.filter((ele) => ele.status == 1);
            return item;
          }
        );
        this.options = this.options && this.options.filter(item => {
          return item.status === 1
        })
        this.setListNull(this.options);
        this.defaultProps = {
          children: "list",
          label: "name",
          value: "value",
          multiple: this.multiple,
        };
      }
    },
    setListNull (options, key = "list") {
      if (options) {
        options.map((e) => {
          if (Array.isArray(e[key]) && e[key].length > 0) {
            this.setListNull(e[key], key);
          } else {
            e[key] = null;
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
</style>
