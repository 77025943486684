/**字典表 */
const dict = {
  state: {
    dict: {}
  },
  mutations: {
    UPDATE_DICT: (state, data) => {
      const { key, value } = data
      state.dict[key] = value
      state.dict = Object.assign({}, state.dict)
    }
  },
  actions: {}
}

export default dict
