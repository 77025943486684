import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false
import ElementUI from 'element-ui'
Vue.use(ElementUI)
// 全局重写message默认提示时间及可关闭
Vue.prototype.$message = function(option) {
  option = option || {}
  if (typeof option === 'string') {
    option = {
      message: option
    }
  }
  return ElementUI.Message(
    Object.assign(
      {
        duration: 2000,
        showClose: true
      },
      option
    )
  )
}
var types = ['success', 'warning', 'info', 'error']
types.forEach(function(type) {
  Vue.prototype.$message[type] = function(option) {
    if (typeof option === 'string') {
      option = {
        message: option
      }
    }
    return ElementUI.Message[type](
      Object.assign(
        {
          duration: 2000,
          showClose: true
        },
        option
      )
    )
  }
})
import 'element-ui/lib/theme-chalk/index.css'

import '@/styles/index.css' // global css

import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'
import cache from '@/utils/cache'
cache.loadingCache()
import '@/permission' // permission control
import _ from 'lodash'
//国际化
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

if (localStorage.getItem('lang') && localStorage.getItem('lang') == 'CN') {
  locale.use(zhLocale)
} else {
  locale.use(enLocale)
}
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'EN', // 语言标识
  messages: {
    CN: require('./lang/zh'), // 中文语言包
    EN: require('./lang/en') // 英文语言包
  }
})

import { getCountMyTask } from '@/api/login'
Vue.prototype.getCountMyTask = getCountMyTask

/** 常用全局组件 */
/** 常用flex组件 */
import { Flexbox, FlexboxItem } from '@/components/flexbox'
Vue.component('flexbox', Flexbox)
Vue.component('flexbox-item', FlexboxItem)
import CRMAllDetail from '@c/CRMAllDetail.vue'
import CRMCreateView from '@c/CRMCreateView.vue'
Vue.component('c-r-m-all-detail', CRMAllDetail)
Vue.component('c-r-m-create-view', CRMCreateView)
import CreateSections from '@c/CreateSections'
Vue.component('create-sections', CreateSections)

// 可拖动窗口
import { dialogDrag } from './directives'
Vue.directive('dialogDrag', dialogDrag)

// 日期采用国际化，每周从周日开始
import moment from 'moment'
moment.locale('zh_cn', {
  week: {
    dow: 7
  }
})
window.moment = moment
// 时间格式化过滤器
Vue.filter('dateformat', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})
// 验证是否包含该权限
import { hasPermission } from '@/utils'
Vue.prototype.hasPermission = hasPermission
Vue.prototype.apiUrl = '/api/' //测试

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
