<template>
  <div>
    <flexbox direction="column"
             align="stretch"
             class="crm-create-container"
             v-loading="loading">
      <c-r-m-detail-head :title="info.projectName || 'Sangfor firewall procument project'"
                         @hideView="hideView">
        <template slot="left">
          <div class="header-title">
            {{ info.projectName }}
          </div>
          <div class="stage">
            <div v-show="inProgress"
                 class="inProgress">{{$t('common.btn.inProgress')}}</div>
            <div v-show="won"
                 class="won">{{$t('common.btn.won')}}</div>
            <div v-show="lost"
                 class="lost">{{$t('common.btn.lost')}}</div>
            <div v-show="overdue"
                 :class="{'en-overdue':lang !== 'CN','zh-overdue':lang === 'CN'}">{{$t('common.btn.overdue')}}</div>
          </div>
        </template>
        <template slot="right">
          <template slot="stage-btn">
            <el-row class="stage">
              <el-button :round="true"
                         :plain="true">{{$t('common.btn.inProgress')}}</el-button>
              <el-button :round="true"
                         :plain="true">{{$t('common.btn.won')}}</el-button>
              <el-button :round="true"
                         :plain="true">{{$t('common.btn.lost')}}</el-button>
              <el-button :round="true"
                         :plain="true">{{$t('common.btn.overdue')}}</el-button>
            </el-row>
          </template>
          <el-button type="primary"
                     size="medium"
                     @click="register"
                     style="background-color: #556ee6; margin-left: 20px">Re-register</el-button>
        </template>
        <template slot="content">
          <el-menu :default-active="activeIndex"
                   mode="horizontal"
                   text-color="#A1A4B4"
                   active-text-color="#556EE6"
                   class="equipmentMenu"
                   @select="handleSelect">
            <el-menu-item index="bssic-information">Basic Information</el-menu-item>
            <el-menu-item index="registration-history">Registration History</el-menu-item>
            <!-- <el-menu-item index="authorization-history"
              >Authorization History</el-menu-item
            > -->
          </el-menu>
        </template>
      </c-r-m-detail-head>
      <div class="crm-create-flex">
        <component :is="getComponentName"
                   :info="info"
                   :historyInfo="historyInfo"
                   :action="createActionInfo"></component>
      </div>
    </flexbox>
    <c-r-m-create-view v-if="isCreate"
                       :crm-type="rowType"
                       :visible.sync="isCreate"
                       :action="createActionInfo"
                       @hiden-view="isCreate = false"
                       @save-success="createEditSuccess"></c-r-m-create-view>
  </div>
</template>

<script>
import CRMDetailHead from "@c/CRMDetailHead";
import CreateView from "@c/CreateView";
import bssicInformation from "./bssicInformation";
import registrationHistory from "./registrationHistory";
import authorizationHistory from "./authorizationHistory";
import { getProjectInfo, getAuthlogsInfo } from "@/api/allProject";
export default {
  name: "projectDetails",
  components: {
    CRMDetailHead,
    CreateView,
    bssicInformation,
    registrationHistory,
    authorizationHistory,
  },
  props: {
    // 详情信息id
    id: [String, Number],
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
    openDetails: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      active: 0,
      overdueActive: 0,
      // crmType: "ProjectDetil",
      activeIndex: "bssic-information",
      equipmentMenu: "bssic-information",
      businessId: "",
      info: {},
      historyInfo: {},
      createActionInfo: {
        type: "update",
      },
      rowType: "",
      isCreate: false,
      loading: false,
      lang: localStorage.getItem("lang"),
    };
  },
  computed: {
    inProgress () {
      return [0, 1, 2, 3, 4, 5, 6, 7].includes(this.active)
    },

    won () {
      return this.active === 8
    },

    lost () {
      return this.active === 9
    },

    overdue () {
      return this.active === 10
    },

    getComponentName () {
      if (this.equipmentMenu == "bssic-information") {
        return "bssic-information";
      } else if (this.equipmentMenu == "registration-history") {
        return "registration-history";
      } else if (this.equipmentMenu == "authorization-history") {
        return "authorization-history";
      }
    },
  },
  mounted () {
    let businessId = this.$store.state.businessId;
    this.businessId = businessId;
    this.getDetails();
    console.log("id", this.id);
  },
  methods: {
    // 获取详情
    getDetails () {
      this.loading = true
      let params = {
        id: this.businessId,
      };
      getProjectInfo(params).then((res) => {
        this.active = parseInt(res.rows.ppStage);
        // this.overdueActive = parseInt(res.rows.ppStage)
        this.info = res.rows;
        this.loading = false
      }).catch(err => {
        this.loading = false;
      });
    },
    // 获取日志
    // getAuthlogs() {
    //   let params = {
    //     id: this.businessId
    //   }
    //   getAuthlogsInfo(params).then(res => {
    //     this.historyInfo = res.rows
    //   })
    // },
    register () {
      if (this.active === 8) {
        this.$message({
          type: "error",
          message: this.$t('common.btn.projectWon')
        });
      } else if (this.active === 9) {
        this.$message({
          type: "error",
          message: this.$t('common.btn.porjectLost')
        });
      } else {
        this.$emit("register", this.info);
      }
    },
    edit () {
      console.log(666);
      // this.$emit("edit");
      this.isCreate = true;
      this.rowType = "ppProject";
      this.createActionInfo = {
        type: "eaupdate",
        data: JSON.parse(JSON.stringify(this.info)),
      };
    },
    hideView () {
      this.$emit("hide-view");
    },
    handleSelect (val) {
      this.equipmentMenu = val;
    },
    createEditSuccess (type) {
      this.getDetails();
      this.detailHeadHandle(type);
    },
    /** 顶部头 操作 */
    detailHeadHandle (data) {
      // window.console.log("data", data);
      // if (data.type == "save") {
      //   this.hideView();
      // }
      this.$emit("handle", data);
    },
  },
};
</script>

<style lang="less" scoped>
.crm-create-container {
  position: relative;
  height: 100%;
}

.project-title {
  font-size: 18px;
  font-weight: bold;
  color: #343a40;
}

.project-tip {
  margin-left: 44px;
  font-size: 12px;
  color: #999a9e;
}

.crm-create-flex {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;

  .equipmentMenu {
    height: 54px;

    .el-menu-item {
      height: 54px;
      text-align: center;
    }
  }
}

.crm-step {
  /deep/ .is-success {
    color: #556ee6;
    border-color: #556ee6;
  }
}

.handle-bar {
  position: relative;
  margin: 0 auto;

  .handle-button {
    // float: right;
    margin-top: 5px;
    margin-right: 10px;
  }
}
.header-title {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: 600;
  line-height: 25px;
  color: #343a40;
  opacity: 1;
}
.stage {
  margin-left: 16px;
  display: flex;
  align-items: center;
  div {
    height: 30px;
    line-height: 24px;
    border-radius: 18px;
    margin-left: 16px;
    text-align: center;
    font-size: 16px;
  }
  .inProgress {
    min-width: 104px;
    padding: 0 17px;
    border: 3px solid #556ee6;
    color: #556ee6;
  }
  .won {
    width: 68px;
    border: 3px solid #19b4bf;
    color: #19b4bf;
  }
  .lost {
    width: 68px;
    border: 3px solid #f46a6a;
    color: #f46a6a;
  }
  .en-overdue {
    width: 100px;
    border: 3px solid #fc5531;
    color: #fc5531;
  }
  .zh-overdue {
    width: 88px;
    border: 3px solid #fc5531;
    color: #fc5531;
  }
}
</style>