<template>
  <flexbox direction="column"
           align="stretch"
           class="crm-create-container">
    <flexbox class="crm-create-header">
      <!-- 设备授权申请 -->
      <div style="
          flex: 1;
          font-size: 17px;
          font-family: Alibaba PuHuiTi;
          font-weight: 600;
          line-height: 22px;
          color: #343a40;
          opacity: 1;
        ">
        {{
          action.data.borrowRecordEntities[0].usedCount == "0"
            ? $t("allEquipment.applyLicense")
            : $t("allEquipment.renewLicense")
        }}
      </div>
      <img class="close"
           src="@/assets/img/task_close.png"
           @click="hidenView" />
    </flexbox>
    <div class="crm-create-flex">
      <el-form ref="crmForm"
               :model="crmForm"
               :rules="rules"
               label-width="150px">
        <create-sections :title="$t('detail.basicInfo')">
          <div style="padding-left: 50px">
            <el-row>
              <el-col :span="8">
                <!-- 渠道商 -->
                <el-form-item :label="$t('allEquipment.channel') + ':'">
                  {{ crmForm.channelName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!-- 申请者 -->
                <el-form-item :label="$t('approval.a4') + ':'">
                  {{ crmForm.applicantName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <!-- 授权次数 -->
                <el-form-item :label="$t('allEquipment.noOfAuthorization') + ':'">
                  {{
                    action.data.borrowRecordEntities[0]
                      ? action.data.borrowRecordEntities[0].usedCount + 1
                      : 1
                  }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item label="Package:">
                {{ crmForm.packageName }}
              </el-form-item>
            </el-row>
          </div>
        </create-sections>
        <create-sections :title="$t('allEquipment.projectInformation')">
          <div style="padding-left: 50px"
               v-loading="businessInfoLoading">
            <el-row :gutter="120">
              <el-col :span="10">
                <!-- 项目名称 -->
                <el-form-item :label="$t('detail.projectName') + ':'"
                              prop="businessName">
                  <el-select v-model="crmForm.businessName"
                             v-if="
                      action.data.depositStatus == '0' &&
                      crmForm.borrowRecordEntities[0] &&
                      crmForm.borrowRecordEntities[0].usedCount == '0'
                    "
                             style="width: 100%"
                             filterable
                             remote
                             reserve-keyword
                             clearable
                             :placeholder="$t('placeholder.search')"
                             :loading="projectNameLoading"
                             :remote-method="projectNameRemote"
                             @change="
                      (val) => {
                        handleChange(val, 'businessName');
                      }
                    ">
                    <el-option v-for="(item, index) in projectNameArr"
                               :key="index"
                               :label="item.business_name"
                               :value="item.business_name"></el-option>
                  </el-select>
                  <div v-else>
                    {{ crmForm.businessName || "--" }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <!-- 客户名称 -->
                <el-form-item :label="$t('detail.customerName') + ':'"
                              prop="customer">
                  {{ crmForm.customerName || "--" }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="120">
              <el-col :span="10">
                <!-- 项目所属阶段 -->
                <el-form-item :label="$t('allEquipment.projectStage') + ':'">
                  {{ crmForm.percent || "--" }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <!-- 项目金额 -->
                <el-form-item :label="$t('allEquipment.projectAmount') + ':'">
                  {{ crmForm.money || "--" }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="120">
              <el-col :span="10">
                <!-- 申请原因 -->
                <el-form-item prop="pursueReason"
                              :label="$t('allEquipment.applyReason') + ':'">
                  <el-input v-model="crmForm.pursueReason"
                            :rows="3"
                            type="textarea"
                            maxlength="1000"
                            show-word-limit />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <!-- 附件 -->
                <el-form-item :label="$t('detail.files') + ':'"
                              prop="sysFileEntityList">
                  <XhFiles :value="crmForm.sysFileEntityList"
                           @value-change="
                      (val) => {
                        handleChange(val, 'sysFileEntityList');
                      }
                    "></XhFiles>
                  <p style="color: #999999; margin-top: -15px">
                    Please upload device information file.
                  </p>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </create-sections>
        <!-- 产品信息 -->
        <create-sections :title="$t('detail.projectInfo')">
          <div style="padding: 0 10px 15px">
            <el-table :data="crmForm.borrowRecordEntities"
                      stripe
                      border
                      highlight-current-row
                      class="productInfo"
                      style="width: 100%; margin-top: 5px">
              <el-table-column :label="$t('detail.productName')"
                               prop="itemName"
                               header-align="center">
                <template slot-scope="scope">
                  <el-form-item :prop="'borrowRecordEntities.' + scope.$index + '.itemName'">
                    {{ scope.row.itemName }}
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column :label="$t('detail.productType')"
                               prop="productType"
                               width="100"
                               align="center">
                <template slot-scope="scope">
                  <div>
                    {{
                      scope.row.productType == "0"
                        ? $t("allEquipment.product")
                        : scope.row.productType == "1"
                        ? $t("allEquipment.accessory")
                        : "--"
                    }}
                  </div>
                </template>
              </el-table-column>
              <!-- "授权开始日期" -->
              <el-table-column :label="$t('allEquipment.authorizationStartDate')"
                               prop="startDate"
                               width="180"
                               align="center">
                <template slot="header"
                          slot-scope="scope">
                  <span style="color: red; margin-right: 4px">*</span><span>{{ $t("allEquipment.authorizationStartDate") }}</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="
                      'borrowRecordEntities.' + scope.$index + '.startDate'
                    "
                                :rules="{
                      required: scope.row.usedCount == '0' ? true : false,
                      message: $t('filter.f68'),
                      trigger: 'change',
                    }">
                    <el-date-picker v-model="scope.row.startDate"
                                    v-if="scope.row.usedCount == '0'"
                                    type="date"
                                    size="mini"
                                    :placeholder="$t('task.16')"
                                    style="width: 150px">
                    </el-date-picker>
                    <div v-else>
                      {{ moment(scope.row.startDate).format("YYYY-MM-DD") }}
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column :label="$t('detail.remark')"
                               prop="remark"
                               align="center">
                <template slot-scope="scope">
                  <el-form-item :prop="'borrowRecordEntities.' + scope.$index + '.remark'"
                                :rules="[
                      {
                        required: ['-6090001311002', '-6090001311001'].some(
                          (ele) => scope.row.uniqueId.includes(ele)
                        )
                          ? true
                          : false,
                        message: $t('filter.f68'),
                        trigger: 'change',
                      },
                    ]">
                    <flexbox>
                      <span style="color: red; margin-right: 4px"
                            v-if="
                          ['-6090001311002', '-6090001311001'].some((ele) =>
                            scope.row.uniqueId.includes(ele)
                          )
                        ">*</span>
                      <el-input v-model="scope.row.remark"
                                :rows="2"
                                type="textarea"
                                maxlength="1000"></el-input>
                    </flexbox>
                  </el-form-item>
                </template>
              </el-table-column>
              <!-- 授权次数 -->
              <el-table-column :label="$t('allEquipment.noOfAuthorization')"
                               :width="lang == 'CN' ? '90' : '130'"
                               prop="usedCount"
                               align="center">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.usedCount }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </create-sections>
      </el-form>
    </div>
    <div class="handle-bar">
      <el-button class="handle-button"
                 :loading="saveLoad"
                 :disabled="saveDisable"
                 type="primary"
                 @click="handleTypeClick('save')">{{ $t("detail.s25") }}</el-button>
      <el-button class="handle-button"
                 :loading="saveDrafLoad"
                 :disabled="saveDrafDisable"
                 v-if="
          action.type == 'save' ||
          (action.type == 'update' && action.data.status == '0')
        "
                 @click="handleTypeClick('saveDraft')">{{ $t("detail.s26") }}</el-button>
      <el-button class="handle-button"
                 size="medium"
                 @click.native="hidenView">
        {{ $t("common.btn.cancel") }}
      </el-button>
    </div>
  </flexbox>
</template>

<script>
import { mapGetters } from 'vuex'
import { moneyFormat, findDIctName } from '@/utils'
import moment from 'moment'
import Lockr from 'lockr'
import CreateView from '@c/CreateView'
import CreateSections from '@c/CreateSections'
import { XhFiles } from '@c/CreateCom'
import {
  equipmentauthSave,
  equipmentauthUpdate,
  selectPartnerPortal,
  getBusinessByBorrowId,
} from '@/api/allEquipment'
export default {
  name: 'equipmentAuthorizationApply',
  components: {
    CreateView,
    CreateSections,
    XhFiles,
  },
  props: {
    action: {
      type: Object,
      default: () => {
        return { type: 'save' }
      },
    },
  },
  data() {
    return {
      crmForm: {
        // deptName: "",
        // deptId: "",
        packageName: '',
        packageId: '',
        applicantName: '',
        applicantId: '',
        // filedDate: new Date(),
        businessId: '',
        businessName: '',
        businessType: '',
        channelId: '',
        channelName: '',
        customerId: '',
        customerName: '',
        percent: '',
        money: '',
        pursueReason: '',
        borrowRecordEntities: [],
        sysFileEntityList: [],
      },
      rules: {
        businessName: [
          { required: true, message: this.$t('filter.f68'), trigger: 'change' },
        ],
        customerName: [
          { required: true, message: this.$t('filter.f68'), trigger: 'change' },
        ],
        pursueReason: [
          { required: true, message: this.$t('filter.f68'), trigger: 'change' },
        ],
      },
      saveLoad: false,
      saveDisable: false,
      saveDrafLoad: false,
      saveDrafDisable: false,
      projectNameArr: [],
      projectNameLoading: false,
      lang: localStorage.getItem('lang'),
      businessInfoLoading: false,
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['userInfo']),
    moneyFormat() {
      return moneyFormat
    },
    moment() {
      return moment
    },
  },
  mounted() {
    this.resetFormData()
  },
  methods: {
    hidenView() {
      this.$emit('hide-view')
    },
    resetFormData() {
      if (this.action.type == 'save') {
        for (const key in this.crmForm) {
          const value = this.action.data[key]
          value && this.$set(this.crmForm, key, value)
        }
      } else {
        for (const key in this.action.data) {
          const value = this.action.data[key]
          this.$set(this.crmForm, key, value)
        }
      }
      this.crmForm.borrowRecordEntities = JSON.parse(
        JSON.stringify(
          this.action.data.borrowRecordEntities.map((item) => {
            if (item.usedCount != '0') {
              item.startDate = moment(item.endDate).add(1, 'd')
            }
            return item
          })
        )
      )
      this.$nextTick(() => {
        if (!this.crmForm.businessId) {
          this.getBusinessInfo()
        }
      })
    },
    // 调接口拿项目信息
    getBusinessInfo() {
      this.businessInfoLoading = true
      getBusinessByBorrowId({
        id: this.crmForm.borrowRecordEntities[0].borrowId,
      })
        .then((res) => {
          this.businessInfoLoading = false
          this.crmForm.businessId = res.rows.businessId
          this.crmForm.businessName = res.rows.businessName
          this.crmForm.businessType = res.rows.businessType
          this.crmForm.customerId = res.rows.customerId
          this.crmForm.customerName = res.rows.customerName
          this.crmForm.percent = res.rows.percent
          this.crmForm.money = res.rows.money
        })
        .catch(() => {
          this.businessInfoLoading = false
        })
    },
    projectNameRemote(queryString) {
      if (queryString !== '') {
        // 项目搜索
        this.projectNameLoading = true
        selectPartnerPortal({
          serchName: queryString,
          channelId: this.crmForm.channelId,
        })
          .then((res) => {
            this.projectNameArr = res.rows
            this.projectNameLoading = false
          })
          .catch(() => {
            this.projectNameLoading = false
          })
      } else {
        this.projectNameArr = []
      }
    },
    handleChange(val, type) {
      if (type === 'businessName') {
        if (val) {
          let projectInfo = this.projectNameArr.find(
            (e) => e.business_name == val
          )
          this.crmForm.businessId = projectInfo.id
          this.crmForm.customerName = projectInfo.customer_name
          this.crmForm.customerId = projectInfo.customer_id
          this.crmForm.money = projectInfo.money
          this.crmForm.percent = projectInfo.percent
          this.crmForm.businessType = projectInfo.businessType
        } else {
          this.crmForm.businessId = ''
          this.crmForm.customerName = ''
          this.crmForm.customerId = ''
          this.crmForm.money = ''
          this.crmForm.percent = ''
          this.crmForm.businessType = ''
        }
      } else if (type === 'sysFileEntityList') {
        this.crmForm.sysFileEntityList = val.value
        this.$refs.crmForm.validateField('sysFileEntityList')
      }
    },
    getRequest() {
      if (this.action.type == 'save') {
        return equipmentauthSave
      } else if (this.action.type == 'update') {
        return equipmentauthUpdate
      }
    },
    handleTypeClick(type) {
      if (!this.crmForm.borrowRecordEntities.length) {
        this.$notify({
          title: this.$t('filter.f13'),
          message: this.$t('filter.f73'),
          type: 'warning',
          duration: 5000,
        })
        return
      }
      this.$refs.crmForm.validate((valid) => {
        if (valid) {
          let params = _.cloneDeep(this.crmForm)
          // params.filedDate = moment(params.filedDate).format();
          if (type == 'save') {
            params.saveType = '1'
            this.saveLoad = true
            this.saveDrafDisable = true
          } else if (type == 'saveDraft') {
            params.saveType = '0'
            this.saveDrafLoad = true
            this.saveDisable = true
          }
          params.authFiledType =
            params.borrowRecordEntities[0].usedCount == '0' ? '0' : '1'
          params.list = params.borrowRecordEntities.map((item) => {
            delete item.endDate
            item.startDate = moment(item.startDate).format()
            return item
          })
          delete params.borrowRecordEntities
          let request = this.getRequest()
          request(params)
            .then((res) => {
              this.$message.success(this.$t('filter.f11'))
              this.$emit('save-success', {
                type: this.action.type,
                saveType: type,
              })
              this.saveLoad = false
              this.saveDisable = false
              this.saveDrafLoad = false
              this.saveDrafDisable = false
              this.hidenView()
            })
            .catch((err) => {
              window.console.log('err', err)
              this.saveLoad = false
              this.saveDisable = false
              this.saveDrafLoad = false
              this.saveDrafDisable = false
            })
        } else {
          this.$nextTick(() => {
            let isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth',
            })
          })
          return
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.crm-create-container {
  position: relative;
  height: 100%;
  padding-top: 10px;
}
.crm-create-flex {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}
.handle-bar {
  position: relative;
  margin: 0 auto;
  .handle-button {
    margin-top: 5px;
    margin-right: 10px;
  }
}
.crm-create-header {
  height: 40px;
  margin-bottom: 20px;
  padding: 0 10px;
  flex-shrink: 0;
  .close {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: -10px;
    padding: 10px;
    cursor: pointer;
  }
}
.el-form-item {
  margin-bottom: 15px;
}
.productInfo {
  .el-form-item {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 0;
    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }
    &.is-error {
      margin-bottom: 20px;
    }
  }
}
</style>