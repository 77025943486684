import request from '@/utils/request'
// 查看我的草稿
export function getListMyDraft(data) {
  return request({
    url: '/sf-partnerportal/pp/approval/listMyDraft',
    method: 'post',
    data: data
  })
}
// 审批动态
export function listCommentByBusinessKey(data) {
  return request({
    url: '/sf-partnerportal/activiti/flow/listCommentByBusinessKey',
    method: 'post',
    data
  })
}

// 待我审批列表
export function getListMyApproval(data) {
  return request({
    url: '/sf-partnerportal/pp/approval/listMyApproval',
    method: 'post',
    data
  })
}

// 获取项目续报备审批详情
export function getProjectdetail(data) {
  return request({
    url: 'sf-partnerportal/pp/project/approvalInfo',
    method: 'post',
    data: data
  })
}

/* 项目报备通过 */
export function projectDoApprovePA(data) {
  return request({
    url: '/sf-partnerportal/pp/project/doApprovePA',
    method: 'post',
    data
  })
}

// PP发起的流程通用驳回
export function flowBackFirst(data) {
  return request({
    url: '/sf-partnerportal/sys/flow/backFirst',
    method: 'post',
    data
  })
}

// 查看我所有审批
export function getListMyApply(data) {
  return request({
    url: '/sf-partnerportal/pp/approval/listMy',
    method: 'post',
    data: data
  })
}
