<template>
  <!-- 设备退押金审批详情 -->
  <div style="height: 100%">
    <flexbox
      v-loading="loading"
      direction="column"
      align="stretch"
      class="d-container"
    >
      <c-r-m-detail-head
        :title="detailData.flowNo || row.flowNo"
        :head-details="headDetails"
        @hideView="hideView"
      >
        <div slot="right" class="header">
          <el-button
            v-if="
              (detailData.status == '0' || detailData.status == '5') &&
              detailData.applicantId == userInfo.userId
            "
            type="primary"
            size="medium"
            icon="el-icon-extendshouquan1"
            @click="openEditView"
            >{{ $t("common.btn.edit") }}</el-button
          >
        </div>
        <div class="busi-line" />
      </c-r-m-detail-head>
      <div id="follow-log-content" class="t-loading-content">
        <basic-info
          :detail="detailData"
          :list="infoList"
          itemWidth="25%"
          labelWidth="180px"
        >
        </basic-info>
        <!-- 产品信息 -->
        <create-sections
          :title="$t('detail.projectInfo')"
          style="margin-top: 30px"
        >
          <el-table
            :data="detailData.listRecord"
            stripe
            border
            highlight-current-row
            class="productInfo"
            style="width: 100%; margin-top: 5px"
          >
            <el-table-column
              :label="$t('detail.productName')"
              prop="itemName"
              header-align="center"
              :formatter="fieldFormatter"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              :label="$t('detail.productType')"
              prop="productType"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.productType == "0"
                      ? $t("allEquipment.product")
                      : scope.row.productType == "1"
                      ? $t("allEquipment.accessory")
                      : "--"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('detail.d21')"
              prop="itemNum"
              align="center"
              :formatter="fieldFormatter"
              width="80"
            >
            </el-table-column>
            <!-- 押金 -->
            <el-table-column
              prop="itemDeposit"
              :label="$t('allEquipment.deposit')"
              :formatter="fieldFormatter"
              width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="itemSn"
              :label="$t('allEquipment.sn')"
              :formatter="fieldFormatter"
              align="center"
            >
            </el-table-column>
            <el-table-column
              :label="$t('detail.d12')"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <span>
                  {{
                    findDIctName(
                      "equipment_status",
                      scope.row.equipmentStatus
                    ) || "--"
                  }}
                </span>
              </template>
            </el-table-column>
            <!-- 授权状态 -->
            <el-table-column
              :label="$t('allEquipment.authorizationStatus')"
              prop="usedStatus"
              :width="lang == 'CN' ? '100' : '150'"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.usedStatus
                      ? scope.row.usedStatus == "0"
                        ? $t("allEquipment.unauthorizated")
                        : scope.row.usedStatus == "1"
                        ? $t("allEquipment.authorized")
                        : $t("allEquipment.authorizateInProgress")
                      : "--"
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </create-sections>
        <!-- <create-sections :title="$t('approval.a14')" style="margin-top: 30px">
          <flexbox align="stretch" justify="flex-start">
            <el-timeline v-if="commentList.length != 0" style="width: 100%">
              <div v-for="(item, index) in commentList" :key="index">
                <el-timeline-item
                  v-for="(activity, index) in item.child"
                  :key="index"
                  :icon="activity.icon"
                  :type="activity.type"
                  :color="activity.color"
                  :size="activity.size"
                  :hide-timestamp="activity.show"
                  :timestamp="activity.timestamp"
                  placement="top"
                >
                  <el-card
                    v-if="activity.show"
                    style="border: 1px solid #d7e8f4; background: #f7fbfe"
                  >
                    <div slot="header" class="clearfix">
                      <p
                        style="
                          width: 100%;
                          display: inline-block;
                          line-height: 20px;
                        "
                      >
                        <span>{{ activity.userName }}</span>
                        <span style="float: right">{{
                          activity.createdDate
                        }}</span>
                      </p>
                    </div>
                    <div class="card-content">
                      <p>{{ activity.fullMessage }}</p>
                    </div>
                  </el-card>
                </el-timeline-item>
              </div>
            </el-timeline>
            <div
              v-if="commentList.length == 0"
              style="
                color: #909399;
                margin-top: 20px;
                text-align: center;
                width: 100%;
              "
            >
              {{ $t("task.44") }}
            </div>
          </flexbox>
        </create-sections> -->
      </div>
    </flexbox>
    <!-- 退押金 -->
    <refund-dialog
      v-if="isCreate"
      :visible.sync="isCreate"
      :detail="createActionInfo"
      actionType="update"
      @save-success="createEditSuccess"
    ></refund-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CRMDetailHead from "@c/CRMDetailHead";
import BasicInfo from "@c/BasicInfo";
import moment from "moment";
import { findDIctName, moneyFormat } from "@/utils";
import { packageApproveInfo } from "@/api/allEquipment";
import { listCommentByBusinessKey } from "@/api/approval";
import RefundDialog from "./components/refundDialog";
export default {
  name: "EquipmentRefundDetail",
  components: {
    CRMDetailHead,
    BasicInfo,
    RefundDialog,
  },
  props: {
    // 详情信息id
    id: [String, Number],
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      detailData: {}, // 当前页面详情数据
      crmType: "equipmentRefund",
      infoList: [], // basicinfo的基本信息
      headDetails: [],
      rowType: "",
      commentList: [],
      isCreate: false,
      createActionInfo: {},
      lang: localStorage.getItem("lang"),
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    moment() {
      return moment;
    },
    findDIctName() {
      return findDIctName;
    },
    refundDeposit() {
      return this.detailData.listRecord.reduce((totle, item) => {
        return (totle += item.equipmentStatus == "4" ? item.itemDeposit : 0);
      }, 0);
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    hideView() {
      this.$emit("hide-view");
    },
    fieldFormatter(row, column) {
      return row[column.property] || "--";
    },
    infoFormat(list) {
      list.map((ele) => {
        ele.child &&
          ele.child.map((olo) => {
            olo.length && olo.map((e) => {
              if (e) {
                e.name = e.name || "--";
              }
            });
          });
      });
    },
    getDetail() {
      this.loading = true;
      packageApproveInfo({ id: this.id })
        .then((res) => {
          this.headDetails = [
            {
              title: this.$t("approval.a4"),
              value: res.rows.applicantName,
            },
            {
              title: this.$t("detail.d12"),
              value: findDIctName("flow_status", res.rows.status),
            },
            {
              title: this.$t("approval.a6"),
              value: res.rows.currentName,
            },
          ];
          this.detailData = res.rows;
          this.loading = false;
          // if (res.rows.flowId) {
          //   this.getComment();
          // }
          this.getBaiscInfo();
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: err.msg,
          });
          this.loading = false;
        });
    },
    getBaiscInfo() {
      this.infoList = [
        {
          title: this.$t("detail.basicInfo"), // "基本信息",
          child: [
            [
              {
                label: this.$t("allEquipment.channel"), // 渠道商
                name: this.detailData.channelName,
              },
              {
                label: this.$t("approval.a4"), // 申请人
                name: this.detailData.applicantName,
              },
              {
                label: this.$t("approval.a5"), // 申请时间
                name: this.detailData.applicantDate
                  ? moment(this.detailData.applicantDate).format("YYYY-MM-DD")
                  : "--",
              },
              {
                label: "Package", // package
                name: this.detailData.packageName,
              },
            ],
          ],
        },
        {
          title: this.$t("allEquipment.projectInformation"), // 项目信息
          itemWidth: "50%",
          child: [
            [
              {
                label: this.$t("detail.customerName"), // 客户
                name: this.detailData.customerName,
              },
              {
                label: this.$t("detail.projectName"), // 项目
                name: this.detailData.businessName,
              },
            ],
            [
              {
                label: this.$t("allEquipment.projectStage"), // 项目所属阶段
                name: this.detailData.percent,
              },
              {
                label: this.$t("allEquipment.projectAmount"), // 项目金额
                name: moneyFormat(this.detailData.money),
              },
            ],
            // [
            //   {
            //     label: "申请原因", // 申请原因
            //     name: this.detailData.pursueReason,
            //   },
            // ],
          ],
        },
        {
          title: this.$t("allEquipment.financialInformation"), //  "财务信息",
          itemWidth: "50%",
          child: [
            [
              {
                label: this.$t("allEquipment.totalDeposit"), // 总押金
                name:
                  this.detailData.depositStatus != "1" &&
                  this.detailData.totalDeposit
                    ? moneyFormat(
                        Number(this.detailData.totalDeposit).toFixed(2)
                      )
                    : "--",
              },
              {
                label: this.$t("allEquipment.requiredRefundAmount"), // 需退押金
                name:
                  this.detailData.depositStatus != "1" && this.refundDeposit
                    ? moneyFormat(Number(this.refundDeposit).toFixed(2))
                    : "--",
              },
              this.detailData.status == "3" && {
                label: this.$t("allEquipment.actualRefundAmount"), // 实际退还押金
                name:
                  this.detailData.depositStatus != "1" &&
                  this.detailData.status == "3"
                    ? moneyFormat(Number(this.detailData.realRefundDeposit).toFixed(2))
                    : "--",
              },
              {
                label: this.$t("detail.remark"), // 备注
                name: this.detailData.remark,
              },
            ],
          ],
        },
      ];
      this.infoFormat(this.infoList);
    },
    getComment() {
      listCommentByBusinessKey(this.detailData.flowId).then((res) => {
        this.commentList = [];
        let commentList = res.rows;
        _.uniq(
          commentList.map((e) => {
            e.sign = e.time.slice(0, 10);
            return e.time.slice(0, 10);
          })
        ).map((ele) => {
          let obj = {
            temp: ele,
            child: [
              {
                timestamp: ele,
                show: false,
                size: "large",
              },
            ],
          };
          commentList.map((e) => {
            if (e.sign == ele) {
              e.show = true;
              e.createdDate = moment(e.time).format("YYYY-MM-DD HH:mm:ss");

              (e.showComment = false), obj.child.push(e);
            }
          });
          this.commentList.push(obj);
        });
      });
    },
    down(item) {
      var a = document.createElement("a");
      var filename = item.fileName;
      a.href = location.protocol + "//" + item.fileUrl;
      a.download = filename;
      a.click();
    },
    openEditView() {
      this.isCreate = true;
      this.createActionInfo = {
        ...this.detailData,
        borrowRecordEntities: this.detailData.listRecord,
      };
    },
    createEditSuccess(type) {
      this.getDetail();
      this.detailHeadHandle(type);
    },
    /** 顶部头 操作 */
    detailHeadHandle(data) {
      window.console.log("data", data);
      if (data.saveType == "save") {
        this.hideView();
      }
      this.$emit("handle", data);
    },
  },
};
</script>

<style lang="less" scoped>
.d-container {
  height: 100%;

  .header {
    display: flex;
    align-items: center;
  }

  .t-loading-content {
    flex: 1;
    overflow: auto;
    margin-top: 15px;
  }
}

.el-button {
  padding: 10px 11px;
}
.busi-line {
  position: absolute;
  bottom: 0;
  left: 17px;
  right: 17px;
  height: 1px;
  background-color: #e6e6e6;
}
</style>