<template>
  <transition name="opacity-fade">
    <el-card v-loading="loading" :style="{ 'z-index': zIndex }" :body-style="bodyStyle"
      class="c-view crm-create-card-container">
      <slot name="header" />
      <slot />
    </el-card>
  </transition>
</template>

<script>
  import {
    getMaxIndex
  } from "@/utils/index";
  export default {
    name: "CreateView", // 所有新建效果的view
    components: {},
    props: {
      bodyStyle: {
        type: Object,
        default: () => {
          return {};
        },
      },
      loading: {
        type: Boolean,
        default: false,
      },
      // 更改背景颜色颜色
      backgroundColor: {
        type: String,
        default: "white",
      },
      /** 展示内容的上下padding */
      padding: {
        type: String,
        default: "10",
      },
    },
    data() {
      return {
        zIndex: getMaxIndex(),
      };
    },
  };
</script>

<style lang="less" scoped>
  .opacity-fade-enter-active,
  .opacity-fade-leave-active {
    transition: all 0.2s;
  }

  .opacity-fade-enter,
  .opacity-fade-leave-to {
    opacity: 0;
  }

  /** 容器布局 */
  .c-view {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .crm-create-card-container {
    margin: 0 auto;
  }

  /deep/.el-card__body {
    height: 100%;
  }
</style>