var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[_c('el-aside',{staticClass:"aside-container",attrs:{"width":"auto"}},[_c('div',{staticClass:"sidebar-container",on:{"click":_vm.toggleSideBarClick}},[_c('i',{staticClass:"collapse-button",class:_vm.collapse ? 'el-icon-extendshouqi1' : 'el-icon-extendzhankai'})]),_c('el-menu',{staticClass:"el-menu-vertical",style:({
        'border-right-color': _vm.backgroundColor,
        'padding-top': '40px'
      }),attrs:{"default-active":_vm.activeIndex,"text-color":_vm.textColor,"background-color":_vm.backgroundColor,"active-text-color":_vm.activeTextColor,"collapse":_vm.collapse}},[_vm._l((this.crmMenu),function(item,index){return [(!item.children)?_c('router-link',{key:index,attrs:{"to":item.fullPath}},[_c('el-menu-item',{staticClass:"menu-item-defalt",class:{ 'menu-item-select': _vm.activeIndex == item.fullPath },attrs:{"index":item.fullPath}},[_c('i',{staticClass:"el-icon",class:'el-icon-' + item.icon,style:({
                color:
                  _vm.activeIndex == item.fullPath ? _vm.activeTextColor : _vm.textColor,
                fontSize: item.fontSize || '16px'
              })}),_c('span',{staticClass:"menuTitle",attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.title))])])],1):_c('el-submenu',{key:index,attrs:{"index":item.fullPath}},[(!item.hidden)?_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon menuIcon",class:'el-icon-' + item.icon,style:({ fontSize: item.fontSize || '16px' })}),_c('span',{staticClass:"menuTitle",attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.title))])]):_vm._e(),_vm._l((_vm.getShowMenu(item.children)),function(subitem,subindex){return _c('router-link',{key:subindex,attrs:{"to":subitem.fullPath}},[_c('el-menu-item',{staticClass:"menu-item-defalt",class:{ 'menu-item-select': _vm.activeIndex == subitem.fullPath },attrs:{"index":subitem.fullPath}},[_c('i',{staticClass:"el-icon menuIcon",class:'el-icon-' + subitem.icon,style:({ fontSize: subitem.fontSize || '16px' })}),_c('span',{staticClass:"menuTitle",attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(subitem.title))]),(
                  _vm.$store.state.user.countMyTask > 0 &&
                    subitem.path == 'needMyApproval'
                )?_c('el-badge',{attrs:{"max":99,"value":_vm.$store.getters.taskCount}}):_vm._e()],1)],1)})],2)]})],2)],1),_c('el-container',[_c('el-header',{staticClass:"navbar"},[_c('div',{staticClass:"navbar-left"},[_c('img',{staticClass:"logo",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.navlogo},on:{"click":_vm.goHome}})]),_c('div',{staticClass:"navbar-right"},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"visible-change":function (val) { return (_vm.userShow = val); },"command":_vm.handleCommand}},[_c('div',{staticClass:"user-container"},[_vm._v(" "+_vm._s(_vm.userInfo.surname || '')+" "+_vm._s(_vm.userInfo.name)+" "),_c('i',{staticClass:"mark",class:_vm.userShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'})]),_c('el-dropdown-menu',{staticClass:"user-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"user","icon":"el-icon-extendziliao"}},[_vm._v(" "+_vm._s(_vm.$t('other.7'))+" ")]),_c('el-dropdown-item',{attrs:{"command":"loginOut","icon":"el-icon-extendtuichu"}},[_vm._v(" "+_vm._s(_vm.$t('other.2'))+" ")])],1)],1)],1)]),_c('el-main',{staticStyle:{"height":"100%","padding":"0","margin":"15px","position":"relative"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }