<template>
  <div>
    <el-button slot="trigger"
               @click="openFileDialog()"
               size="small"
               type="primary">{{ $t("components.c10") }}</el-button>
    <h4 v-show="dataValue.length">{{ $t('common.uplodeList') }}</h4>
    <ul v-for="(item,index) in dataValue"
        :key="index">
      <li class="file">
        <span class="label">{{ item.fileName }}</span>
        <span class="value"><img class="success-img"
               src="@/assets/img/uplode_success.png"></span>
      </li>
    </ul>
    <p v-show="showTip"
       style="color: #999999">
      {{ $t("components.c11") }}
    </p>
    <el-dialog :append-to-body="true"
               :modal-append-to-body="false"
               class="fileDialog"
               :modal="isShowModal"
               :title="$t('components.c1')"
               width="550px"
               :visible.sync="dialogFileVisible"
               v-dialogDrag>
      <div class="dialogContent">
        <el-table :data="dataValue"
                  :header-cell-style="{ background: '#ffffff' }"
                  stripe
                  style="width: 100%">
          <el-table-column prop="fileName"
                           :label="$t('components.c2')"></el-table-column>
          <el-table-column prop="createdByName"
                           :label="$t('components.c3')"></el-table-column>
          <el-table-column prop="createdDate"
                           width="165px"
                           :label="$t('components.c4')">
            <template slot-scope="scope">{{
              scope.row.createdDate | dateformat
            }}</template>
          </el-table-column>
          <el-table-column width="90px"
                           fixed="right"
                           :label="$t('components.c5')">
            <template slot-scope="scope">
              <el-button type="text"
                         v-if="scope.row.fileUrl"
                         @click="down(scope.row)"
                         size="small">{{ $t("components.c6") }}</el-button>
              <el-button style="color: red"
                         @click="deleteFile(scope.row, scope.$index)"
                         type="text"
                         size="small">{{ $t("components.c7") }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 10px 20px 0px 20px">
          <el-upload ref="upload"
                     :multiple="true"
                     :data="formData"
                     name="uploadFiles"
                     :auto-upload="false"
                     :headers="{ Authorization: 'Bearer ' + token, 'Accept-Language':lang === 'CN' ? 'zh-CN' : 'en' }"
                     :on-remove="removeFile"
                     :on-progress="uploadProcess"
                     :before-remove="beforeRemove"
                     :on-error="uploadError"
                     :on-success="uploadSuccess"
                     drag
                     :file-list="fileList"
                     :action="
             !id
                ? apiUrl + 'api-gsm/fileUploadUpdate/fileLoad'
                : apiUrl + 'api-gsm/fileUploadUpdate/fileLoadDate'
            ">
            <!-- :show-file-list="false" -->
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              {{ $t("components.c8") }}
              <em>{{ $t("components.c9") }}</em>
            </div>
          </el-upload>
          <el-button :disabled="isSubmit"
                     style="margin: 10px"
                     size="small"
                     type="success"
                     @click="submitUpload">{{ $t("components.c9") }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script type="text/javascript">
import arrayMixin from "./arrayMixin";
import Lockr from "lockr";
import { delFile } from "@/api/common";

export default {
  name: "XhFiles", // 新建 file  以数组的形式上传
  components: {},
  mixins: [arrayMixin],
  props: {
    showTip: {
      type: Boolean,
      default: true,
    },
    id: [String, Number],
    crmType: {
      type: String,
      default: "",
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      requestAPI: "",
      formData: {},
      isSubmit: false,
      token: "",
      fileList: [],
      dialogFileVisible: false,
      batchId: "", // 批次ID
      lang: localStorage.getItem("lang"),
    };
  },
  computed: {},
  watch: {},
  mounted () {
    this.token = Lockr.get("access_pp_token");
    if (this.id) {
      this.formData.bizId = this.id;
      this.formData.bizName = this.crmType;
    }
    let requestAPI = document.location.hostname + ":9200";
    // console.log(document.location.hostname+':9200')
    // console.log(this.process.env.API_ROOT)
  },
  methods: {
    uploadSectionFile: function (param) {
      //自定义文件上传

      var fileObj = param.file;
      // 接收上传文件的后台地址
      var FileController =
        "http://200.200.4.124:9200/api-gsm/fileUploadUpdate/fileLoad";
      // FormData 对象
      var form = new FormData();
      // 文件对象
      form.append("uploadFiles", [fileObj]);
      // 其他参数
      // form.append("xxx", xxx);
      // XMLHttpRequest 对象
      var xhr = new XMLHttpRequest();
      xhr.open("post", FileController, true);
      xhr.setRequestHeader(
        "Authorization",
        "Bearer 00e2a9f5-cee0-40fa-8524-cee53d4d88f7"
      );

      // xhr.upload.addEventListener("progress", vm.progressFunction, false); //监听上传进度
      xhr.onload = function () {
        vm.Form.playUrl = xhr.response; //接收上传到阿里云的文件地址
        vm.$message({
          message: "success",
          type: "success",
        });
      };
      xhr.send(form);
    },

    submitUpload () {
      this.$refs.upload.submit();
    },
    openFileDialog () {
      console.log(this.dataValue);
      this.dialogFileVisible = true;
    },
    delFile (id) {
      console.log(id);
    },
    removeFile (file, fileList) { },
    uploadError (err, file, fileList) {
      let error = err.toString();
      error = error.replace("Error: ", "")
      error = JSON.parse(error);
      this.$message({
        type: "error",
        message: error.msg
      })
    },
    uploadProcess (event, file, fileList) { },
    beforeRemove (res) { },
    uploadSuccess (res, file, fileList) {
      this.dataValue = [...this.dataValue, ...res.rows];
      this.fileList = fileList;
      this.$emit("value-change", {
        index: this.index,
        parantIndex: this.parantIndex,
        value: this.dataValue,
      });
    },
    selectImage () {
      if (!this.disabled) {
        document.getElementById("xhImageInput" + this.index || "0").click();
      }
    },
    down (item) {
      var a = document.createElement("a");
      var filename = item.fileName;
      // a.href = "http://" + item.fileUrl;
      a.href = location.protocol + "//" + item.fileUrl;
      a.download = filename;
      a.click();
    },
    /** 删除图片 */
    deleteFile (item, index) {
      this.$confirm(this.$t("other.6") + "?", this.$t("other.1"), {
        confirmButtonText: this.$t("other.4"),
        cancelButtonText: this.$t("other.5"),
        type: "warning",
      })
        .then(() => {
          delFile({
            id: item.id,
          })
            .then((res) => {
              this.dataValue.splice(index, 1);
              this.$emit("value-change", {
                index: this.index,
                value: this.dataValue,
              });
              this.$message.success(this.$t("filter.f11"));
            })
            .catch(() => { });
        })
        .catch(() => { });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-upload-dragger {
  width: 510px !important;
}
/** 附件  */
.xh-files-cont {
  position: relative;
  display: inline-block;
  border-radius: 3px;
  width: 100%;
  border: 1px solid #ddd;
  padding: 3.5px 5px;
  margin: 3px;
  line-height: 15px;
}

.xh-files-cont.is_disabled {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  cursor: not-allowed;
  .f-name {
    background-color: #f0f4f8ea;
    color: #c0c4cc;
    cursor: not-allowed;
  }
}

.xh-files-cont.is_valid:hover {
  border-color: #c0c4cc;
}

.f-header {
  cursor: pointer;
  padding: 5px 0 5px;
  .f-logo {
    position: block;
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
  .f-name {
    color: #3e84e9;
    font-size: 12px;
  }
}
.f-header.is_disabled {
  cursor: not-allowed;
}

.f-body {
  .f-item {
    padding: 3px 0;
    height: 25px;
    .f-img {
      position: block;
      width: 15px;
      height: 15px;
      padding: 0 1px;
      margin-right: 8px;
    }
    .f-name {
      color: #666;
      font-size: 12px;
    }
    .close-button {
      cursor: pointer;
    }
  }
}

.bar-iput {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  opacity: 0;
}
.file {
  display: flex;
  align-items: center;
  max-width: 540px;
  justify-content: space-between;
  margin-bottom: 10px;
  .label {
    max-width: 480px;
    white-space: pre-wrap;
    line-height: 25px;
  }
  .value {
    display: flex;
    align-items: center;
    .success-img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
