/** 记录 侧边索引 */
const app = {
  state: {
    name: '',
    sidebar: {
      activeIndex: '', // 目前激活的 菜单
      activeName: [] // 目前激活的 菜单名
    }
  },

  mutations: {
    SET_ACTIVEINDEX: (state, path) => {
      state.sidebar.activeIndex = path
    },
    SET_APPNAME: (state, name) => {
      state.name = name
    },
    SET_ACTIVENAME: (state, name) => {
      state.sidebar.activeName = name
    }
  },

  actions: {}
}

export default app
