<!--
详情基本信息暂时组件
props
  list 列表，多个信息集合，每个元素表示一个类种的集合，[
        {
          title: "基本信息", // "基本信息",
          itemWidth: "", // 每个元素占一行多宽，不写默认50%
          child: [
            child下每个元素为数组，代表一行，可自由决定一行多少个元素，也可将所有元素丢到一个数组里，自动换行。
            [
              {
                label: "label",
                name: "name",
              },
            ],
          ],
        }]
  labelWidth  每一元素label宽度,默认120px
-->
<template>
  <div>
    <div v-for="(item, index) in list"
         :key="index">
      <create-sections :title="item.title"
                       :noShowMark="item.noShowMark"
                       :noShowLine="item.noShowLine"
                       class="parent"
                       v-if="item">
        <div>
          <el-row class="child"
                  v-for="(ele, i) in item.child"
                  :key="i">
            <flexbox align="center"
                     wrap="wrap">
              <template v-for="(e, n) in ele">
                <div v-if="e"
                     :key="n"
                     :style="{ width: item.itemWidth || '50%' }"
                     style="display: flex; align-items: center"
                     class="child-item">
                  <div class="item-label"
                       :style="{ width: labelWidth }">
                    <!-- <div
                    class="item-label"
                    style="
                      margin: 5px 10px;
                      word-wrap: break-word;
                      word-break: break-all;
                    "
                  > -->
                    {{ e.label + ":" }}
                  </div>
                  <div>
                    <span v-if="!e.gotoDetail && !e.id"
                          class="name">{{
                      e.name
                    }}</span>
                    <a v-else
                       style="color: rgb(62, 132, 233)"
                       @click="gotoDetail(e.id, e.crmType)"
                       class="name">{{ e.name }}</a>
                  </div>
                </div>
              </template>
            </flexbox>
          </el-row>
        </div>
      </create-sections>
    </div>
  </div>
</template>

<script>
import CreateSections from "@c/CreateSections";
export default {
  name: "BasicInfo",
  components: {
    CreateSections,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 每一元素宽度，类似于el-form的label-width
    labelWidth: {
      type: String,
      default: "120px",
    },
  },
  methods: {
    gotoDetail (id, crmType) { },
  },
};
</script>

<style lang="less" scoped>
.parent {
  .child {
    // display: block;
    .child-item {
      margin-bottom: 30px;
    }
    .item-label {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      line-height: 20px;
      color: #999a9e;
      opacity: 1;
      text-align: right;
      margin-right: 30px;
      min-width: 200px;
    }
    .name {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      line-height: 20px;
      color: #343a40;
      opacity: 1;
    }
  }
}
</style>