<template>
  <!-- 设备授权详情 -->
  <div style="height: 100%">
    <flexbox v-loading="loading"
             direction="column"
             align="stretch"
             class="d-container">
      <c-r-m-detail-head :title="detailData.flowNo || row.flowNo"
                         :head-details="headDetails"
                         @hideView="hideView">
        <div slot="right"
             class="header">
          <el-button v-if="
              (detailData.status == '0' || detailData.status == '5') &&
              detailData.applicantId == userInfo.userId
            "
                     type="primary"
                     size="medium"
                     icon="el-icon-extendshouquan1"
                     @click="openEditView">{{ $t("common.btn.edit") }}</el-button>
        </div>
        <div class="busi-line" />
      </c-r-m-detail-head>
      <div id="follow-log-content"
           class="t-loading-content">
        <basic-info :detail="detailData"
                    :list="infoList"
                    itemWidth="25%"
                    labelWidth="130px">
        </basic-info>
        <create-sections :title="$t('detail.files')"
                         style="margin-top: 30px">
          <el-table :data="detailData.sysFileEntityList"
                    stripe
                    border
                    style="width: 100% margin-top: 5px">
            <el-table-column prop="fileName"
                             min-width="200px"
                             :label="$t('components.c2')"
                             align="center"></el-table-column>
            <el-table-column prop="createdByName"
                             :label="$t('components.c3')"
                             align="center"></el-table-column>
            <el-table-column prop="createdDate"
                             :label="$t('components.c4')"
                             align="center">
              <template slot-scope="scope">{{
                scope.row.createdDate | dateformat
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('components.c5')"
                             align="center">
              <template slot-scope="scope">
                <el-button type="text"
                           v-if="scope.row.fileUrl"
                           @click="down(scope.row)"
                           size="small">{{ $t("components.c6") }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </create-sections>
        <!-- 产品信息 -->
        <create-sections :title="$t('detail.projectInfo')"
                         style="margin-top: 30px">
          <el-table :data="detailData.borrowRecordEntities"
                    stripe
                    border
                    highlight-current-row
                    class="productInfo"
                    style="width: 100%; margin-top: 5px">
            <el-table-column :label="$t('detail.productName')"
                             prop="itemName"
                             align="center"></el-table-column>
            <el-table-column :label="$t('detail.productType')"
                             prop="productType"
                             width="100"
                             align="center">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.productType == "0"
                      ? $t("allEquipment.product")
                      : scope.row.productType == "1"
                      ? $t("allEquipment.accessory")
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
            <!-- 授权开始日期 -->
            <el-table-column :label="$t('allEquipment.authorizationStartDate')"
                             prop="startDate"
                             width="180"
                             align="center">
              <template slot-scope="scope">
                <div>
                  {{ moment(scope.row.startDate).format("YYYY-MM-DD") }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('allEquipment.sn')"
                             prop="itemSn"
                             align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.itemSn || "--" }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('detail.remark')"
                             prop="remark"
                             align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.remark || "--" }}
                </div>
              </template>
            </el-table-column>
            <!-- 授权次数 -->
            <el-table-column :label="$t('allEquipment.noOfAuthorization')"
                             prop="usedCount"
                             :width="lang == 'CN' ? '90' : '130'"
                             align="center">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.usedCount }}
                </div>
              </template>
            </el-table-column>
            <!-- 授权文件 -->
            <!-- <el-table-column
              :label="$t('allEquipment.licenseFile')"
              align="center"
              width="90"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="showLicense(scope.row.sysFileEntityList)"
                  >{{ $t("allEquipment.view") }}</el-button
                >
              </template>
            </el-table-column> -->
          </el-table>
        </create-sections>
        <!-- <create-sections :title="$t('approval.a14')" style="margin-top: 30px">
          <flexbox align="stretch" justify="flex-start">
            <el-timeline v-if="commentList.length != 0" style="width: 100%">
              <div v-for="(item, index) in commentList" :key="index">
                <el-timeline-item
                  v-for="(activity, index) in item.child"
                  :key="index"
                  :icon="activity.icon"
                  :type="activity.type"
                  :color="activity.color"
                  :size="activity.size"
                  :hide-timestamp="activity.show"
                  :timestamp="activity.timestamp"
                  placement="top"
                >
                  <el-card
                    v-if="activity.show"
                    style="border: 1px solid #d7e8f4; background: #f7fbfe"
                  >
                    <div slot="header" class="clearfix">
                      <p
                        style="
                          width: 100%;
                          display: inline-block;
                          line-height: 20px;
                        "
                      >
                        <span>{{ activity.userName }}</span>
                        <span style="float: right">{{
                          activity.createdDate
                        }}</span>
                      </p>
                    </div>
                    <div class="card-content">
                      <p>{{ activity.fullMessage }}</p>
                    </div>
                  </el-card>
                </el-timeline-item>
              </div>
            </el-timeline>
            <div
              v-if="commentList.length == 0"
              style="
                color: #909399;
                margin-top: 20px;
                text-align: center;
                width: 100%;
              "
            >
              {{ $t("task.44") }}
            </div>
          </flexbox>
        </create-sections> -->
      </div>
    </flexbox>
    <el-dialog :title="$t('allEquipment.licenseFile')"
               width="550px"
               :visible.sync="showLicenseDialog"
               class="license"
               append-to-body
               v-dialogDrag>
      <el-table :data="licenseData"
                stripe
                border
                highlight-current-row>
        <el-table-column prop="fileName"
                         :label="$t('components.c2')"></el-table-column>
        <el-table-column prop="createdByName"
                         :label="$t('components.c3')"></el-table-column>
        <el-table-column prop="createdDate"
                         width="145px"
                         :label="$t('components.c4')">
          <template slot-scope="scope">{{
            scope.row.createdDate | dateformat
          }}</template>
        </el-table-column>
        <el-table-column width="85px"
                         :label="$t('components.c5')">
          <template slot-scope="scope">
            <el-button type="text"
                       v-if="scope.row.fileUrl"
                       @click="down(scope.row)"
                       size="small">{{ $t("components.c6") }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <c-r-m-create-view v-if="isCreate"
                       :visible.sync="isCreate"
                       :crm-type="rowType"
                       :action="createActionInfo"
                       @save-success="createEditSuccess"
                       @hiden-view="isCreate = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CRMDetailHead from '@c/CRMDetailHead'
import BasicInfo from '@c/BasicInfo'
import moment from 'moment'
import { findDIctName, moneyFormat } from '@/utils'
import { equipmentauthInfo } from '@/api/allEquipment'
import { listCommentByBusinessKey } from '@/api/approval'
export default {
  name: 'PackageDetail',
  components: {
    CRMDetailHead,
    BasicInfo,
  },
  props: {
    // 详情信息id
    id: [String, Number],
    row: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      detailData: {}, // 当前页面详情数据
      crmType: 'equipmentAuthorization',
      infoList: [], // basicinfo的基本信息
      headDetails: [],
      rowType: '',
      commentList: [],
      isCreate: false,
      createActionInfo: {
        type: 'update',
      },
      lang: localStorage.getItem('lang'),
      licenseData: [],
      showLicenseDialog: false,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    moment() {
      return moment
    },
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    hideView() {
      this.$emit('hide-view')
    },
    infoFormat(list) {
      list.map((ele) => {
        ele.child &&
          ele.child.map((olo) => {
            olo.length &&
              olo.map((e) => {
                if (e) {
                  e.name = e.name || '--'
                }
              })
          })
      })
    },
    getDetail() {
      this.loading = true
      equipmentauthInfo({ id: this.id })
        .then((res) => {
          this.headDetails = [
            {
              title: this.$t('approval.a4'),
              value: res.rows.applicantName,
            },
            {
              title: this.$t('detail.d12'),
              value: findDIctName('flow_status', res.rows.status),
            },
            {
              title: this.$t('approval.a6'),
              value: res.rows.currentName,
            },
          ]
          this.detailData = res.rows
          this.loading = false
          // if (res.rows.flowId) {
          //   this.getComment();
          // }
          this.getBaiscInfo()
          this.licenseData = res.rows.sysFileEntityList
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: err.msg,
          })
          this.loading = false
        })
    },
    getBaiscInfo() {
      this.infoList = [
        {
          title: this.$t('detail.basicInfo'), // "基本信息",
          child: [
            [
              {
                label: this.$t('allEquipment.channel'), // 渠道商
                name: this.detailData.channelName,
              },
              {
                label: this.$t('approval.a4'), // 申请人
                name: this.detailData.applicantName,
              },
              {
                label: this.$t('approval.a5'), // 申请时间
                name: this.detailData.filedDate
                  ? moment(this.detailData.filedDate).format('YYYY-MM-DD')
                  : '--',
              },
              {
                label: 'package', // package
                name: this.detailData.packageName,
              },
            ],
          ],
        },
        {
          title: this.$t('allEquipment.projectInformation'), // 项目信息
          itemWidth: '50%',
          child: [
            [
              {
                label: this.$t('detail.customerName'), // 客户
                name: this.detailData.customerName,
              },
              {
                label: this.$t('detail.projectName'), // 项目
                name: this.detailData.businessName,
              },
            ],
            [
              {
                label: this.$t('allEquipment.projectStage'), // 项目所属阶段
                name: this.detailData.percent,
              },
              {
                label: this.$t('allEquipment.projectAmount'), // 项目金额
                name: moneyFormat(this.detailData.money),
              },
            ],
            [
              {
                label: this.$t('allEquipment.applyReason'), // 申请原因
                name: this.detailData.pursueReason,
              },
            ],
          ],
        },
      ]
      this.infoFormat(this.infoList)
    },
    getComment() {
      listCommentByBusinessKey(this.detailData.flowId).then((res) => {
        this.commentList = []
        let commentList = res.rows
        _.uniq(
          commentList.map((e) => {
            e.sign = e.time.slice(0, 10)
            return e.time.slice(0, 10)
          })
        ).map((ele) => {
          let obj = {
            temp: ele,
            child: [
              {
                timestamp: ele,
                show: false,
                size: 'large',
              },
            ],
          }
          commentList.map((e) => {
            if (e.sign == ele) {
              e.show = true
              e.createdDate = moment(e.time).format('YYYY-MM-DD HH:mm:ss')
              ;(e.showComment = false), obj.child.push(e)
            }
          })
          this.commentList.push(obj)
        })
      })
    },
    down(item) {
      var a = document.createElement('a')
      var filename = item.fileName
      a.href = location.protocol + '//' + item.fileUrl
      a.download = filename
      a.click()
    },
    openEditView() {
      this.isCreate = true
      this.rowType = 'equipmentAuthorization'
      this.createActionInfo.data = this.detailData
      this.createActionInfo.deptId = this.detailData.deptId
    },
    createEditSuccess(type) {
      this.getDetail()
      this.detailHeadHandle(type)
    },
    /** 顶部头 操作 */
    detailHeadHandle(data) {
      window.console.log('data', data)
      if (data.saveType == 'save') {
        this.hideView()
      }
      this.$emit('handle', data)
    },
    showLicense(file) {
      // this.licenseData = file || [];
      this.showLicenseDialog = true
    },
  },
}
</script>

<style lang="less" scoped>
.d-container {
  height: 100%;

  .header {
    display: flex;
    align-items: center;
  }

  .t-loading-content {
    flex: 1;
    overflow: auto;
    margin-top: 15px;
  }
}

.el-button {
  padding: 10px 11px;
}
.busi-line {
  position: absolute;
  bottom: 0;
  left: 17px;
  right: 17px;
  height: 1px;
  background-color: #e6e6e6;
}
</style>