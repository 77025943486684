<template>
  <el-dialog
    :title="checkType === 'approve' ? $t('approval.a15') : $t('approval.a16')"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <el-form
      :model="form"
      :rules="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="审批意见" prop="commit">
        <el-input type="textarea" v-model="form.commit" :rows="6"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { projectDoApprovePA, flowBackFirst } from '@/api/approval'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    checkType: {
      type: String,
      default: ''
    },
    detail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      ruleForm: {
        commit: [
          { required: true, message: this.$t('filter.f68'), trigger: 'change' }
        ]
      },
      form: {
        comment: ''
      },
      loading: false
    }
  },
  methods: {
    reset() {
      this.form = {
        comment: ''
      }
    },
    handleClose() {
      this.$emit('close-dialog', false)
      this.reset()
    },
    submit() {
      this.$refs.ruleForm.validate(async (val) => {
        if (val) {
          this.loading = true
          let data = {
            applyType: '0',
            comment: this.form.commit,
            id: this.detail.flowId,
            taskId: this.detail.taskId,
            approvalSource:"PP"
          }
          if (this.checkType == 'approve') {
            let res = await projectDoApprovePA(data)
            if (res.code == 0) {
              this.$message.success('审批通过')
            } else {
              this.$message.error(res.msg)
            }
          } else {
            let res = await flowBackFirst(data)
            if (res.code == 0) {
              this.$message.success('驳回成功')
            } else {
              this.$message.error(res.msg)
            }
          }
          this.loading = false
          this.$emit('close-dialog', false)
          this.$emit('hide-view')
        } else {
          return this.$message.error('请输入审批意见')
        }
      })
    }
  }
}
</script>

<style></style>
