import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { removeAuth } from '@/utils/auth'
import qs from 'qs'
import router from '../router'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'EN', // 语言标识
  messages: {
    CN: require('../lang/zh'), // 中文语言包
    EN: require('../lang/en') // 英文语言包
  }
})
var showLoginMessageBox = false
if (localStorage.getItem('lang') && localStorage.getItem('lang') == 'CN') {
  axios.defaults.headers['Accept-Language'] = 'zh-CN'
} else {
  axios.defaults.headers['Accept-Language'] = 'en'
}
axios.defaults.headers['Content-Type'] = 'application/json'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // api 的 base_url
  timeout: 60000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(
  config => {
    const flag =
      config.headers['Content-Type'] &&
      config.headers['Content-Type'].indexOf('application/json') !== -1
    if (!flag) {
      const mult =
        config.headers['Content-Type'] &&
        config.headers['Content-Type'].indexOf('multipart/form-data') !== -1
      if (mult) {
        config.data = config.data
      } else {
        config.data = qs.stringify(config.data)
      }
    }
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data
    if (
      (response.status === 200 && response.config.responseType === 'blob') ||
      res.access_token ||
      response.config.url == '/api/api-gsm/crm/project/exportProject'
    ) {
      // 文件类型特殊处理
      return response
    } else if (res.code !== 0) {
      // 302	登录已失效
      if (res.code === 302) {
        if (!showLoginMessageBox) {
          showLoginMessageBox = true
          MessageBox.confirm(i18n.t('other.106'), i18n.t('filter.f13'), {
            showCancelButton: false,
            showClose: true,
            confirmButtonText: i18n.t('other.107'),
            type: 'warning',
            callback: action => {
              showLoginMessageBox = false
              if (action === 'confirm') {
                removeAuth()
                  .then(() => {
                    location.reload() // 为了重新实例化vue-router对象 避免bug
                  })
                  .catch(() => {
                    location.reload()
                  })
              }
            }
          })
        }
      }
      //单独处理Bom下载接口
      else if (response.config.url == '/sf-partnerportal/productRecord/exportBom' || '/sf-partnerporta/productRecord/exportDemo' || '/api/api-file/kb/filedfs/download') {
        return response.data
      } else if (response.config.url == '/sf-partnerportal/workOrder/upgradeWorkOrder') {
        return response.data
      }
      else {
        console.log(response.config.url);
        if (res.msg) {
          Message({
            message: res.msg,
            type: 'error',
            showClose: true
          })
        }
      }
      return Promise.reject(res)
    } else {
      console.log('==================>>>>>message:',i18n.t('other.105'))
      return res
    }
  },
  error => {
    error
    console.log('err' + error) // for debug
    console.log(error.response)
    if (error.response.data.size > 0 && error.response.config.url !== "/sf-partnerportal/pp/assets/downloadLicense") {
      // tryHideFullScreenLoading();

      return error.response.data
    }
    if (error.response.code == 400 || error.response.code == 500) {
      Message({
        message: error.response.msg,
        type: 'error',
        duration: 4 * 1000,
        showClose: true
      })
    }

    if (error.response.status === 401 || error.response.status === 404) {
      localStorage.setItem('access_pp_token', '')
      delete service.defaults.headers['Authorization']
      if (router.currentRoute.path != '/login') {
        if (process.env.NODE_ENV == 'development') {
          router.replace('/login')
        } else {
          window.location.replace(process.env.VUE_APP_JUMP_URL)
        }
      }
      // var url = 'http://' + location.host + '/#/login';
      // location.href = url;
      let message = ''
      if (error.response.status === 401) {
        message = 'Login status expire, please login again.'
      } else {
        message = error.response.data.message || error.response.data.msg
      }
      Message({
        // message: error.response.data.message || error.response.data.msg,
        message,
        type: 'error',
        duration: 4 * 1000,
        showClose: true
      })
    } else if (error.response.status === 403) {
      Message({
        message: error.response.data.message || error.response.data.msg,
        type: 'warning',
        duration: 4 * 1000,
        showClose: true
      })
    }

    if (error.response.status === 500 && (error.response.config.url !== '/sf-partnerportal/productSelection/car/add' && error.response.config.url !== '/sf-partnerportal/productSelection/save')) {
      var msg = error.response.data.errorMsg
      if ((msg == null || msg == undefined) && error.response.config.url !== '/sf-partnerportal/pp/assets/downloadLicense') {
        // msg = error.response.data.msg
        Message({
          message: msg,
          type: 'warning',
          duration: 4 * 1000,
          showClose: true
        })
      }
      if ((msg == null || msg == undefined) && error.response.config.url !== '/sf-partnerportal/pp/assets/downloadLicense') {
        // msg = i18n.t('other.105')
        Message({
          message: i18n.t('other.105'),
          type: 'warning',
          duration: 4 * 1000,
          showClose: true
        })
      }
      if (error.response.status === 500 && error.response.config.url === '/sf-partnerportal/pp/assets/downloadLicense') {
        let data = error.response.data
        let fileReader = new FileReader()
        fileReader.readAsText(data)
        fileReader.onload = function (result) {
          let jsondata = JSON.parse(result.target.result)
          Message({
            message: jsondata.msg,
            type: "error",
            duration: 4 * 1000,
          })
        }
        return
      }
      Message({
        message: msg,
        type: 'error',
        duration: 4 * 1000,
        showClose: true
      })
    }
    else {
      var msg = error.response.data.msg
      Message({
        message: msg,
        type: 'warning',
        duration: 4 * 1000,
        showClose: true
      })
    }

    // tryHideFullScreenLoading();
    return Promise.reject(error)
  }
)

export default service
