<template>
  <div style="height: 100%">
    <flexbox v-loading="loading"
             direction="column"
             align="stretch"
             class="d-container">
      <c-r-m-detail-head :title="detailData.packageName"
                         :crmType="crmType"
                         @hideView="hideView">
        <div slot="right"
             class="header">
          <!-- 设备归还 -->
          <el-button v-if="detailData.packageStatus == '1'"
                     type="primary"
                     size="medium"
                     icon="el-icon-extendwenjianxiazai"
                     @click="showReturnDialog = true">
            {{ $t("allEquipment.equipmentReturn") }}</el-button>
          <!-- 退押金 -->
          <el-button v-if="
              detailData.packageStatus == '3' && detailData.depositStatus == '0'
            "
                     type="primary"
                     size="medium"
                     icon="el-icon-extendfeiyongtuihuan"
                     @click="showRefundDialog = true">{{ $t("allEquipment.applyDepositRefund") }}</el-button>
        </div>
      </c-r-m-detail-head>
      <div id="follow-log-content"
           class="t-loading-content">
        <basic-info :detail="detailData"
                    :list="infoList"
                    itemWidth="25%"
                    labelWidth="180px">
        </basic-info>
        <!-- 产品信息 -->
        <create-sections :title="$t('detail.projectInfo')"
                         style="margin-top: 30px">
          <el-table :data="detailData.borrowRecordEntities"
                    stripe
                    border
                    highlight-current-row
                    class="productInfo"
                    style="width: 100%; margin-top: 5px">
            <el-table-column :label="$t('detail.productName')"
                             prop="itemName"
                             header-align="center"
                             min-width="120"></el-table-column>
            <el-table-column :label="$t('detail.productType')"
                             prop="productType"
                             :width="lang == 'CN' ? '80' : '100'"
                             align="center">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.productType == "0"
                      ? $t("allEquipment.product")
                      : scope.row.productType == "1"
                      ? $t("allEquipment.accessory")
                      : "--"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('detail.d21')"
                             :width="lang == 'CN' ? '60' : '80'"
                             prop="itemNum"
                             align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.itemNum || "--" }}</div>
              </template>
            </el-table-column>
            <!-- 押金 -->
            <el-table-column :label="$t('allEquipment.deposit')"
                             prop="itemDeposit"
                             width="100"
                             align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.itemDeposit || "--" }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('allEquipment.sn')"
                             prop="itemSn"
                             align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.itemSn || "--" }}</div>
              </template>
            </el-table-column>
            <!-- 设备/配件状态 -->
            <el-table-column :label="$t('allEquipment.EPstatus')"
                             prop="equipmentStatus"
                             width="100"
                             align="center">
              <template slot-scope="scope">
                <div>
                  {{
                    findDIctName("equipment_status", scope.row.equipmentStatus)
                  }}
                </div>
              </template>
            </el-table-column>
            <!-- 授权状态 -->
            <el-table-column :label="$t('allEquipment.authorizationStatus')"
                             prop="usedStatus"
                             :width="lang == 'CN' ? '100' : '150'"
                             align="center">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.usedStatus
                      ? scope.row.usedStatus == "0"
                        ? $t("allEquipment.unauthorizated")
                        : scope.row.usedStatus == "1"
                        ? $t("allEquipment.authorized")
                        : $t("allEquipment.authorizateInProgress")
                      : "--"
                  }}
                </div>
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column :label="$t('common.btn.handle')"
                             align="center"
                             :width="lang == 'CN' ? '100' : '140'"
                             v-if="detailData.packageStatus == '1'">
              <template slot-scope="scope">
                <div style="text-align: center">
                  <el-button v-if="
                      scope.row.productType == '0' ||
                      ['-6090001311002', '-6090001311001'].some((ele) =>
                        scope.row.uniqueId.includes(ele)
                      )
                    "
                             :disabled="scope.row.usedStatus == '2' || scope.row.equipmentStatus === '4' || scope.row.equipmentStatus === '8'"
                             type="success"
                             size="mini"
                             plain
                             @click="equipmentAuthorization(scope.row)">{{
                      scope.row.usedCount > 0
                        ? $t("allEquipment.reAuthorize")
                        : $t("allEquipment.authorize")
                    }}</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </create-sections>
      </div>
    </flexbox>
    <!-- 设备归还 -->
    <return-dialog v-if="showReturnDialog"
                   :visible.sync="showReturnDialog"
                   :detail="detailData"
                   @save-success="createEditSuccess"></return-dialog>
    <!-- 退押金 -->
    <refund-dialog v-if="showRefundDialog"
                   :visible.sync="showRefundDialog"
                   :detail="detailData"
                   @save-success="createEditSuccess"></refund-dialog>
    <c-r-m-create-view :visible.sync="isCreate"
                       :crmType="rowType"
                       :action="createActionInfo"
                       @save-success="createEditSuccess"
                       @hiden-view="isCreate = false"></c-r-m-create-view>
  </div>
</template>

<script>
import moment from 'moment'
import CRMDetailHead from '@c/CRMDetailHead'
import BasicInfo from '@c/BasicInfo'
import ReturnDialog from './components/returnDialog'
import RefundDialog from './components/refundDialog'
import { packageInfo } from '@/api/allEquipment'
import { findDIctName, findFullName, moneyFormat } from '@/utils'
export default {
  name: 'PackageDetail',
  components: {
    CRMDetailHead,
    BasicInfo,
    ReturnDialog,
    RefundDialog,
  },
  props: {
    // 详情信息id
    id: [String, Number],
  },
  data () {
    return {
      loading: false,
      crmType: 'packageDetail',
      detailData: {},
      infoList: [], // basicinfo的基本信息
      historyLoading: false,
      historyFieldList: [],
      historyList: [],
      showReturnDialog: false,
      isCreate: false,
      rowType: '',
      createActionInfo: {
        type: 'save',
      },
      lang: localStorage.getItem('lang'),
      showRefundDialog: false,
      actionType: 'save',
    }
  },
  computed: {
    findDIctName () {
      return findDIctName
    },
    refundDeposit () {
      return this.detailData.borrowRecordEntities.reduce((totle, item) => {
        return (totle += item.equipmentStatus == '4' ? item.itemDeposit : 0)
      }, 0)
    },
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    hideView () {
      this.$emit('hide-view')
    },
    infoFormat (list) {
      list.map((ele) => {
        ele.child.map((olo) => {
          olo.map((e) => {
            e.name = e.name || '--'
          })
        })
      })
    },
    getHistory () {
      this.historyFieldList = [
        {
          name: '借用开始日期',
          fieldName: 'startData',
        },
        {
          name: '借用截止日期',
          fieldName: 'endData',
        },
        {
          name: '申请编号',
          fieldName: 'applicationNo',
        },
        {
          name: '变更操作',
          fieldName: 'changeLog',
        },
        {
          name: '变更时间',
          fieldName: 'changeTime',
        },
      ]
      this.historyList = [
        {
          startData: '2021-03-21',
          endData: '2022-01-30',
          applicationNo: 'A0001',
          changeLog: 'Return',
          changeTime: '2021-06-01',
        },
        {
          startData: '2021-03-21',
          endData: '2022-01-30',
          applicationNo: 'A0001',
          changeLog: 'Extend Rental',
          changeTime: '2021-06-01',
        },
        {
          startData: '2021-03-21',
          endData: '2022-01-30',
          applicationNo: 'A0001',
          changeLog: 'Apply/Extend License',
          changeTime: '2021-06-01',
        },
      ]
    },
    getDetail () {
      this.loading = true
      new Promise((resolve, reject) => {
        packageInfo(this.id)
          .then((res) => {
            this.detailData = res.rows
            // this.detailData.packageStatus = '5'
            this.getBaiscInfo()
            this.loading = false
            resolve()
          })
          .catch(() => {
            this.loading = false
          })
      }).then(() => {
        // console.log(this.detailData);
      })
    },
    getBaiscInfo () {
      this.infoList = [
        {
          title: this.$t('detail.basicInfo'), // "基本信息",
          itemWidth: '50%',
          child: [
            [
              {
                label: this.$t('allEquipment.channel'), // 渠道商
                name: this.detailData.channelName,
              },
              {
                label: this.$t('approval.a4'), // 申请人
                name: this.detailData.applicantName,
              },
            ],
            [
              {
                label: this.$t('allEquipment.rentalPeriod'), // 借用期限
                name: this.detailData.expirationTime
                  ? moment(this.detailData.expirationTime).format('YYYY-MM-DD')
                  : '--',
              },
              {
                label: this.$t('allEquipment.packageStatus'), // 批次状态
                name: findDIctName(
                  'package_status',
                  this.detailData.packageStatus
                ),
              },
            ],
          ],
        },
        {
          title: this.$t('allEquipment.projectInformation'), // "项目信息",
          itemWidth: '50%',
          child: [
            [
              {
                label: this.$t('detail.projectName'), // 项目
                name: this.detailData.businessName,
              },
              {
                label: this.$t('detail.customerName'), // 客户
                name: this.detailData.customerName,
              },
            ],
            [
              {
                label: this.$t('allEquipment.projectStage'), // 项目所属阶段
                name: this.detailData.percent,
              },
              {
                label: this.$t('allEquipment.projectAmount'), // 项目金额
                name: this.detailData.money,
              },
            ],
          ],
        },
        {
          title: this.$t('allEquipment.financialInformation'), // "财务信息",
          itemWidth: '25%',
          child: [
            [
              {
                label: this.$t('allEquipment.totalDeposit'), // 总押金
                name:
                  this.detailData.depositStatus != "1" &&
                    this.detailData.totalDeposit
                    ? moneyFormat(
                      Number(this.detailData.totalDeposit).toFixed(2)
                    )
                    : "0",
              },
              {
                label: this.$t('allEquipment.requiredRefundAmount'), // 需退押金
                name:
                  this.detailData.depositStatus != '1' && this.refundDeposit
                    ? moneyFormat(Number(this.refundDeposit).toFixed(2))
                    : '0',
              },
              {
                label: this.$t('allEquipment.actualRefundAmount'), // 实际退还押金
                name:
                  this.detailData.depositStatus != "1" &&
                    this.detailData.realRefundDeposit
                    ? moneyFormat(
                      Number(this.detailData.realRefundDeposit).toFixed(2)
                    )
                    : "0",
              },
              {
                label: this.$t('allEquipment.depositStatus'), // 押金状态
                name: findDIctName(
                  'deposit_status',
                  this.detailData.depositStatus
                ),
              },
            ],
          ],
        },
        {
          title: this.$t('allEquipment.logisticsInformaion'), // "物流信息",
          itemWidth: '50%',
          child: [
            [
              {
                label: this.$t('allEquipment.courierCompany'), // "快递公司",
                name:
                  this.detailData.logisticsEntity &&
                  this.detailData.logisticsEntity.logisticsCompany,
              },
              {
                label: this.$t('allEquipment.shipmentNumber'), // "物流单号",
                name:
                  this.detailData.logisticsEntity &&
                  this.detailData.logisticsEntity.logisticsNum,
              },
            ],
          ],
        },
      ]
      this.infoFormat(this.infoList)
    },
    equipmentAuthorization (row) {
      // 设备授权按钮
      this.isCreate = true
      this.rowType = 'equipmentAuthorization'
      this.createActionInfo = {
        type: 'save',
        data: JSON.parse(JSON.stringify(this.detailData)),
      }
      this.createActionInfo.data.borrowRecordEntities = [row]
    },
    createEditSuccess (type) {
      this.getDetail();
      this.detailHeadHandle(type);
    },
    /** 顶部头 操作 */
    detailHeadHandle (data) {
      // if (data.saveType == "save") {
      //   this.hideView();
      // }
      this.$emit('handle', data)
    },
  },
}
</script>

<style lang="less" scoped>
.d-container {
  height: 100%;

  .header {
    display: flex;
    align-items: center;
  }

  .t-loading-content {
    flex: 1;
    overflow: auto;
  }
}

.el-button {
  padding: 10px 11px;
}
</style>