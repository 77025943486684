<template>
  <!-- 设备详情 -->
  <div class="deviceDetail">
    <flexbox
      v-loading="loading"
      direction="column"
      align="stretch"
      class="d-container"
    >
      <c-r-m-detail-head
        :title="detailData.itemName"
        :crmType="crmType"
        @hideView="hideView"
      >
        <!-- <div slot="right" class="header"></div> -->
      </c-r-m-detail-head>
      <div id="follow-log-content" class="t-loading-content">
        <basic-info
          :detail="detailData"
          :list="infoList"
          itemWidth="25%"
          labelWidth="160px"
        >
        </basic-info>
        <create-sections
          :title="$t('allEquipment.authorizationHistory')"
          style="margin-top: 30px"
        >
          <el-table
            :data="tableInfo"
            v-loading="tableLoading"
            stripe
            border
            highlight-current-row
            class="productInfo"
            style="width: 100%; margin: 5px 0 15px"
          >
            <el-table-column
              v-for="(item, index) in fieldList"
              :key="index"
              :prop="item.fieldName"
              :label="item.name"
              :width="item.width"
              :formatter="fieldFormatter"
              show-overflow-tooltip
            >
            </el-table-column>
            <!-- 授权文件 -->
            <el-table-column
              :label="$t('allEquipment.licenseFile')"
              align="center"
              width="90"
            >
              <template slot-scope="scope">
                <!-- 查看按钮 -->
                <el-button
                  type="primary"
                  size="mini"
                  @click="showLicense(scope.row.sysFileEntityList)"
                  >{{ $t("allEquipment.view") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="p-contianer">
            <el-pagination
              :current-page="pageNumber"
              :page-sizes="pageSizes"
              :page-size.sync="pageSize"
              :total="total"
              class="p-bar"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </create-sections>
      </div>
    </flexbox>
    <el-dialog
      :title="$t('allEquipment.licenseFile')"
      width="550px"
      :visible.sync="showLicenseDialog"
      class="license"
      append-to-body
      v-dialogDrag
    >
      <el-table :data="licenseData" stripe border highlight-current-row>
        <el-table-column
          prop="fileName"
          :label="$t('components.c2')"
        ></el-table-column>
        <el-table-column
          prop="createdByName"
          :label="$t('components.c3')"
        ></el-table-column>
        <el-table-column
          prop="createdDate"
          width="145px"
          :label="$t('components.c4')"
        >
          <template slot-scope="scope">{{
            scope.row.createdDate | dateformat
          }}</template>
        </el-table-column>
        <el-table-column width="85px" :label="$t('components.c5')">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.fileUrl"
              @click="down(scope.row)"
              size="small"
              >{{ $t("components.c6") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <return-dialog :visible.sync="showReturnDialog"></return-dialog>
    <c-r-m-create-view
      :visible.sync="isCreate"
      :crmType="rowType"
      :action="createActionInfo"
      @save-success="createEditSuccess"
      @hiden-view="isCreate = false"
    ></c-r-m-create-view>
  </div>
</template>

<script>
import moment from "moment";
import CRMDetailHead from "@c/CRMDetailHead";
import BasicInfo from "@c/BasicInfo";
import ReturnDialog from "./components/returnDialog";
import { getDeviceInfo, authlogsList } from "@/api/allEquipment";
import { findDIctName, findFullName, moneyFormat } from "@/utils";
export default {
  name: "DeviceDetail",
  components: {
    CRMDetailHead,
    BasicInfo,
    ReturnDialog,
  },
  props: {
    // 详情信息id
    id: [String, Number],
  },
  data() {
    return {
      loading: false,
      crmType: "deviceDetail",
      detailData: [],
      infoList: [], // basicinfo的基本信息
      tableLoading: false,
      tableInfo: [],
      pageNumber: 1,
      pageSizes: [10, 15, 30, 60, 100],
      pageSize: 10,
      total: 0,
      tableLoading: false,
      showReturnDialog: false,
      isCreate: false,
      rowType: "",
      createActionInfo: {
        type: "save",
      },
      lang: localStorage.getItem("lang"),
      licenseData: [],
      showLicenseDialog: false,
    };
  },
  computed: {
    findDIctName() {
      return findDIctName;
    },
    fieldList() {
      return [
        {
          name: this.$t("allEquipment.authorizationStartDate"),
          fieldName: "authStartDate",
          width: this.lang == "CN" ? "100" : "155",
        }, // 授权开始日期
        {
          name: this.$t("allEquipment.authorizationEndDate"),
          fieldName: "authEndDate",
          width: this.lang == "CN" ? "100" : "155",
        }, // 授权截止日期
        {
          name: this.$t("allEquipment.authorizationChannel"),
          fieldName: "channelName",
          // width: this.lang == "CN" ? "100" : "150",
        }, // 授权渠道
        {
          name: this.$t("detail.accountCustomer"),
          fieldName: "customerName",
          // width: this.lang == "CN" ? "100" : "150",
        }, // 关联客户
        {
          name: this.$t("detail.associatedProject"),
          fieldName: "businessName",
          // width: this.lang == "CN" ? "100" : "150",
        }, // 关联项目
        {
          name: this.$t("allEquipment.salesRep"),
          fieldName: "owner",
          width: this.lang == "CN" ? "100" : "90",
        }, // 关联负责人
        {
          name: this.$t("allEquipment.projectStatus"),
          fieldName: "percent",
          width: this.lang == "CN" ? "90" : "100",
        }, // 项目状态
        {
          name: this.$t("allEquipment.projectAmount"),
          fieldName: "money",
          width: this.lang == "CN" ? "90" : "105",
        }, // 项目金额
        {
          name: this.$t("allEquipment.noOfAuthorization"),
          fieldName: "authNum",
          width: this.lang == "CN" ? "90" : "130",
        }, // 授权次数
      ];
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    hideView() {
      this.$emit("hide-view");
    },
    handleClick(tab) {
      this.pageNumber = 1;
      this.getTableInfo();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableInfo();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getTableInfo();
    },
    fieldFormatter(row, column) {
      if (
        ["startDate", "endDate", "authStartDate", "authEndDate"].some(
          (item) => item == column.property
        )
      ) {
        return moment(row[column.property]).format("YYYY-MM-DD");
      } else {
        return row[column.property] || "--";
      }
    },
    getTableInfo() {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        borrowId: this.detailData.borrowId,
      };
      this.tableLoading = true;
      authlogsList(params)
        .then((res) => {
          this.tableInfo = res.rows.rows;
          this.total = res.rows.total;
          this.tableLoading = false;
        })
        .catch((err) => {
          window.console.log("err", err);
          this.tableLoading = false;
        });
    },
    showLicense(file) {
      this.licenseData = file || [];
      this.showLicenseDialog = true;
    },
    infoFormat(list) {
      list.map((ele) => {
        ele.child.map((olo) => {
          olo.map((e) => {
            e.name = e.name || "--";
          });
        });
      });
    },
    getDetail() {
      this.loading = true;
      new Promise((resolve, reject) => {
        getDeviceInfo({ id: this.id })
          .then((res) => {
            this.detailData = res.rows;
            this.getBaiscInfo();
            this.getTableInfo();
            this.loading = false;
            resolve();
          })
          .catch(() => {
            this.loading = false;
          });
      }).then(() => {
        // console.log(this.detailData);
      });
    },
    getBaiscInfo() {
      this.infoList = [
        {
          title: this.$t("detail.basicInfo"), // "基本信息",
          itemWidth: "25%",
          child: [
            [
              {
                label: this.$t("allEquipment.sn"), // SN码
                name: this.detailData.itemSn,
              },

              {
                label: this.$t("allEquipment.itemId"), // 网关ID
                name: this.detailData.itemId,
              },
              {
                label: "Package",
                name: this.detailData.packageName,
              },
              {
                label: this.$t("detail.d12"),
                name: findDIctName(
                  "equipment_status",
                  this.detailData.equipmentStatus
                ),
              },
            ],
            [
              {
                label: this.$t("allEquipment.authorizationEndDate"), // 授权截止日期
                name: this.detailData.endDate
                  ? moment(this.detailData.endDate).format("YYYY-MM-DD")
                  : "--",
              },
              // {
              //   label: "所需押金", // 所需押金
              //   name: "$" + this.detailData.itemDeposit,
              // },
            ],
          ],
        },
      ];
      this.infoFormat(this.infoList);
    },
    equipmentAuthorization(row) {
      // 设备授权按钮
      this.isCreate = true;
      this.rowType = "equipmentAuthorization";
      this.createActionInfo = {
        type: "save",
        data: JSON.parse(JSON.stringify(this.detailData)),
      };
      this.createActionInfo.data.borrowRecordEntities = [row];
    },
    createEditSuccess(type) {
      this.getDetail();
      this.detailHeadHandle(type);
    },
    /** 顶部头 操作 */
    detailHeadHandle(data) {
      // if (data.saveType == "save") {
      //   this.hideView();
      // }
      this.$emit("handle", data);
    },
    down(item) {
      var a = document.createElement("a");
      var filename = item.fileName;
      // a.href = "http://" + item.fileUrl;
      a.href = location.protocol + "//" + item.fileUrl;
      a.download = filename;
      a.click();
    },
  },
};
</script>

<style lang="less" scoped>
.deviceDetail {
  height: 100%;

  .d-container {
    height: 100%;

    .header {
      display: flex;
      align-items: center;
    }

    .t-loading-content {
      flex: 1;
      overflow: auto;
    }
  }
}

.el-button {
  padding: 10px 11px;
}
</style>