<template>
  <div class="crm-create-container">
    <el-table :data="AuthlogsInfoData" border stripe style="width: 100%">
      <el-table-column sortable label="Authorization Start Date">
        <template slot-scope="scope">
          <p>{{moment(scope.row.authStartDate).format('YYYY-MM-DD')}}</p>
        </template>
      </el-table-column>
      <el-table-column sortable label="Authorization End Date">
        <template slot-scope="scope">
          <p>{{ moment(scope.row.authEndDate).format('YYYY-MM-DD') }}</p>
        </template>
      </el-table-column>
      <el-table-column label="Partner">
        <template slot-scope="scope">
          <a style="color: #556ee6">{{ scope.row.channelName }}</a>
        </template>
      </el-table-column>
      <el-table-column prop="itemType" label="Product">
        <template slot-scope="scope">
          <span>{{ scope.row.itemName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="itemSn" label="SN Code">
        <template slot-scope="scope">
          <a style="color: #556ee6">{{ scope.row.itemSn }}</a>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getAuthlogsInfo } from "@/api/allProject";
import moment from "moment";
export default {
  name: "registrationHistory",
  props: {
    historyInfo: Object,
    info: {
      type: Object,
      default: () => {
        return {
          type: "save",
        };
      },
    },
  },
  data() {
    return {
      AuthlogsInfoData:[],
    };
  },
  methods: {
    AuthlogsInfo() {
      let params={
        id:this.info.businessId
      }
      getAuthlogsInfo(params).then((res) => {
        console.log(res);
        this.AuthlogsInfoData=res.rows.rows
      });
    },
  },
  created() {
    console.log(this.info);
    this.AuthlogsInfo();
  },
  computed:{
    moment(){
      return moment
    }
  }
};
</script>

<style lang="less" scoped>
.crm-create-container {
  padding: 20px 0;
}
</style>