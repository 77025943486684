import request from '@/utils/request'

export function login(userInfo) {
  return request({
    url: '/api-auth/oauth/user/pptoken',
    method: 'post',
    headers: {
      client_id: 'sangfor',
      client_secret: 'webApp',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    data: userInfo
  })
}
// export function logout(access_token) {
//   localStorage.removeItem('access_pp_token')
//   return request({
//     url: '/api-auth/oauth/remove/pptoken?token=' + access_token,
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
//     }
//   })
// }
// 注销idt
export function logout(idtToken, ppToken) {
  return request({
    url: "/api-auth/oauth/remove/pptoken",
    method: "post",
    params: { token: ppToken, ppToken: idtToken },
  });
}

export function getCountMyTask() {
  return request({
    url: '/sf-partnerportal/sys/task/countMyTask',
    method: 'get'
  })
}
