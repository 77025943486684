<template>
  <div class="bssicInformation">
    <create-sections title="Project Information">
      <flexbox justify="flex-start"
               align="flex-start"
               wrap="wrap">
        <p>
          <span>Company/Organization：</span><a>{{ info.company || "--" }}</a>
        </p>
        <!-- <p>
          <span>Stage:</span
          ><a>{{ findDIctName("PpProjectStage", info.stage) }}</a>
        </p> -->
        <p>
          <span>Customer Budget：</span><a> $ {{ info.customerBudget || "0" }}</a>
        </p>
        <p>
          <span>Expected PO Date：</span><a>{{ moment(info.expectedPoDate).format("YYYY-MM-DD") }}</a>
        </p>
        <p>
          <span>Project Name:</span><a>{{ info.projectName || "--" }}</a>
        </p>
        <p>
          <span>Project Expiry Date：</span><a>{{ moment(info.expectedPoDateEnd).format("YYYY-MM-DD") }}</a>
        </p>
      </flexbox>
    </create-sections>
    <create-sections title="Company/ Contact Detail Information">
      <flexbox justify="flex-start"
               align="flex-start"
               wrap="wrap">
        <p>
          <span>Industry:</span><a>{{
            findFullName(
              info.industry,
              this.$store.state.dict.dict.customer_business,
              "value",
              "list"
            )
          }}</a>
        </p>
        <p>
          <span>Address:</span><a>{{ info.address || "--" }}</a>
        </p>
        <p>
          <span>Country/Region:</span><a>{{ findDIctName("country", info.country) || "--" }}</a>
        </p>
        <p>
          <span>{{ $t("detail.scene") }}:</span><a>{{
            findFullName(
              info.requirement,
              this.$store.state.dict.dict.project_scene,
              "value",
              "list"
            ) || "--"
          }}</a>
        </p>
        <p>
          <span>Contact Person:</span><a>{{ info.contactPerson || "--" }}</a>
        </p>
        <p>
          <span>Position:</span><a>{{ info.position || "--" }}</a>
        </p>
        <p>
          <span>Tel/ Fax:</span><a>{{ info.tel || "--" }}</a>
        </p>
        <p>
          <span>Mobile:</span><a>{{ info.mobile || "--" }}</a>
        </p>
        <p>
          <span>Email:</span><a>{{ info.email || "--" }}</a>
        </p>
      </flexbox>
    </create-sections>
    <div style="padding: 0 10px 0 0">
      <el-table :data="info.productList"
                border
                stripe
                style="width: 100%">
        <el-table-column prop="categoryName"
                         label="Product Line">
        </el-table-column>
        <el-table-column prop="settings"
                         label="Special Requirement & Settings">
          <template slot-scope="scope">
            <div>{{ scope.row.settings || "--" }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="productNum" label="Quantity"> </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import CreateSections from "@c/CreateSections";
import moment from "moment";
import { findDIctName, moneyFormat, findFullName } from "@/utils";
export default {
  name: "bssicInformation",
  components: {
    CreateSections,
  },
  props: {
    info: Object,
  },
  data () {
    return {};
  },
  methods: {},
  computed: {
    findFullName () {
      return findFullName;
    },
    findDIctName () {
      return findDIctName;
    },
    moment () {
      return moment;
    },
  },
};
</script>

<style lang="less" scoped>
.bssicInformation {
  padding: 20px 0;

  p {
    padding: 13px 0;
    display: flex;
    align-items: flex-start;
    width: 50%;

    & > span {
      margin-left: 40px;
      display: block;
      width: 200px;
      font-size: 14px;
      color: #999a9e;
      word-wrap: break-word;
    }

    & > a {
      padding: 0 26px;
      display: block;
      width: calc(100% - 182px);
      font-size: 14px;
      color: #343a40;
      cursor: text;
      word-wrap: break-word;
    }
  }
}
</style>