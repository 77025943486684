import store from '@/store'
export function hasPermission (perms) {
  return store.state.user.permission.includes(perms)
}
/** 获取最大 z-index 的值 */
import { PopupManager } from 'element-ui/lib/utils/popup'
export function getMaxIndex () {
  return PopupManager.nextZIndex()
}
/** 深拷贝 */
export function objDeepCopy (source) {
  if (typeof source === 'object') {
    var sourceCopy = source instanceof Array ? [] : {}
    for (var item in source) {
      if (!source[item]) {
        sourceCopy[item] = source[item]
      } else {
        sourceCopy[item] =
          typeof source[item] === 'object'
            ? objDeepCopy(source[item])
            : source[item]
      }
    }
    return sourceCopy
  }
  return source
}

// 最后一级id 获取父级id
export function getFathersById (id, data, prop = 'deptId', list = 'list') {

  var arrRes = []
  const rev = (data, nodeId) => {
    for (var i = 0, length = data.length; i < length; i++) {
      const node = data[i]
      if (node[prop] === nodeId) {
        arrRes.unshift(node[prop])
        return true
      } else {
        if (node[list] && node[list].length) {
          if (rev(node[list], nodeId)) {
            arrRes.unshift(node[prop])
            return true
          }
        }
      }
    }
    return false
  }
  rev(data, id)
  return arrRes
}
// 根据最后一级id找全称   isNoGroup   项目报备时，部门展示到办事处，不展示组
export function findFullName (id, options, prop, list, isDept, isNoGroup) {
  let fullPath = getFathersById(id, options, prop, list)
  let fullName = findName(options, fullPath, prop, list, isDept, isNoGroup)
  return fullName
}
/*
 根据对应id找name  isDept只取最后一级
*/
export function findName (
  options,
  arr,
  prop = 'deptId',
  list = 'list',
  isDept, isNoGroup
) {
  if (isNoGroup && arr.length === 3) {
    arr.pop()
  }
  let officeName = ''
  const rev = (options, arr, index = 0) => {
    options.forEach(c => {
      if (isDept) {
        if (c[prop] === arr[index] && index == arr.length - 1) {
          officeName += c.name + (index != arr.length - 1 ? '/' : '')
        }
      } else {
        if (c[prop] === arr[index]) {
          officeName += c.name + (index != arr.length - 1 ? '/' : '')
        }
      }

      if (Array.isArray(c[list])) {
        rev(c[list], arr, index + 1)
      }
    })
  }
  rev(options, arr, 0)
  return officeName
}
export function findDIctName (key, val) {
  if (store.state.dict.dict[key] && store.state.dict.dict[key].length > 0) {
    return store.state.dict.dict[key].reduce((str, ele) => {
      if (ele.value == val) {
        str = ele.name
      }
      return str
    }, '--')
  } else {
    // 1秒判断一次，直到有了该字典，先不用
    // let initIndex = window.setInterval(function () {
    //   if (store.state.dict.dict[key] && store.state.dict.dict[key].length > 0) {
    //     window.clearInterval(initIndex)
    //     return store.state.dict.dict[key].reduce((str, ele) => {
    //       if (ele.value == val) {
    //         str = ele.name
    //       }
    //       return str;
    //     }, "--");
    //   }
    // },1000)
    return '-'
  }
}
/**
 * 金额格式化 增加千分符
 * @param {*} val
 */
export function moneyFormat (val) {
  if (!val && val != 0) return '--'
  const i = val > 0 ? Math.floor(val) : Math.ceil(val)
  val += ''
  const d = val.split('.')[1] || ''
  return i.toLocaleString('en-US') + (d ? '.' + d : '')
}
/** 获取file大小的名称 */
export function fileSize (size) {
  var size_int = size
  if (typeof size === 'string' && size.constructor == String) {
    size_int = parseInt(size)
  }
  var formatSize
  if (parseInt(size_int / 1024 / 1024) > 0) {
    formatSize = (size_int / 1024 / 1024).toFixed(2) + 'MB'
  } else if (parseInt(size_int / 1024) > 0) {
    formatSize = (size_int / 1024).toFixed(2) + 'kB'
  } else {
    formatSize = size_int + 'Byte'
  }
  return formatSize
}
