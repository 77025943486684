<template>
  <create-view v-if="visible">
    <component
      :is="tabName"
      v-bind="$attrs"
      v-on="$listeners"
      :crmType="crmType"
      class="d-view"
      @hide-view="hiddenView"
      @back-view="backView"
    />
  </create-view>
</template>

<script>
import CreateView from '@c/CreateView'
import borrowEquipmentApply from '@v/project/allEquipment/borrowEquipmentApply'
import equipmentAuthorizationApply from '@v/project/allEquipment/equipmentAuthorizationApply'
import projectSave from '@v/project/allProject/projectSave'
export default {
  name: 'CRMCreateView', // 详情
  components: {
    CreateView,
    borrowEquipmentApply,
    equipmentAuthorizationApply,
    projectSave
  },
  props: {
    /** 没有值就是全部类型 有值就是当个类型 */
    crmType: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabName: '' // 组件名
    }
  },
  watch: {
    crmType: {
      handler() {
        if (this.crmType == 'borrowEquipmentApply') {
          this.tabName = 'borrowEquipment-apply'
        } else if (this.crmType == 'equipmentAuthorization') {
          this.tabName = 'equipment-authorization-apply'
        } else if (this.crmType == 'ppProject') {
          this.tabName = 'project-save'
        } else {
          this.tabName = this.crmType
        }
      },
      immediate: true
    }
  },
  methods: {
    hiddenView() {
      this.$emit('update:visible', false)
    },
    backView() {
      this.$emit('updateBackView')
    },
    /**
     * 新建编辑操作
     */
    createlHandle(data) {
      this.$emit('handle', data)
    }
  }
}
</script>

<style></style>
