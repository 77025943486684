<template>
  <create-view v-if="visible && tabName">
    <component
      :is="tabName"
      v-bind="$attrs"
      v-on="$listeners"
      class="d-view"
      @hide-view="hiddenView"
      @register="register"
      @edit="edit"
    />
  </create-view>
</template>

<script>
import { getMaxIndex } from '@/utils/index'
import CreateView from '@c/CreateView'
import PackageDetail from '@v/project/allEquipment/packageDetail'
import DeviceDetail from '@v/project/allEquipment/deviceDetail'
import ProjectDetail from '@v/project/allProject/projectDetails'
import borrowEquipmentDetail from '@v/project/allEquipment/borrowEquipmentDetail'
import equipmentAuthorizationDetail from '@v/project/allEquipment/equipmentAuthorizationDetail'
import equipmentReturnDetail from '@v/project/allEquipment/equipmentReturnDetail'
import equipmentRefundDetail from '@v/project/allEquipment/equipmentRefundDetail'
import projectSaveDetails from '@v/project/allProject/projectSaveDetails'
import myApprovalDetail from '@v/approval/components/myApprovalDetail.vue'
export default {
  name: 'CRMAllDetail', // 详情
  components: {
    CreateView,
    PackageDetail,
    DeviceDetail,
    ProjectDetail,
    borrowEquipmentDetail,
    equipmentAuthorizationDetail,
    equipmentReturnDetail,
    equipmentRefundDetail,
    projectSaveDetails,
    myApprovalDetail
  },
  props: {
    /** 没有值就是全部类型 有值就是当个类型 */
    crmType: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabName: '' // 组件名
    }
  },
  watch: {
    crmType: {
      handler() {
        if (this.crmType == 'packageDetail') {
          this.tabName = 'package-detail'
        } else if (this.crmType == 'deviceDetail') {
          this.tabName = 'device-detail'
        } else if (this.crmType == 'ppProject') {
          this.tabName = 'project-detail'
        } else if (this.crmType == 'borrowEquipment') {
          this.tabName = 'borrow-equipment-detail'
        } else if (this.crmType == 'equipmentAuthorization') {
          this.tabName = 'equipment-authorization-detail'
        } else if (this.crmType == 'equipmentReturn') {
          this.tabName = 'equipment-return-detail'
        } else if (this.crmType == 'equipmentRefund') {
          this.tabName = 'equipment-refund-detail'
        } else if (this.crmType == 'ProjectSaveDetil') {
          this.tabName = 'project-save-details'
        } else {
          this.tabName = this.crmType
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    hiddenView() {
      this.$emit('update:visible', false)
    },
    register(info) {
      this.$emit('indexRegister', info)
    },
    edit() {
      this.$emit('editProject')
    },
    /**
     * 详情操作
     */
    detailHandle(data) {
      this.$emit('handle', data)
    }
  }
}
</script>

<style lang="less" scoped>
.d-view {
  height: 100%;
}
/deep/ .el-card__body {
  padding: 10px 20px;
}
</style>
