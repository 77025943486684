<template>
  <flexbox direction="column"
           align="stretch"
           class="crm-create-container">
    <flexbox class="crm-create-header">
      <!-- 设备授权申请 -->
      <div style="
          flex: 1;
          font-size: 17px;
          font-family: Alibaba PuHuiTi;
          font-weight: 600;
          line-height: 22px;
          color: #343a40;
          opacity: 1;
        ">
        Project Registration
      </div>
      <img class="close"
           src="@/assets/img/task_close.png"
           @click="hidenView" />
    </flexbox>
    <div class="crm-create-flex">
      <create-sections title="Channel Information">
        <div>
          <el-form :model="ruleForm"
                   :rules="rules"
                   ref="channelRuleForm"
                   label-width="210px"
                   style="width: 100%">
            <flexbox wrap="wrap">
              <el-form-item :label="$t('allEquipment.partner') + ':'">
                <div>
                  <!-- {{
                    this.action.type == "update"
                      ? userInfo.channel || "--"
                      : userInfo.channelName || "--"
                  }} -->
                  {{ $store.state.user.userInfo.channelName }}
                </div>
              </el-form-item>
              <el-form-item :label="$t('allEquipment.applicant') + ':'">
                <div>
                  {{
                    this.action.type == "update"
                      ? userInfo.applicant || "--"
                      : userInfo.name
                      ? (userInfo.surname || "") + " " + userInfo.name
                      : "--"
                  }}
                </div>
              </el-form-item>
              <el-form-item :label="$t('allEquipment.address') + ':'">
                <div>
                  {{ findDIctName("country", ChannleCountryData.country) }}
                </div>
              </el-form-item>
              <el-form-item :label="$t('allEquipment.mobile') + ':'">
                <div>{{ ChannleCountryData.mobile || "--" }}</div>
              </el-form-item>
              <el-form-item :label="$t('allEquipment.email') + ':'">
                <div>{{ $store.state.user.userInfo.email || "--" }}</div>
              </el-form-item>
              <el-form-item :label="$t('allEquipment.sangforOffice') + ':'">
                <div v-if="ChannleCountryData && ChannleCountryData.dept_id">{{ action.data && action.data.flowName == 'Renew Project Registration' ?commonFindFullName(productLineData.deptId,
              findFullNameList,
                'deptId',
                'list',
                true,true) : commonFindFullName(ChannleCountryData.dept_id,findFullNameList,
                'deptId',
                'list',
                true,true) }}
                </div>
              </el-form-item>
            </flexbox>
            <flexbox wrap="wrap">
              <el-form-item v-if="
                  $store.state.user.userInfo.channelClass == '0' ||
                  $store.state.user.userInfo.channelClass == '1'
                "
                            :label="$t('allEquipment.reseller') + ':'"
                            prop="channel">
                <el-input v-model="ruleForm.channel"
                          placeholder="Please input reseller"
                          size="small"
                          :maxlength="200"
                          style="width: 100%"></el-input>
              </el-form-item>
              <el-form-item v-else
                            :label="$t('allEquipment.distribute') + ':'"
                            prop="distributor">
                <el-select v-model="ruleForm.distributor"
                           size="small"
                           placeholder="Please enter a keyword"
                           :remote-method="remoteMethod"
                           :loading="loading"
                           :filterable="true"
                           :remote="true"
                           style="width: 100%">
                  <el-option v-for="(item, index) in distributeList"
                             :key="index"
                             :label="item.channelName"
                             :value="item.channelId"></el-option>
                </el-select>
              </el-form-item>
              <!-- 总代邮箱或下游渠道邮箱 -->
              <el-form-item :label=" $store.state.user.userInfo.channelClass == '0' ||
                  $store.state.user.userInfo.channelClass == '1' ? $t('allEquipment.resellerEmail') + ':' : $t('allEquipment.distributeEmail') + ':' "
                            prop="channelEmail">
                <el-input v-model="ruleForm.channelEmail"
                          size="small"
                          :maxlength="200"
                          placeholder="Please input email"
                          style="width: 100%"></el-input>
              </el-form-item>

              <el-form-item :label="$t('allEquipment.channelProjectOwner') + ':'"
                            prop="channelProjectBy">
                <el-input v-model.trim="ruleForm.channelProjectBy"
                          size="small"
                          :maxlength="200"
                          placeholder="Please input project owner name"
                          style="width: 100%"></el-input>
              </el-form-item>
              <el-form-item :label="$t('allEquipment.channelProjectOwnerEmail') + ':'"
                            prop="channelProjectEmail">
                <el-input v-model="ruleForm.channelProjectEmail"
                          size="small"
                          :maxlength="200"
                          placeholder="Please input channel project owner email"
                          style="width: 100%"></el-input>
              </el-form-item>
            </flexbox>
          </el-form>
        </div>
      </create-sections>
      <create-sections title="Project Information">
        <div>
          <el-form :model="ruleForm"
                   :rules="rules"
                   label-width="210px"
                   ref="ruleForm">
            <flexbox wrap="wrap">
              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.customer') + ':'"
                            prop="company">
                <el-select v-model="ruleForm.company"
                           remote
                           filterable
                           style="width: 100%"
                           size="small"
                           :remote-method="getByProjectNameData"
                           @change="selectCompany"
                           placeholder="Please input the company name">
                  <el-option v-for="(item, index) in companys"
                             :key="index"
                             :label="item.customerName"
                             :value="item.customerId">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item class="project-form-item"
                            label="Stage:"
                            prop="stage">
                <el-select v-model="ruleForm.stage"
                           size="small"
                           placeholder="Please select stage"
                           style="width: 100%">
                  <el-option v-for="(item, index) in projectStatus"
                             :key="index"
                             :label="item.label"
                             :value="item.value"
                             :disabled="stageDisabled(item)"></el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.customerBudget') + ':'"
                            prop="customerBudget"
                            v-if="!(action.type == 'eaupdate' ||
                    (action.type == 'update' &&
                      action.data.flowName == 'Renew Project Registration'))
                  ">
                <el-input v-model.trim="ruleForm.customerBudget"
                          size="small"
                          :maxlength="20"
                          type="number"
                          class="customer-budget-input"
                          :disabled="
                    action.type == 'eaupdate' ||
                    (action.type == 'update' &&
                      action.data.flowName == 'Renew Project Registration')
                  "
                          placeholder="Please input the customer budget">
                </el-input>
              </el-form-item>
              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.expectedDate') + ':'"
                            prop="expectedPoDate">
                <el-date-picker type="date"
                                size="small"
                                v-model="ruleForm.expectedPoDate"
                                placeholder="Please select the Expected PO Date"
                                style="width: 100%">
                </el-date-picker>
              </el-form-item>
              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.industry') + ':'"
                            prop="industry">
                <!-- <el-input
                v-model="ruleForm.industry"
                placeholder="Please input the company Industry"
                >
                </el-input> -->
                <XhProuctCate :value="ruleForm.industry"
                              :crmType="crmType"
                              dictName="customer_business"
                              placeholder="Please select industry"
                              @value-change="AAAAchange">
                </XhProuctCate>
              </el-form-item>
              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.address') + ':'"
                            prop="address">
                <el-input v-model.trim="ruleForm.address"
                          size="small"
                          placeholder="Please input the company address"
                          style="width: 100%">
                </el-input>
              </el-form-item>
              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.countryRegion') + ':'"
                            prop="country">
                <el-select v-model="ruleForm.country"
                           filterable
                           size="small"
                           style="width: 100%"
                           placeholder="Please select the country">
                  <el-option v-for="(item, index) in $store.state.dict.dict.country"
                             :key="index"
                             :label="item.name"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="project-form-item"
                            :label="$t('detail.scene') + ':'"
                            prop="businessScene">
                <!-- <XhProuctCate :value="ruleForm.businessScene"
                              :crmType="crmType"
                              dictName="project_scene"
                              placeholder="Please select requirement and scenario"
                              @value-change="requirementChange">
                </XhProuctCate> -->
                <el-select v-model="ruleForm.businessScene"
                           size="small"
                           style="width: 100%"
                           placeholder="Please select scene">
                  <el-option v-for="(item, index) in $store.state.dict.dict.project_scene"
                             :key="index"
                             :disabled="item.value&&getOptionDisable(item)"
                             :label="item.name"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.contactPerson') + ':'"
                            prop="contactPerson">
                <el-input v-model.trim="ruleForm.contactPerson"
                          placeholder="Please input the contact person name"
                          size="small"
                          :maxlength="200"
                          style="width: 100%">
                </el-input>
              </el-form-item>
              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.position') + ':'"
                            prop="position">
                <el-input v-model.trim="ruleForm.position"
                          :maxlength="100"
                          placeholder="Please input the contact person position"
                          size="small"></el-input>
              </el-form-item>
              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.email') + ':'"
                            prop="email">
                <el-input v-model="ruleForm.email"
                          :maxlength="200"
                          placeholder="Please input  contact person email"
                          size="small">
                </el-input>
              </el-form-item>

              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.isRenew') + ':'"
                            prop="isRenew">
                <el-select v-model="ruleForm.isRenew"
                           size="small"
                           style="width: 100%"
                           placeholder="Please select isRenew">
                  <el-option v-for="(item, index) in $store.state.dict.dict.commonType"
                             :key="index"
                             :disabled="item.value&&getOptionDisable(item)"
                             :label="item.name"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.telFax') + ':'"
                            prop="tel">
                <el-input v-model.trim="ruleForm.tel"
                          :maxlength="15"
                          placeholder="Please input the telephone or fax"
                          size="small">
                </el-input>
              </el-form-item>
              <el-form-item class="project-form-item"
                            :label="$t('allEquipment.mobile') + ':'"
                            prop="mobile">
                <el-input v-model.trim="ruleForm.mobile"
                          :maxlength="15"
                          placeholder="Please input contact person mobile"
                          size="small">
                </el-input>
              </el-form-item>
            </flexbox>
          </el-form>
        </div>
      </create-sections>

      <div style="padding: 0 20px">
        <el-table :data="tableData"
                  stripe
                  border
                  style="margin-top: 20px; width: 100%">
          <el-table-column prop="productLine"
                           :label="$t('allEquipment.productLine')">
            <template slot="header"
                      slot-scope="scope">
              <span style="color: red; margin-right: 4px">*</span><span>{{$t('allEquipment.productLine')}}</span>
            </template>
            <template slot-scope="scope">
              <el-cascader v-if="
                  productLineData.listStatus &&
                  productLineData.listStatus == '0' &&
                  action.type != 'eaupdate' && productLineData.flowName !== 'Renew Project Registration'
                "
                           :options="products"
                           :ref="'cascader' + scope.$index"
                           @change="(value) => handleProductChange(scope.$index, value)"
                           placeholder=" "
                           :show-all-levels="false"
                           size="small"
                           style="width: 100%"
                           :props="{ value:'id', label: 'name' }"
                           v-model="scope.row.productVlaue">
              </el-cascader>
              <el-cascader disabled
                           v-else-if="
                  productLineData.listStatus &&
                  productLineData.listStatus == '1' &&
                  action.type != 'eaupdate' && productLineData.flowName !== 'Renew Project Registration'
                "
                           :options="products"
                           :ref="'cascader' + scope.$index"
                           @change="(value) => handleProductChange(scope.$index, value)"
                           placeholder=" "
                           :show-all-levels="false"
                           size="small"
                           style="width: 100%"
                           :props="{ value: 'id', label: 'name' }"
                           v-model="scope.row.categoryName">
              </el-cascader>
              <el-cascader v-else-if="!businessId && action.type != 'eaupdate' && productLineData.flowName !== 'Renew Project Registration'"
                           :options="products"
                           :ref="'cascader' + scope.$index"
                           @change="(value) => handleProductChange(scope.$index, value)"
                           placeholder=" "
                           :show-all-levels="false"
                           size="small"
                           style="width: 100%"
                           :props="{ value: 'id', label: 'name' }"
                           v-model="scope.row.categoryName">
              </el-cascader>
              <div v-else>
                {{ scope.row.productLine || scope.row.categoryName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="settings"
                           :label="$t('allEquipment.specialSetting')">
            <template slot-scope="scope">
              <el-input size="small"
                        :maxlength="200"
                        :disabled="
                  productLineData.listStatus &&
                  productLineData.listStatus == '1' && productLineData.flowName !== 'Renew Project Registration'
                "
                        v-if="
                  (!businessId ||
                  action.type == 'update' ||
                  action.type == 'eaupdate') && productLineData.flowName !== 'Renew Project Registration' 
                "
                        v-model="scope.row.settings"></el-input>
              <p v-else>{{ scope.row.settings || "" }}</p>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="quantity" label="Quantity">
            <template slot="header" slot-scope="scope">
              <span style="color: red; margin-right: 4px">*</span
              ><span>Quantity</span>
            </template>
            <template slot-scope="scope">
              <el-input
                size="small"
                v-if="
                  productLineData.listStatus &&
                  productLineData.listStatus == '0'
                "
                v-model="scope.row.quantity"
                disabled
              ></el-input>
              <el-input
                size="small"
                v-if="
                  productLineData.listStatus &&
                  productLineData.listStatus == '1'
                "
                v-model="scope.row.productNum"
                disabled
              ></el-input>
              <el-input
                size="small"
                v-if="!businessId"
                v-model="scope.row.quantity"
                disabled
              ></el-input>
            </template>
          </el-table-column> -->
          <el-table-column prop="action"
                           :label="$t('allEquipment.action')"
                           v-if="!businessId || action.type == 'update' && productLineData.flowName !== 'Renew Project Registration'">
            <template slot="header"
                      slot-scope="scope">
              <span style="color: red; margin-right: 4px">*</span><span>{{$t('allEquipment.action')}}</span>
            </template>
            <template slot-scope="scope">
              <el-button class="handle-button"
                         size="small"
                         style="background-color: #f46a6a; color: #fff"
                         @click="cancelLine(scope.$index)">
                Delete
              </el-button>
              <!-- <el-button class="handle-button" size="small" v-if="!scope.row.action"
                style="background-color: #556ee6; color:#fff;" @click="comfireLine(scope.$index)">
                确认
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="add-line"
             @click="addLine"
             v-if="!businessId || !(action.type == 'eaupdate' ||
                    (action.type == 'update' &&
                      action.data.flowName == 'Renew Project Registration'))
                  ">
          <a><i class="el-icon-plus"></i></a> Add Product Line
        </div>
      </div>
    </div>
    <div class="handle-bar">
      <el-button class="handle-button"
                 size="medium"
                 :loading="confirmLoading"
                 style="background-color: #556ee6; margin-left: 20px; color: #fff"
                 @click.native="submitForm('ruleForm', 1)">
        Save
      </el-button>
      <el-button v-if="action.type != 'update' && action.type != 'eaupdate'"
                 class="handle-button"
                 size="medium"
                 :loading="confirmLoading"
                 @click.native="submitForm('ruleForm', 0)">
        Save Draft
      </el-button>
      <el-button class="handle-button"
                 size="medium"
                 @click.native="hidenView">
        Cancel
      </el-button>
    </div>
  </flexbox>
</template>

<script>
import CreateView from '@c/CreateView'
import { XhProuctCate } from '@c/CreateCom'
import { getProductMultiStruction, getProductInit } from '@/api/allEquipment'
import {
  getProjectInfo,
  getProjectdetail,
  getEpaProjectdetail,
  updateProject,
  ppupdateProject,
  getByProjectName,
  newProject,
  getCustomerInfo,
  getCategoryList,
  getNewCategoryList,
  getByChannle,
  getDistribute,
  getByChannleCountry,
} from '@/api/allProject'
import { getFathersById, findDIctName, findFullName } from '@/utils/index'

export default {
  name: 'projectSave',
  components: {
    CreateView,
    XhProuctCate,
  },
  props: {
    crmType: {
      type: String,
      default: '',
    },
    openSave: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Object,
      default: () => {
        return {
          type: 'save',
        }
      },
    },
  },

  data() {
    // 自定义验证
    // var checkhannelProjectEmail = (rule, value, callback) => {
    //   const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
    //   if (!value) {
    //     return callback(
    //       new Error('Please input  contact channel Project Email')
    //     )
    //   }
    //   setTimeout(() => {
    //     if (mailReg.test(value)) {
    //       callback()
    //     } else {
    //       callback(new Error('Please enter the correct email format'))
    //     }
    //   }, 100)
    // }
    // var checkPersonEmail = (rule, value, callback) => {
    //   const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
    //   if (!value) {
    //     return callback(new Error('Please input  contact person email'))
    //   }
    //   setTimeout(() => {
    //     if (mailReg.test(value)) {
    //       callback()
    //     } else {
    //       callback(new Error('Please enter the correct email format'))
    //     }
    //   }, 100)
    // }
    var checkTel = (rule, value, callback) => {
      if (!value) {
        return callback()
      }
      setTimeout(() => {
        if (value.length > 15) {
          callback(new Error('The telephone cannot exceed 15 digits'))
        } else {
          callback()
        }
      }, 100)
    }
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback()
      }
      setTimeout(() => {
        if (value.length > 20) {
          callback(new Error('The mobile cannot exceed 20 digits'))
        } else {
          callback()
        }
      }, 100)
    }
    var checkCompany = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Please input the company name'))
      }
      setTimeout(() => {
        if (value.length > 200) {
          callback(new Error('The company name cannot exceed 200 digits'))
        } else {
          callback()
        }
      }, 100)
    }

    const customerBudgetRule = (rule, value, callback) => {
      if (!value || Number(value) <= 0) {
        return callback(new Error('Please input the customer budget'))
      } else {
        callback()
      }
    }
    return {
      lacalLanguage: localStorage.getItem('lang'),
      ruleForm: {
        channelProjectBy: '',
        channelProjectEmail: '',
        distributor: '',
        channel: '',
        company: '',
        industry: '',
        address: '',
        country: '',
        contactPerson: '',
        position: '',
        tel: '',
        mobile: '',
        email: '',
        // projectName: '',
        customerBudget: '',
        expectedPoDate: '',
        stage: '0',
        applicantAddress: '',
        applicantMobile: '',
        saveType: '',
        businessScene: '',
        channelEmail: '',
        isRenew: '0',
      },
      rules: {
        company: [
          {
            required: true,
            validator: checkCompany,
            trigger: 'change',
          },
        ],
        channel: [
          {
            required: false,
            message: 'Please input the company name',
            trigger: 'change',
          },
        ],
        distributor: [
          {
            required: false,
            message: 'Please input the company name',
            trigger: 'change',
          },
        ],
        industry: [
          {
            required: true,
            message: 'Please input the company Industry',
            trigger: 'change',
          },
        ],
        address: [
          {
            required: true,
            message: 'Please input the company address',
            trigger: 'change',
          },
        ],
        country: [
          {
            required: true,
            message: 'Please input the country',
            trigger: 'change',
          },
        ],
        businessScene: [
          {
            required: true,
            message: 'Please select the requirement',
            trigger: 'change',
          },
        ],
        contactPerson: [
          {
            required: true,
            message: 'Please input the contact person name',
            trigger: 'change',
          },
        ],
        position: [
          {
            required: true,
            message: 'Please input the contact person position',
            trigger: 'change',
          },
        ],
        tel: [
          {
            required: false,
            validator: checkTel,
            trigger: 'change',
          },
        ],
        mobile: [
          {
            required: false,
            validator: checkMobile,
            trigger: 'change',
          },
        ],
        email: [
          // { required: true, validator: checkPersonEmail, trigger: 'blur' },
          {
            required: true,
            message: 'Please input  contact person email',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please enter the correct email format',
            trigger: ['blur', 'change'],
          },
          // {
          //   required: true,
          //   message: "Please input  contact person email",
          //   trigger: "change",
          // },
        ],
        channelEmail: [
          {
            required: true,
            message: 'Please input  channelEmail',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please enter the correct email format',
            trigger: ['blur', 'change'],
          },
        ],
        channelProjectEmail: [
          // {
          //   required: true,
          //   validator: checkhannelProjectEmail,
          //   trigger: 'blur',
          // },
          {
            required: true,
            message: 'Please input  contact channel Project Email',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please enter the correct email format',
            trigger: ['blur', 'change'],
          },
        ],
        projectName: [
          {
            required: true,
            message: 'Please input the project name',
            trigger: 'change',
          },
        ],
        channelProjectBy: [
          {
            required: true,
            message: 'Please input the channel project name',
            trigger: 'change',
          },
        ],
        customerBudget: [
          {
            required: true,
            // message: 'Please input the customer budget',
            validator: customerBudgetRule,
            trigger: ['blur', 'change'],
          },
        ],
        expectedPoDate: [
          {
            required: true,
            message: 'Please input the Expected PO Date',
            trigger: 'change',
          },
        ],
        stage: [
          {
            required: true,
            message: 'POC Confimred',
            trigger: 'change',
          },
        ],
        isRenew: [
          {
            required: true,
            message: 'Please select the isRenew',
            trigger: 'change',
          },
        ],
      },
      tableData: [],
      productMultiStruction: [],
      productLineData: {},
      loading: false,
      channel: {},
      channelList: [],
      distributeList: [],
      ChannleCountryData: {},
      businessId: null,
      userInfo: {},
      confirmLoading: false,
      customerId: null,
      companys: [],
      customerInfo: {},
      products: [],
      projectStatus: [
        { label: '20%:BOM Submitted', value: '0' },
        { label: '30%:Customer Visited', value: '1' },
        { label: '40%:POC Arranged', value: '3' },
        { label: '50%:POC Conducted', value: '4' },
        { label: '60%:POC Passed', value: '5' },
        { label: '70%:Quotation Requested', value: '6' },
        { label: '90%:PO Received', value: '7' },
      ],
    }
  },
  computed: {
    // stageDisabled() {
    //   if (this.action.type == "save") {
    //     return false;
    //   } else {
    //     // return true;
    //     if (['0','1'].some(item=>this.ruleForm.stage==item)) {

    //     }
    //   }
    // },
    findDIctName() {
      return findDIctName
    },

    commonFindFullName() {
      return findFullName
    },

    findFullNameList() {
      return this.$store.state.dict.dict.deptOptions
    },
  },

  mounted() {
    getProductMultiStruction().then((res) => {
      this.productMultiStruction = res.rows
    })
    console.log(546465, this.action)
    console.log('userInfo', this.$store.state.user.userInfo)
    if (this.action.type == 'update') {
      if (this.action.data.projectFiledId) {
        let userInfo = this.action.data
        let businessId = this.action.data.projectFiledId
          ? this.action.data.projectFiledId
          : ''
        this.userInfo = userInfo
        if (businessId) {
          this.businessId = businessId
          // this.getDetails(businessId);
        }
      } else {
        let userInfo = this.action.data
        let businessId = this.action.data.businessId
        this.userInfo = userInfo
        if (businessId) {
          this.businessId = businessId
          // this.getDetails(businessId);
        }
      }
    } else {
      let userInfo = this.$store.state.user.userInfo
      let businessId = this.$store.state.businessId
      this.userInfo = userInfo
      if (businessId) {
        this.businessId = businessId
        // this.getDetails(this.businessId);
      }
    }
    if (this.action.type == 'save') {
      this.tableData = [
        {
          productVlaue: [],
          productLine: '',
          settings: '',
          quantity: '1',
          action: false,
          businessProductLine: '',
        },
      ]
    }
    console.log(this.businessId)
    this.getByChannleData()
    this.getCategoryListData()
    this.ChannleCountry()
  },

  methods: {
    getOptionDisable(item) {
      //编辑场景若存在禁用选项，则在编辑是讲该禁用选项放开
      if (
        item.status === 0 &&
        this.action.type !== 'save' &&
        this.action.data.businessScene == item.value
      ) {
        item.status = 1
      }
      return item.status === 0
    },
    stageDisabled(item) {
      // window.console.log('item',item);
      if (this.action.type == 'save') {
        return false
      } else {
        if (this.action.data?.status == '0') {
          return false
        } else {
          if (['0', '1'].some((ele) => this.ruleForm.stage == ele)) {
            // 值选的是 20%，30%，禁用其他
            if (['0', '1'].some((e) => item.value == e)) {
              return false
            } else {
              return true
            }
          } else {
            if (['0', '1'].some((e) => item.value == e)) {
              return true
            } else {
              return false
            }
          }
        }
      }
    },

    getProductLine(row) {
      // scope.row.productLine ? Array.isArray(scope.row.productLine)?'':''  :scope.row.productLine || scope.row.categoryName
      if (row.productLine) {
        if (Array.isArray(row.productLine)) {
          if (row.productLine.length) {
            return row.productLine.join(' / ')
          } else {
            return '--'
          }
        } else {
          return row.productLine
        }
      } else if (row.categoryName) {
        if (Array.isArray(row.categoryName)) {
          if (row.categoryName.length) {
            return row.categoryName.join('/')
          } else {
            return '--'
          }
        } else {
          return row.categoryName
        }
      } else {
        return '--'
      }
    },
    // 获取渠道信息
    getByChannleData() {
      let params = {
        channelId: this.userInfo.channelId,
        channelType: this.userInfo.channelClass,
      }
      getByChannle(params).then((res) => {
        this.channelList = res.rows
        if (this.businessId) {
          this.getDetails(this.businessId)
        }
      })
    },
    //获取渠道国家信息
    ChannleCountry() {
      let params = {
        id: this.$store.state.user.userInfo.channelId,
        userName: this.$store.state.user.userInfo.username,
      }
      console.log(params)
      getByChannleCountry(params).then((res) => {
        this.ChannleCountryData = res.rows
      })
    },
    // 选择渠道
    handleCommand(command) {
      this.channel = command
    },
    currentFindFullName(name, data) {
      var arrRes = []
      const rev = (data, nodeName) => {
        for (var i = 0, length = data.length; i < length; i++) {
          const node = data[i]
          if (node.name == nodeName) {
            arrRes.unshift(node.name)
            return true
          } else {
            if (node.children && node.children.length) {
              if (rev(node.children, nodeName)) {
                arrRes.unshift(node.name)
                return true
              }
            }
          }
        }
        return false
      }
      rev(data, name)
      return arrRes
    },

    //获取回显产品线id
    getProductListId(val, options) {
      let idArr = []
      options.forEach((item) => {
        if (item.children && item.children.length) {
          item.children.forEach((subItem) => {
            if (subItem.children && subItem.children.length) {
              subItem.children.forEach((thirdItem) => {
                if (val === thirdItem.id.toString()) {
                  idArr = [item.id, subItem.id, val]
                }
              })
            }
          })
        }
      })
      return idArr
    },

    // 获取详情
    getDetails(businessId) {
      let params = {
        id: businessId,
        channelId: this.$store.state.user.userInfo.channelId,
        userName: this.$store.state.user.userInfo.username,
      }
      if (this.action.type == 'update') {
        if (
          this.action.data &&
          this.action.data.flowName == 'Renew Project Registration'
        ) {
          getEpaProjectdetail(params).then((res) => {
            this.ruleForm = res.rows
            this.productLineData = res.rows
            this.tableData = res.rows.list.map((item) => {
              item.categoryName = this.currentFindFullName(
                item.categoryName,
                this.products
              )
              return item
            })
          })
        } else {
          if (this.action.data.projectFiledId) {
            getProjectdetail(params).then((res) => {
              this.ruleForm = res.rows
              this.tableData = res.rows.list.map((item) => {
                item.productVlaue = this.getProductListId(
                  item.businessProductLine,
                  this.products
                )
                return item
              })
              this.productLineData = res.rows
              this.$forceUpdate()
            })
          } else {
            getProjectInfo(params).then((res) => {
              this.ruleForm = res.rows
              this.tableData = res.rows.productList.map((item) => {
                item.categoryName = this.currentFindFullName(
                  item.categoryName,
                  this.products
                )
                return item
              })
              this.productLineData = res.rows
            })
          }
        }
        this.ruleForm.industry = getFathersById(
          this.ruleForm.industry,
          this.$store.state.dict.dict.customer_business,
          'value',
          'list'
        )
      } else {
        getProjectInfo(params).then((res) => {
          this.productLineData = res.rows
          this.ruleForm = res.rows
          this.tableData = res.rows.productList.map((item) => {
            // item.categoryName = this.findFullName(
            //   item.categoryName,
            //   this.products
            // );
            return item
          })
        })
      }
    },
    // 获取公司名称
    getByProjectNameData(query) {
      this.ruleForm.company = query
      let params = {
        channelId: this.userInfo.channelId,
        name: query,
      }
      getByProjectName(params).then((res) => {
        this.companys = res.rows
      })
    },
    // 选择公司
    selectCompany(e) {
      let params = {
        id: e,
      }
      getCustomerInfo(params).then((res) => {
        let { customerId, customerName, address, country } = res.rows
        this.ruleForm.customerId = customerId
        this.ruleForm.company = customerName
        this.ruleForm.address = address
        this.ruleForm.country = country
      })
    },
    // 关闭
    hidenView() {
      this.$emit('hide-view')
    },
    // 获取产品集
    getCategoryListData() {
      getNewCategoryList().then((res) => {
        this.products = res.rows
      })
    },
    // 选择产品
    handleProductChange(index, value) {
      const labelList =
        this.$refs['cascader' + index].getCheckedNodes()[0].pathLabels
      this.tableData[index].productLine = labelList[2]
      this.tableData[index].businessProductLine = value[2]
      this.tableData[index].productVlaue = value
    },
    //下游渠道和总代的远程搜索
    remoteMethod(query) {
      let params = {
        channelClass: '0,1', //0 总代，1 vad    根据需求传入相应渠道等级
        deptId: this.userInfo.deptId,
        channelName: query,
      }
      getDistribute(params).then((res) => {
        this.distributeList = res.rows
      })
    },
    // 新增行
    addLine() {
      let obj = {
        productVlaue: [],
        productLine: '',
        settings: '',
        quantity: '1',
        action: false,
        businessProductLine: '',
      }
      this.tableData.push(obj)
    },
    // 删除行
    cancelLine(index) {
      this.tableData.splice(index, 1)
    },
    // 提交
    submitForm(formName, draftStatus) {
      this.confirmLoading = true
      const p1 = new Promise((resolve, reject) => {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            resolve()
          } else {
            reject()
          }
        })
      })
      const p2 = new Promise((resolve, reject) => {
        this.$refs['channelRuleForm'].validate((valid) => {
          if (valid) {
            resolve()
          } else {
            reject()
          }
        })
      })
      console.log(this.tableData)
      let productLine = this.tableData.some((val) => {
        return val.productLine || val.categoryName
      })
      console.log(productLine)
      if (productLine) {
        Promise.all([p1, p2])
          .then(() => {
            let params = JSON.parse(JSON.stringify(this.ruleForm))
            // 设置申请人
            params.applicant =
              this.action.type == 'update'
                ? this.userInfo.applicant || '--'
                : this.userInfo.name
                ? (this.userInfo.surname || '') + ' ' + this.userInfo.name
                : '--'
            // 设置申请人邮箱
            params.applicantEmail = this.userInfo.email
            // 设置部门id
            params.deptId =
              this.action.data &&
              this.action.data.flowName == 'Renew Project Registration'
                ? this.productLineData.deptId
                : this.ChannleCountryData.dept_id
            // params.deptId = this.$store.state.user.userInfo.deptId
            // 是否为草稿
            params.saveType = draftStatus
            params.applicantAddress = this.ChannleCountryData.country
            params.applicantMobile = this.ChannleCountryData.mobile
            params.expectedPoDate = moment(params.expectedPoDate).format()
            let channelObj = {}
            this.channelList.forEach((val) => {
              // if (
              //   this.userInfo.channelType == "0" ||
              //   this.userInfo.channelType == "1"
              // ) {
              //   if (val.channelId == this.ruleForm.channel) {
              //     channelObj = val;
              //   }
              // } else {
              //   }
              if (val.channelId == this.ruleForm.distributor) {
                channelObj = val
              }
            })
            console.log(channelObj)
            // 判断是否为总代
            if (
              this.$store.state.user.userInfo.channelClass == '0' ||
              this.$store.state.user.userInfo.channelClass == '1'
            ) {
              // params.channel = channelObj.channelName;
              // params.channelId = channelObj.channelId;
              params.distributor = this.userInfo.channelName
              params.distributorId = this.userInfo.channelId
              params.distributorReseller = 'Reseller'
            } else {
              console.log('dasdasd')
              params.channel = this.userInfo.channelName
              params.channelId = this.userInfo.channelId
              params.distributor = channelObj.channelName
              params.distributorId = channelObj.channelId
              params.distributorReseller = 'Distributor'
            }
            if (
              this.action.data &&
              this.action.data.flowName == 'Renew Project Registration'
            ) {
              params.updateType = '1'
            } else {
              params.updateType = '0'
            }
            params.industry =
              typeof params.industry === 'string'
                ? params.industry
                : params.industry[params.industry.length - 1]
            // params.requirement =
            //   typeof params.requirement === 'string'
            //     ? params.requirement
            //     : params.requirement[params.requirement.length - 1]
            params.businessScene = params.businessScene
            params.list = JSON.parse(JSON.stringify(this.tableData)).filter(
              (item) => item.productLine || item.categoryName
            )
            params.list = params.list.map((item) => {
              if (Array.isArray(item.categoryName)) {
                item.categoryName = item.categoryName.length
                  ? item.categoryName[item.categoryName.length - 1]
                  : ''
              }
              if (Array.isArray(item.productLine)) {
                item.productLine = item.productLine.length
                  ? item.productLine[item.productLine.length - 1]
                  : ''
              }
              return item
            })
            console.log(params)
            if (this.businessId && this.action.type != 'update') {
              // 修改
              updateProject(params)
                .then((res) => {
                  let { msg } = res
                  this.confirmLoading = false
                  this.$message({
                    type: 'success',
                    message: msg,
                  })
                  this.hidenView()
                  this.$emit('save-success', {
                    type: this.action.type,
                  })
                })
                .catch((err) => {
                  this.confirmLoading = false
                })
            } else if (this.businessId && this.action.type == 'update') {
              // if (JSON.stringify(this.channel) == "{}") {
              //   params.channel = this.userInfo.channel;
              //   params.channelId = this.userInfo.channelId;
              //   params.distributor = this.userInfo.channel;
                params.distributorId = this.productLineData.distributorId;
              // }
              ppupdateProject(params)
                .then((res) => {
                  let { msg } = res
                  this.confirmLoading = false
                  this.$message({
                    type: 'success',
                    message: msg,
                  })
                  this.hidenView()
                  this.$emit('save-success', {
                    type: this.action.type,
                  })
                })
                .catch((err) => {
                  this.confirmLoading = false
                })
            } else if (this.businessId && this.action.type == 'eaupdate') {
              // if (JSON.stringify(this.channel) == "{}") {
              //   params.channel = this.userInfo.channel;
              //   params.channelId = this.userInfo.channelId;
              //   params.distributor = this.userInfo.channel;
              //   params.distributorId = this.userInfo.channelId;
              // }
              updateProject(params)
                .then((res) => {
                  let { msg } = res
                  this.confirmLoading = false
                  this.$message({
                    type: 'success',
                    message: msg,
                  })
                  this.hidenView()
                  this.$emit('save-success', {
                    type: this.action.type,
                  })
                })
                .catch((err) => {
                  this.confirmLoading = false
                })
            } else {
              newProject(params)
                .then((res) => {
                  let { msg } = res
                  this.confirmLoading = false
                  this.$message({
                    type: 'success',
                    message: msg,
                  })
                  this.hidenView()
                  this.$emit('save-success', {
                    type: this.action.type,
                  })
                })
                .catch((err) => {
                  this.confirmLoading = false
                })
            }
          })
          .catch(() => {
            this.confirmLoading = false
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              isError.length &&
                isError[0].scrollIntoView({
                  // 滚动到指定节点
                  // 值有start,center,end，nearest，当前显示在视图区域中间
                  block: 'center',
                  // 值有auto、instant,smooth，缓动动画（当前是慢速的）
                  behavior: 'smooth',
                })
            })
            return false
          })
      } else {
        this.$message({
          type: 'warning',
          message: 'The product line cannot be empty',
        })
        this.confirmLoading = false
      }
    },
    AAAAchange(data) {
      console.log(data)
      this.ruleForm.industry = data.value
    },
    requirementChange(data) {
      this.ruleForm.businessScene = data.value
    },
  },
}
</script>

<style lang="less" scoped>
.crm-create-header {
  height: 40px;
  margin-bottom: 20px;
  padding: 0 10px;
  flex-shrink: 0;
  .close {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: -10px;
    padding: 10px;
    cursor: pointer;
  }
}
.crm-create-container {
  position: relative;
  height: 100%;
  .item-label {
    display: inline-block;
    width: 210px;
    padding: 0 12px 0 0;
    text-align: right;
  }
}
.crm-create-item {
  flex: 0 0 50%;
  flex-shrink: 0;
  margin-bottom: 22px;
}
.el-form {
  /deep/ .el-form-item {
    width: 50%;
    flex-shrink: 0;
  }
}

.crm-create-flex {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px 5px 0;
  flex: 1;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > h1 {
      font-size: 18px;
      color: #343a40;
    }

    & > p {
      font-size: 12px;
      color: #a1a4b4;

      & > span {
        padding: 0 3px;
        color: #f46a6a;
      }
    }
  }

  .subheading {
    margin-top: 10px;

    & > h1 {
      font-size: 16px;
      color: #343a40;
    }
  }

  .channelInfo {
    margin: 25px 0;

    p {
      padding-bottom: 13px;
      display: flex;
      align-items: flex-start;
      width: 33%;

      & > span {
        display: block;
        width: 200px;
        font-size: 14px;
        color: #999a9e;
        word-wrap: break-word;
      }

      & > a {
        padding: 0 26px;
        display: block;
        width: calc(100% - 182px);
        font-size: 14px;
        color: #343a40;
        cursor: text;
        word-wrap: break-word;
      }
    }
  }

  // .project-form {
  //   margin-top: 25px;

  //   .project-form-item {
  //     margin-bottom: 24px;

  //     /deep/ .el-form-item__label {
  //       line-height: 14px;
  //     }
  //   }
  // }

  .add-line {
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    color: #556ee6;
    border: 1px solid #e5e7ec;
    border-top: none;
    & > a {
      margin-right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      border: 1px solid #556ee6;
    }
  }
}

.handle-bar {
  position: relative;
  margin: 0 auto;

  .handle-button {
    // float: right;
    margin-top: 5px;
    margin-right: 10px;
  }
}

/deep/.customer-budget-input input::-webkit-outer-spin-button,
/deep/.customer-budget-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/.customer-budget-input input[type='number'] {
  -moz-appearance: textfield;
}
</style>