import request from '@/utils/request'

//结构树list
export function gettreeList (data) {
    return request({
        url:'/sf-partnerportal/crm/category/pp/productConfig/list',
        method:'post',
        data
    })
}

//获取列表
export function getProductList (data) {
    return request({
        url:'/sf-partnerportal/productRecord/list',
        method:'post',
        data
    })
}

//单个产品删除
export function productRemove (data) {
    return request({
        url:'/sf-partnerportal/productRecordDetail/removeDetail',
        method:'post',
        data
    })
}

//项目批量删除
export function productBatchRemove (data) {
    return request({
        url:'/sf-partnerportal/productRecord/remove',
        method:'post',
        data
    })
}

//下载boom表
export function exportBom (data) {
    return request({
        url:'/sf-partnerportal/productRecord/exportBom',
        method:'post',
        responseType: 'arraybuffer',
        data
    })
}

//配置列表
export function configList (data) {
    return request({
        url:'/sf-partnerportal/productRecord/config',
        method:'post',
        data
    })
}

//保存
export function saveOrUpdate (data) {
    return request({
        url:'/sf-partnerportal/productRecordDetail/update',
        method:'post',
        data
    })
}

//获取数据回显 编辑页面
export function getDetailList (data) {
    return request({
        url:'/sf-partnerportal/productRecordDetail/list',
        method:'post',
        data
    })
}

//不在提示
export function noTips (data) {
    return request({
        url:'/sf-partnerportal/productNotTip/list',
        method:'post',
        data
    })
}


export function noTipsSave (data) {
    return request({
        url:'/sf-partnerportal/productNotTip/save',
        method:'post',
        data
    })
}

//已下载Bom 
export function getDownList (data) {
    return request({
        url:'/sf-partnerportal/productRecordBom/list',
        method:'post',
        data
    })
}

//预览/productRecord/exportDemo
export function exportDemo (data) {
    return request({
        url:'/sf-partnerportal/productRecordBom/exportBom',
        method:'post',
        responseType: 'arraybuffer',
        data
    })
}
//预览
export function showexcel (data) {
    return request({
        url:'/sf-partnerportal/productRecordBom/showexcel',
        method:'post',
        responseType: 'arraybuffer',
        data
    })
}

//是否删除抛错
export function isExist (data) {
    return request({
        url:'/sf-partnerportal/productRecord/isExist',
        method:'post',
        data
    })
}