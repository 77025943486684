<template>
  <!-- /** 设备归还*// -->
  <el-dialog
    :visible="visible"
    :append-to-body="true"
    :title="$t('allEquipment.equipmentReturn')"
    width="900px"
    @close="handleCancel"
    v-dialogDrag
  >
    <el-form
      ref="crmForm"
      :model="temp"
      :rules="rules"
      label-width="100px"
      style="overflow: auto"
    >
      <!-- 产品信息 -->
      <create-sections
        :title="$t('detail.projectInfo') + ':'"
        noShowMark
        noShowLine
        style="margin-bottom: 20px"
      >
        <el-table
          :data="productList"
          max-height="300px"
          stripe
          border
          highlight-current-row
          class="table-list"
          style="width: 100%"
        >
          <el-table-column
            :label="$t('detail.productName')"
            prop="itemName"
            header-align="center"
            :formatter="fieldFormatter"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            :label="$t('detail.productType')"
            prop="productType"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.productType == "0"
                    ? $t("allEquipment.product")
                    : scope.row.productType == "1"
                    ? $t("allEquipment.accessory")
                    : "--"
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="itemNum"
            :label="$t('detail.d21')"
            align="center"
            :formatter="fieldFormatter"
            width="80"
          >
          </el-table-column>
          <!-- 押金 -->
          <el-table-column
            prop="itemDeposit"
            :label="$t('allEquipment.deposit')"
            :formatter="fieldFormatter"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="itemSn"
            :label="$t('allEquipment.sn')"
            :formatter="fieldFormatter"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="itemId"
            :label="$t('allEquipment.itemId')"
            :formatter="fieldFormatter"
            align="center"
          >
          </el-table-column>
          <el-table-column :label="$t('detail.d12')" width="120" align="center">
            <template slot-scope="scope">
              <span>
                {{
                  findDIctName("equipment_status", scope.row.equipmentStatus) ||
                  "--"
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 授权状态 -->
          <el-table-column
            :label="$t('allEquipment.authorizationStatus')"
            prop="usedStatus"
            :width="lang == 'CN' ? '100' : '140'"
            align="center"
          >
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.usedStatus
                    ? scope.row.usedStatus == "0"
                      ? $t("allEquipment.unauthorizated")
                      : scope.row.usedStatus == "1"
                      ? $t("allEquipment.authorized")
                      : $t("allEquipment.authorizateInProgress")
                    : "--"
                }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </create-sections>
      <el-row style="padding-right: 20px">
        <el-form-item :label="$t('detail.remark') + ':'" prop="remark">
          <el-input
            v-model="temp.remark"
            type="textarea"
            :rows="3"
            maxlength="1000"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="handle-bar">
      <el-button size="small" @click.native="handleCancel">{{
        $t("common.btn.cancel")
      }}</el-button>
      <el-button
        :loading="saveLoading"
        size="small"
        type="primary"
        @click="handleConfirm"
        >{{ $t("common.btn.save") }}</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { findDIctName, moneyFormat } from "@/utils";
import { packageReturn, packageReturnUpdate } from "@/api/allEquipment";
export default {
  name: "ReturnDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    actionType: {
      type: String,
      default: "save",
    },
  },
  data() {
    return {
      productList: [],
      temp: {
        remark: "",
      },
      rules: {
        remark: [
          { required: true, message: this.$t("filter.f68"), trigger: "change" },
        ],
      },
      saveLoading: false,
      lang: localStorage.getItem("lang"),
    };
  },
  computed: {
    findDIctName() {
      return findDIctName;
    },
  },
  watch: {
    "detail.borrowRecordEntities": {
      handler(val) {
        if (val) {
          this.productList = JSON.parse(JSON.stringify(val));
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    handleCancel() {
      this.$emit("update:visible", false);
    },
    fieldFormatter(row, column) {
      return row[column.property] || "--";
    },
    getRequest() {
      if (this.actionType == "save") {
        return packageReturn;
      } else {
        return packageReturnUpdate;
      }
    },
    handleConfirm() {
      this.$refs.crmForm.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          let params = {
            deptId: this.detail.deptId,
            packageId: this.detail.packageId,
            channelId: this.detail.channelId,
            taskId: this.detail.taskId,
            flowId: this.detail.flowId,
            flowNo: this.detail.flowNo,
            saveType: "1",
            remark: this.temp.remark,
          };
          if (this.actionType == "update") {
            params.refundId = this.detail.refundId;
          }
          let request = this.getRequest();
          request(params)
            .then((res) => {
              this.saveLoading = false;
              this.$emit("save-success", { saveType: "save" });
              this.handleCancel();
            })
            .catch((err) => {
              window.console.log("err", err);
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.handle-bar {
  position: relative;
  height: 30px;
  button {
    margin-right: 10px;
  }
}
.table-list {
  .el-form-item {
    margin-bottom: 0;
    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }
    &.is-error {
      margin-bottom: 20px;
    }
  }
}
.el-table .cell {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 23px;
}
.el-table /deep/ .el-table__body td {
  padding: 6px 0;
}
</style>