<template>
  <!-- 添加产品弹窗-->
  <el-dialog class="quote"
             :visible="visible"
             :append-to-body="true"
             :modal-append-to-body="false"
             :title="$t('detail.addProduct')"
             @close="onClose"
             width="1200px"
             :close-on-click-modal="false"
             v-dialogDrag>
    <el-form ref="form"
             :model="form"
             :rules="rules"
             label-width="120px"
             :inline="true">
      <div class="quote-dialog-header">
        <el-form-item :label="$t('detail.s8') + ':'">
          <el-cascader v-model="idArrr"
                       ref="productCas"
                       filterable
                       @change="initProduct"
                       clearable
                       :placeholder="$t('other.52')"
                       style="width: 600px"
                       :props="{ children: 'children', label: 'name', value: 'id' }"
                       :options="productMultiStruction"></el-cascader>
        </el-form-item>
        <i v-if="!Object.keys(productData).length && loading"
           class="el-icon-loading"
           style="line-height: 35px; margin-left: 5px"></i>
      </div>
      <div v-if="showInfo"
           class="info">{{$t('detail.productText')}}</div>
      <el-tabs v-model="activeName"
               tab-position="left"
               type="card"
               v-if="productData">
        <el-tab-pane v-for="(item, index) in productData.children"
                     :key="item.itemNameQuote"
                     :name="index.toString()">
          <div slot="label">
            <span>{{ item.itemNameQuote }}</span>
          </div>
          <div class="quote-tabs-content"
               v-if="activeName == index.toString()">
            <el-table :data="item.children"
                      height="450px"
                      :row-style="rowStyle"
                      v-loading="loading"
                      border
                      ref="eltable"
                      @row-click="handleRowClick">
              <el-table-column :label="$t('detail.productName')"
                               header-align="center"
                               min-width="120">
                <template slot-scope="scope">
                  <span :class="{ 'quote-tabs-warn': !scope.row.warning }"
                        v-if="scope.row.booleanSale">{{ scope.row.invoiceNameInt }}</span>
                  <span class="quote-tabs-noSale"
                        v-if="!scope.row.booleanSale">
                    <i :class="
                        hideRow.indexOf(scope.row.itemCode) == -1
                          ? 'el-icon-remove'
                          : 'el-icon-circle-plus'
                      "></i>
                    {{ scope.row.invoiceNameInt }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('detail.productType')"
                               align="center"
                               width="140">
                <template slot-scope="scope"
                          v-if="scope.row.booleanSale">{{
                  scope.row.itemTypeName
                }}</template>
              </el-table-column>
              <!-- 产品说明 -->
              <el-table-column :label="$t('detail.s10')"
                               header-align="center"
                               width="470">
                <template slot-scope="scope"
                          v-if="scope.row.booleanSale">
                  <span style="color: red">{{
                    scope.row.remark ? "【" + scope.row.remark + "】" : ""
                  }}</span>
                  {{ scope.row.productDesc }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('detail.s13')"
                               align="center"
                               header-align="center"
                               width="60"
                               prop="unit">
                <template slot-scope="scope"
                          v-if="scope.row.booleanSale">{{
                  filter(scope.row.unit)
                }}</template>
              </el-table-column>
              <el-table-column :label="$t('detail.d21')"
                               align="center"
                               header-align="center"
                               width="80">
                <template slot-scope="scope">
                  <el-input-number v-if="scope.row.booleanSale"
                                   v-model="scope.row.itemNum"
                                   type="number"
                                   size="mini"
                                   :max="9999"
                                   controls-position="right"
                                   @change="changeNum($event, scope.row)"
                                   :min="0"></el-input-number>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div slot="footer"
         class="quote-dialog-footer">
      <el-button type="primary"
                 @click="save"
                 size="mini"
                 class="quote-save"
                 :disabled="setBtn">{{ $t("common.btn.save") }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getProductMultiStruction, getProductInit, getChannelProductUnit } from "@/api/allEquipment";
export default {
  name: "GetProduct",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    sceneType: String
  },
  data () {
    return {
      loading: false,
      form: {},
      rules: {},
      idArrr: [],
      productMultiStruction: [],
      productData: {}, // 产品型号数据
      hideRow: [],
      setBtn: false,
      activeName: "",
      showInfo: false
    };
  },
  mounted () {
    getProductMultiStruction().then((res) => {
      this.productMultiStruction = res.rows;
    });
  },
  methods: {

    getProductInitRequest () {
      if (this.sceneType === 'channelApply') {
        return getChannelProductUnit
      } else if (!this.sceneType) {
        return getProductInit
      }
    },
    onClose () {
      this.productData = {};
      this.idArrr = [];
      this.$emit("update:visible", false);
    },
    filter (unit) {
      if (this.$store.state.dict.dict.materialsUnit) {
        return this.$store.state.dict.dict.materialsUnit.reduce((str, ele) => {
          if (ele.name == unit) {
            str = ele.value;
          }
          return str;
        }, "");
      } else {
        return unit;
      }
    },
    rowStyle ({ row, rowIndex }) {
      let style = {};
      this.hideRow.indexOf(row.parentCode) !== -1 && row.hot === false
        ? (style.display = "none")
        : "";
      if (!row.booleanSale) {
        style.cursor = "pointer";
      }
      return style;
    },
    packUp (row) {
      if (this.hideRow.indexOf(row.itemCode) == -1) {
        this.hideRow.push(row.itemCode);
      } else {
        this.hideRow.splice(this.hideRow.indexOf(row.itemCode), 1);
      }
      this.$nextTick(() => {
        this.$refs.eltable.map((item) => item.doLayout && item.doLayout());
      });
    },
    initProduct (idArrr) {
      if (idArrr.length == 0) {
        this.productData = {};
        return;
      }
      let paraId = {};
      function setParaId (arr) {
        arr.map((item) => {
          if (item.children && item.children.length) {
            setParaId(item.children);
          } else {
            paraId[item.id] = item;
          }
        });
      }
      setParaId(this.productMultiStruction);
      let row = paraId[idArrr[idArrr.length - 1]];
      this.form.productId = "";
      this.form.bomUniqueId = row.code;
      this.form.categoryName = row.fullPath.split("/").slice(3, 4).join("");
      this.hideRow = [];
      this.getInitProduct(row.code);
      this.setBtn = false;
    },
    changeNum (value, row) {
      _.map(this.productData.children, (a, n) => {
        _.map(a.children, (b, m) => {
          if (b.uniqueId == row.uniqueId) {
            const obj = Object.assign({}, b, { itemNum: value });
            this.$set(this.productData.children[n].children, m, obj);
            if (b.booleanServicePer && b.ladderPriceList) {
              JSON.parse(b.ladderPriceList).map((ele) => {
                if (ele.toCount >= b.itemNum && b.itemNum >= ele.fromCount) {
                  const obj = Object.assign({}, b, {
                    itemPrice: ele.ladderPrice,
                  });
                  this.$set(this.productData.children[n].children, m, obj);
                }
              });
            }
          }
        });
      });
    },
    getInitProduct (code) {
      this.loading = true;
      const request = this.getProductInitRequest()
      request(code)
        .then((res) => {
          if (!res.rows.children || !res.rows.children.length) {
            this.showInfo = true
          } else {
            this.showInfo = false
          }
          if (res.rows.itemCode) {
            this.productData = Object.assign({}, res.rows);
            this.productData.children.map((item, n) => {
              var productArray = [];
              item.children.map((v) => {
                this.hideRow.push(v.itemCode);
                productArray.push(v);
                if (v.children) {
                  v.children.map((obj) => {
                    productArray.push(obj);
                  });
                }
              });
              this.productData.children[n].children = productArray;
            });
            this.activeName = "0";
          } else {
            this.$message.error("产品已下架");
            this.productData = {};
            this.form.name = "";
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleRowClick (row, column) {
      if (!row.booleanSale) {
        this.packUp(row);
      }
    },
    save () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const haveSip = this.idArrr.some((item) => {
            return item == "212144" || item == "394" || item == "543";
          });
          // 去重
          // if (this.productData && this.productData.children) {
          let productSelectEntityList = _.uniqBy(
            this.productData.children.reduce((arr, ele) => {
              ele.children.map((olo) => {
                if (olo.itemNum) {
                  arr.push({
                    uniqueId: olo.uniqueId,
                    itemCode: olo.itemCode,
                    itemName: olo.itemName,
                    invoiceNameInt: olo.invoiceNameInt,
                    itemNum: olo.itemNum,
                    itemType: olo.itemTypeName,
                    equipmentQuota: olo.equipmentQuota,
                    itemDeposit: olo.depositQuota,
                    firstBorrowDayNum: olo.firstBorrowDayNum,
                    renewDayNum: olo.renewDayNum,
                    firstAuthDayNum: olo.firstAuthDayNum,
                    renewAuthDayNum: olo.renewAuthDayNum,
                    haveSip: haveSip ? "1" : "0",
                    productType:
                      olo.itemCategory == "3.00"
                        ? "1"
                        : olo.itemCategory == "1.00"
                          ? "0"
                          : "",
                  });
                }
              });
              return arr;
            }, []),
            "uniqueId"
          );
          // }
          let productSelectEntityArr = [];
          productSelectEntityList.forEach((item) => {
            if (item.productType == "0") {
              productSelectEntityArr.push(item);
            } else {
              let isHasItem = productSelectEntityArr.find(
                (ele) => ele.itemName == item.itemName
              );
              if (isHasItem) {
                isHasItem.itemNum += item.itemNum;
              } else {
                productSelectEntityArr.push(item);
              }
            }
          });

          let params = _.cloneDeep(this.form);
          params.productSelectEntityList = productSelectEntityArr;
          this.$emit("save-success", params);
          this.onClose();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.quote {
  .quote-dialog-header {
    margin: 5px 0px;
    span {
      color: red;
      margin-left: 10px;
      cursor: default;
    }
  }
  .quote-tabs-content {
    // min-height: 600px;
    padding: 10px;
    border: 1px solid #dddddd;
    overflow: auto;
  }
  .quote-tabs-warn {
    padding-left: 16px;
  }
  .quote-tabs-noSale {
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
  }
  .quote-tabs-warning {
    font-size: 16px;
  }
  .quote-saveAdd {
    padding: 7px;
  }
  .quote-save {
    padding: 7px 25px;
  }
  .quote-dialog-footer {
    span {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      color: red;
      font-size: 14px;
    }
  }
  .quote-nosale {
    width: 52px;
    height: 17px;
    vertical-align: middle;
  }
}
.quote /deep/ .el-dialog {
  max-height: none;
}
/deep/ .el-tab-pane {
  height: auto !important;
}
/deep/ .el-table__body-wrapper {
  overflow: auto;
}
.info {
  margin-left: 120px;
  color: #f56c6c;
}
</style>