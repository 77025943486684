<template>
  <!-- 设备退押金弹窗 -->
  <el-dialog :visible="visible"
             :append-to-body="true"
             :title="$t('allEquipment.applyDepositRefund')"
             width="900px"
             @close="handleCancel"
             v-dialogDrag>
    <el-form ref="crmForm"
             :model="temp"
             :rules="rules"
             style="overflow: auto">
      <el-row>
        <el-col :span="12">
          <!-- 总押金 -->
          <el-form-item :label="$t('allEquipment.totalDeposit') + ':'"
                        prop="totalDeposit">
            {{ temp.totalDeposit || "0" }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 需退押金 -->
          <el-form-item :label="$t('allEquipment.requiredRefundAmount') + ':'"
                        prop="returnDposit">
            {{ temp.returnDposit || "0" }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item :label="$t('detail.remark') + ':'"
                      prop="remark">
          <el-input v-model="temp.remark"
                    type="textarea"
                    :rows="3"
                    maxlength="1000"
                    show-word-limit></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer"
         class="handle-bar">
      <el-button size="small"
                 @click.native="handleCancel">{{
        $t("common.btn.cancel")
      }}</el-button>
      <el-button :loading="saveLoading"
                 size="small"
                 type="primary"
                 @click="handleConfirm">{{ $t("common.btn.save") }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { findDIctName, moneyFormat } from "@/utils";
import { packageRefund, packageRefundUpdate } from "@/api/allEquipment";
export default {
  name: "RefundDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    actionType: {
      type: String,
      default: "save",
    },
  },
  data () {
    return {
      temp: {
        totalDeposit: "",
        returnDposit: "",
        remark: "",
      },
      rules: {
        remark: [
          { required: true, message: this.$t("filter.f68"), trigger: "change" },
        ],
      },
      saveLoading: false,
      lang: localStorage.getItem("lang"),
    };
  },
  computed: {
    findDIctName () {
      return findDIctName;
    },
  },
  watch: {},
  mounted () {
    this.resetFormData();
  },
  methods: {
    handleCancel () {
      this.$emit("update:visible", false);
    },
    resetFormData () {
      this.temp.totalDeposit = this.detail.totalDeposit;
      this.temp.returnDposit = this.detail.borrowRecordEntities
        ? this.detail.borrowRecordEntities.reduce((totle, item) => {
          return (totle +=
            item.equipmentStatus == "4" ? item.itemDeposit : 0);
        }, 0)
        : 0;
    },
    fieldFormatter (row, column) {
      return row[column.property] || "--";
    },
    getRequest () {
      if (this.actionType == "save") {
        return packageRefund;
      } else {
        return packageRefundUpdate;
      }
    },
    handleConfirm () {
      this.$refs.crmForm.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          let params = {
            deptId: this.detail.deptId,
            packageId: this.detail.packageId,
            channelId: this.detail.channelId,
            tradeMoney: this.temp.returnDposit,
            taskId: this.detail.taskId,
            flowId: this.detail.flowId,
            flowNo: this.detail.flowNo,
            saveType: "1",
            remark: this.temp.remark,
          };
          if (this.actionType == "update") {
            params.refundId = this.detail.refundId;
          }
          let request = this.getRequest();
          request(params)
            .then((res) => {
              this.saveLoading = false;
              this.$emit("save-success", { saveType: "save" });
              this.handleCancel();
            })
            .catch((err) => {
              window.console.log("err", err);
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.handle-bar {
  position: relative;
  height: 30px;
  button {
    margin-right: 10px;
  }
}
</style>