import request from '@/utils/request'

// 获取当前用户信息
export function getUserInfo () {
  return request({
    url: '/api-user/sys/ppuser/info',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'get'
  })
}
// 获取菜单
export function getMenu (params) {
  return request({
    url: '/api-user/sys/ppmenu/userWithRouteFormat',
    heades: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'get',
    params
  })
}

//草稿及审批删除
export function deleteMyDraft (data) {
  return request({
    url: '/sf-partnerportal/pp/approval/delMyDraft',
    method: 'post',
    data
  })
}

// 查询用户自定义的隐藏和显示列
export function queryFieldConfig (data) {
  return request({
    url: '/api-gsm/basic/field/queryFieldConfig',
    method: 'post',
    data: data
  })
}
// 保存用户自定义的表头
export function saveFieldConfig (data) {
  return request({
    url: '/api-gsm/basic/field/saveFieldConfig',
    method: 'post',
    data: data
  })
}
// 获取用户高级筛选的场景
export function getScene (data) {
  return request({
    url: '/api-gsm/basic/scene/queryScene',
    method: 'post',
    data: data
  })
}
// 保存用户高级筛选场景
export function saveScene (data) {
  return request({
    url: '/api-gsm/basic/scene/save',
    method: 'post',
    data: data
  })
}
//   编辑高级筛选搜索场景
export function editScene (data) {
  return request({
    url: '/api-gsm/basic/scene/update',
    method: 'post',
    data: data
  })
}
// 删除场景
export function deleteScene (data) {
  return request({
    url: '/api-gsm/basic/scene/remove',
    method: 'post',
    data: data
  })
}
export function crmSceneDefaults (data) {
  return request({
    url: '/api-gsm/basic/scene/setDefault',
    method: 'post',
    data: data
  })
}
//保存用户设置的隐藏显示筛选场景
export function saveSceneConfig (data) {
  return request({
    url: '/api-gsm/basic/scene/saveSceneConfig',
    method: 'post',
    data: data
  })
}
// 获取用户设置的隐藏显示场景
export function querySceneConfig (data) {
  return request({
    url: '/api-gsm/basic/scene/querySceneConfig',
    method: 'post',
    data: data
  })
}
// 获取动态表头
export function queryListHead (data) {
  return request({
    url: '/api-gsm/basic/fieldsoft/queryListHead',
    method: 'post',
    data: data
  })
}
// 修改宽度
export function updateListHeadWidth (data) {
  return request({
    url: '/api-gsm/basic/fieldsoft/updateListHeadWidth',
    method: 'post',
    data: data
  })
}
// 字段表查询
export function queryDict (key) {
  return request({
    url: '/api-gsm/sys/dict/selectDict',

    method: 'post',
    data: key
  })
}



// // 部门信息
// export function queryDept (data) {
//   return request({
//     url: '/api-gsm/access/dept/selectFilterBy',

//     method: 'post',
//     data: data
//   })
// }


// 删除文件
export function delFile (data) {
  return request({
    url: '/api-gsm/sys/file/remove',
    method: 'post',
    data: data
  })
}
// 部门信息
export function queryDept (data) {
  return request({
    url: '/api-gsm/access/dept/selectFilterByUser',

    method: 'post',
    data: data
  })
}

// 获取需求场景
export function getRequireDist (data) {
  return request({
    url: '/api-gsm/sys/dict/listWithChildren',

    method: 'post',
    data: data
  })
}
