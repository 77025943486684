import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import permission from './modules/permission'
import dict from './modules/dict'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    businessId: '',
    projectDetails: {}
  },
  mutations: {
    setBusinessId (state, businessId) {
      state.businessId = businessId
    },
    projectDetails (state, projectDetails) {
      state.projectDetails = projectDetails
    }
  },
  modules: {
    app,
    user,
    permission,
    dict
  },
  getters: {
    userInfo: state => state.user.userInfo,
    dict: state => state.dict.dict,
    activeIndex: state => state.app.sidebar.activeIndex,
    activeName: state => state.app.sidebar.activeName,
    // 权限
    permission: state => state.user.permission,
    //菜单
    crmMenu: state => state.permission.crmMenu,
    // 路由
    addRouters: state => state.permission.addRouters,
    taskCount: state=>state.user.countMyTask
  }
})
