<template>
  <flexbox direction="column"
           align="stretch"
           class="crm-create-container">
    <flexbox class="crm-create-header">
      <!-- 设备授权申请 -->
      <div style="
          flex: 1;
          font-size: 17px;
          font-family: Alibaba PuHuiTi;
          font-weight: 600;
          line-height: 22px;
          color: #343a40;
          opacity: 1;
        ">
        {{ $t("allEquipment.equipmentApply") }}
      </div>
      <img class="close"
           src="@/assets/img/task_close.png"
           @click="hidenView" />
    </flexbox>
    <div class="crm-create-flex">
      <el-form ref="crmForm"
               :model="crmForm"
               :rules="rules"
               label-width="130px">
        <create-sections :title="$t('detail.basicInfo')">
          <div style="padding-left: 50px">
            <el-row>
              <el-col :span="crmForm.depositStatus == '0' ? 8 : 12">
                <!-- 渠道商 -->
                <el-form-item :label="$t('allEquipment.channel') + ':'">
                  {{ crmForm.channelName }}
                </el-form-item>
              </el-col>
              <el-col :span="crmForm.depositStatus == '0' ? 8 : 12">
                <!-- 申请者 -->
                <el-form-item :label="$t('approval.a4') + ':'">
                  {{ crmForm.applicantName }}
                </el-form-item>
              </el-col>
              <el-col :span="8"
                      v-if="crmForm.depositStatus == '0'">
                <!-- 所需押金 -->
                <el-form-item :label="$t('allEquipment.requiredDeposit') + ':'">
                  {{ totalDeposit }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <!-- 借测类型 -->
              <el-form-item :label="$t('allEquipment.borrowType') + ':'">
                <el-radio-group v-model="crmForm.depositStatus">
                  <!-- 不支付押金 -->
                  <el-radio label="1">{{
                    $t("allEquipment.noDepositRequired")
                  }}</el-radio>
                  <!-- 支付押金 -->
                  <el-radio label="0">{{
                    $t("allEquipment.depositRequired")
                  }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item :label=" crmForm.depositStatus === '0' ? $t('allEquipment.uploadVoucher') + ':' : $t('createcase.Attachments') + ':'"
                            prop="sysFileEntityList">
                <!-- <flexbox>
                  <div class="upload-documents" @click="uploadDocumentsClick">
                    <div
                      class="default"
                      v-if="!uploadFiles || uploadFiles.length == 0"
                    >
                      <i class="el-icon-upload"></i>
                      <div>
                        请上传支付押金的凭证（支持PNG,JPG,PDF,TIF,GIF格式）
                      </div>
                    </div>
                    <el-upload
                      ref="upload"
                      list-type="picture-card"
                      :file-list="fileList"
                      :headers="{ Authorization: 'Bearer ' + token }"
                      name="uploadFiles"
                      :action="apiUrl + 'api-gsm/sys/file/uploadFile'"
                      multiple
                      :auto-upload="false"
                      :before-upload="beforeUpload"
                      :on-error="uploadError"
                      :on-success="uploadSuccess"
                      class="upload"
                    >
                      <div
                        slot="file"
                        slot-scope="{ file }"
                        style="height: 100%"
                      >
                        <el-progress
                          v-if="file.status == 'uploading'"
                          type="circle"
                          :percentage="Math.floor(file.percentage)"
                          style="width: 100%"
                          @click.stop
                        ></el-progress>
                        <img
                          class="el-upload-list__item-thumbnail"
                          v-if="file.status != 'uploading'"
                          :src="file.url"
                          alt=""
                        />
                        <span
                          v-if="file.status != 'uploading'"
                          class="el-upload-list__item-actions"
                          @click.stop
                        >
                          <span
                            class="el-upload-list__item-preview"
                            @click.stop="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            class="el-upload-list__item-delete"
                            @click.stop="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog
                      :visible.sync="documentsVisible"
                      :modal-append-to-body="true"
                      :append-to-body="true"
                      :show-close="false"
                      width="40%"
                      v-dialogDrag
                    >
                      <img width="100%" :src="documentsImageUrl" alt="" />
                    </el-dialog>
                  </div>
                  <el-button
                    type="primary"
                    size="medium"
                    style="margin-left: 30px; background-color: #556ee6"
                    @click="submitUpload"
                    >发送文件</el-button
                  >
                </flexbox> -->
                <XhFiles :value="crmForm.sysFileEntityList"
                         @value-change="
                    (val) => {
                      handleChange(val, 'sysFileEntityList');
                    }
                  "></XhFiles>
              </el-form-item>
            </el-row>
          </div>
        </create-sections>
        <!-- 项目信息 -->
        <create-sections :title="$t('allEquipment.projectInformation')"
                         v-if="crmForm.depositStatus == '1'">
          <div style="padding-left: 50px">
            <el-row :gutter="120">
              <el-col :span="12">
                <!-- 项目名称 -->
                <el-form-item :label="$t('detail.projectName') + ':'"
                              prop="businessName">
                  <el-select style="width: 100%"
                             v-model="crmForm.businessName"
                             filterable
                             remote
                             reserve-keyword
                             clearable
                             :placeholder="$t('placeholder.search')"
                             :loading="projectNameLoading"
                             :remote-method="projectNameRemote"
                             @change="
                      (val) => {
                        handleChange(val, 'businessName');
                      }
                    ">
                    <el-option v-for="(item, index) in projectNameArr"
                               :key="index"
                               :label="item.business_name"
                               :value="item.business_name"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- 客户名称 -->
                <el-form-item :label="$t('detail.customerName') + ':'"
                              prop="customerName">
                  {{ crmForm.customerName || "--" }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="120">
              <el-col :span="12">
                <!-- 项目所属阶段 -->
                <el-form-item :label="$t('allEquipment.projectStage') + ':'">
                  {{ crmForm.percent || "--" }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <!-- 项目金额 -->
                <el-form-item :label="$t('allEquipment.projectAmount') + ':'">
                  {{ crmForm.money || "--" }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="120">
              <el-col :span="20">
                <!-- 申请原因 -->
                <el-form-item :label="$t('allEquipment.applyReason') + ':'"
                              prop="pursueReason">
                  <el-input v-model="crmForm.pursueReason"
                            :rows="3"
                            type="textarea"
                            maxlength="1000"
                            show-word-limit />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </create-sections>
        <!-- 产品信息 -->
        <create-sections :title="$t('detail.projectInfo')">
          <el-table :data="crmForm.productEntities"
                    stripe
                    border
                    highlight-current-row
                    class="productInfo"
                    style="width: 100%; margin-top: 5px">
            <el-table-column :label="$t('detail.productName')"
                             prop="invoiceNameInt">
              <template slot="header"
                        slot-scope="scope">
                <span style="color: red; margin-right: 4px">*</span><span>{{ $t("detail.productName") }}</span>
              </template>
              <template slot-scope="scope">
                <el-form-item :prop="'productEntities.' + scope.$index + '.invoiceNameInt'">
                  {{ scope.row.invoiceNameInt }}
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column :label="$t('detail.d21')"
                             width="80"
                             prop="itemNum">
              <template slot-scope="scope">
                <el-form-item :prop="'productEntities.' + scope.$index + '.itemNum'">
                  <el-input v-model="scope.row.itemNum"
                            size="mini"
                            style="text-align: center; width: 100%"
                            controls-position="right"
                            @input="(val) => itemNumChange(val, scope.row)"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 产品类型 -->
            <el-table-column :label="$t('detail.productType')"
                             prop="productType"
                             width="100">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.productType == "0"
                      ? $t("allEquipment.product")
                      : scope.row.productType == "1"
                      ? $t("allEquipment.accessory")
                      : "--"
                  }}
                </div>
              </template>
            </el-table-column>
            <!-- 单件配额 -->
            <!-- <el-table-column
              :label="$t('allEquipment.unitQuota')"
              width="120"
              prop="equipmentQuota"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'productEntities.' + scope.$index + '.equipmentQuota'"
                >
                  {{ scope.row.equipmentQuota || 0 }}
                </el-form-item>
              </template>
            </el-table-column> -->
            <!-- 押金 -->
            <el-table-column :label="$t('allEquipment.deposit')"
                             width="120"
                             prop="itemDeposit"
                             v-if="crmForm.depositStatus == '0'">
              <template slot-scope="scope">
                <el-form-item :prop="'productEntities.' + scope.$index + '.itemDeposit'">
                  {{ scope.row.itemDeposit*scope.row.itemNum || 0 }}
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column :label="$t('detail.remark')"
                             prop="remark">
              <template slot-scope="scope">
                <el-form-item :prop="'productEntities.' + scope.$index + '.remark'"
                              :rules="[
                    {
                      required: ['-6090001311002', '-6090001311001'].some(
                        (ele) => scope.row.uniqueId.includes(ele)
                      )
                        ? true
                        : false,
                      message: $t('filter.f68'),
                      trigger: 'change',
                    },
                  ]">
                  <flexbox>
                    <span style="color: red; margin-right: 4px"
                          v-if="
                        ['-6090001311002', '-6090001311001'].some((ele) =>
                          scope.row.uniqueId.includes(ele)
                        )
                      ">*</span>
                    <el-input v-model="scope.row.remark"
                              :rows="2"
                              type="textarea"
                              maxlength="1000"></el-input>
                  </flexbox>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column :label="$t('components.c5')"
                             width="90">
              <template slot-scope="scope">
                <el-button type="danger"
                           size="mini"
                           @click="deleteProduct(scope)"
                           style="width: 100%">{{ $t("components.c7") }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="add-product">
            <el-button type="text"
                       icon="el-icon-plus"
                       @click="addProduct">{{
              $t("detail.addProduct")
            }}</el-button>
          </div>
        </create-sections>
        <create-sections :title="$t('allEquipment.logisticsInformaion')"
                         noShowLine>
          <div style="padding-left: 50px">
            <el-row :gutter="30">
              <el-col :span="12">
                <el-form-item :label="$t('allEquipment.deliveryMethod') + ':'"
                              prop="sendType">
                  <el-radio-group v-model="crmForm.logisticsEntity.sendType"
                                  style="display: initial">
                    <el-radio label="0">{{
                      $t("allEquipment.normalMode")
                    }}</el-radio>
                    <el-radio label="1"
                              style="white-space: initial">{{
                      $t("allEquipment.selfPickUp")
                    }}</el-radio>
                    <!-- <el-radio label="1" style="white-space: initial">{{
                      $t("allEquipment.sfExpress") + $t("allEquipment.sfOther")
                    }}</el-radio> -->
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <template v-if="crmForm.logisticsEntity.sendType == '0'">
                <el-col :span="12">
                  <el-form-item :label="$t('allEquipment.postcode') + ':'"
                                prop="logisticsEntity.recipientsPostcode">
                    <el-input v-model="crmForm.logisticsEntity.recipientsPostcode"
                              size="medium"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('allEquipment.shippingAddress') + ':'"
                                prop="recipientsAddress">
                    <el-input v-model="crmForm.logisticsEntity.recipientsAddress"
                              size="medium"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('allEquipment.recipient')"
                                prop="logisticsEntity.recipients">
                    <el-input v-model="crmForm.logisticsEntity.recipients"
                              size="medium"
                              placeholder="Please input the contact person"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <!-- 联系电话 -->
                  <el-form-item :label="$t('other.76') + ':'"
                                prop="logisticsEntity.recipientsPhone">
                    <el-input v-model="crmForm.logisticsEntity.recipientsPhone"
                              oninput="value=value.replace(/[^\d|\+| ]/g,'')"
                              @blur="
                        (e) =>
                          (crmForm.logisticsEntity.recipientsPhone =
                            e.target.value)
                      "
                              size="medium"
                              placeholder="Please input the contact telephone"></el-input>
                  </el-form-item>
                </el-col>
              </template>
              <el-col :span="12">
                <el-form-item :label="$t('detail.remark') + ':'">
                  <el-input v-model="crmForm.logisticsEntity.remark"
                            :rows="3"
                            type="textarea"
                            maxlength="1000"
                            show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="30">
            </el-row> -->
            <!-- <el-row :gutter="30">
            </el-row> -->
          </div>
        </create-sections>
      </el-form>
    </div>
    <div class="handle-bar">
      <el-button class="handle-button"
                 :loading="saveLoad"
                 :disabled="saveDisable"
                 type="primary"
                 @click="handleTypeClick('save')">{{ $t("detail.s25") }}</el-button>
      <el-button class="handle-button"
                 :loading="saveDrafLoad"
                 :disabled="saveDrafDisable"
                 v-if="
          action.type == 'save' ||
          (action.type == 'update' && action.data.status == '0')
        "
                 @click="handleTypeClick('saveDraft')">{{ $t("detail.s26") }}</el-button>
      <el-button class="handle-button"
                 size="medium"
                 @click.native="hidenView">
        {{ $t("common.btn.cancel") }}
      </el-button>
    </div>
    <get-product :visible.sync="dialogFormVisible"
                 :sceneType='sceneType'
                 @save-success="handleSuccess"></get-product>
  </flexbox>
</template>

<script>
import { mapGetters } from "vuex";
import Lockr from "lockr";
import CreateView from "@c/CreateView";
import CreateSections from "@c/CreateSections";
import { XhFiles } from "@c/CreateCom";
import getProduct from "./components/getProduct";
import {
  selectPartnerPortal,
  channelborrowSave,
  channelborrowUpdate,
} from "@/api/allEquipment";
export default {
  name: "borrowEquipmentApply",
  components: {
    CreateView,
    CreateSections,
    getProduct,
    XhFiles,
  },
  props: {
    action: {
      type: Object,
      default: () => {
        return { type: "save" };
      },
    },
  },
  data () {
    var sysFileEntityListChange = (rule, value, callback) => {
      if (this.crmForm.sysFileEntityList.length == 0 && this.crmForm.depositStatus === '0') {
        callback(new Error("请上传凭证")); // 请上传凭证
      } else {
        callback();
      }
    };

    let vaildNumber = (rule, value, callback) => {
      if (value.toString().length > 32) {
        callback(new Error(this.$t('formVaild.head') + 32 + this.$t('formVaild.tail')));
      } else {
        callback();
      }
    };

    let vaildRecipients = (rule, value, callback) => {
      if (value.toString().length > 100) {
        callback(new Error(this.$t('formVaild.head') + 100 + this.$t('formVaild.tail')));
      } else {
        callback();
      }
    };

    return {
      crmForm: {
        depositStatus: "0",
        applicantName: "",
        applicantId: "",
        filedDate: new Date(),
        channelId: "",
        channelName: "",
        customerId: "",
        customerName: "",
        businessId: "",
        businessName: "",
        businessType: "",
        productEntities: [],
        logisticsEntity: {
          recipientsPostcode: "",
          recipientsAddress: "",
          sendType: "0",
          recipients: "",
          recipientsPhone: "",
          remark: "",
        },
        sysFileEntityList: [],
      },
      rules: {
        businessName: [
          { required: true, message: this.$t("filter.f68"), trigger: "change" },
        ],
        customerName: [
          { required: true, message: this.$t("filter.f68"), trigger: "change" },
        ],
        pursueReason: [
          { required: true, message: this.$t("filter.f68"), trigger: "change" },
        ],
        sysFileEntityList: [
          {
            required: true,
            validator: sysFileEntityListChange,
            trigger: "change",
          },
        ],

        'logisticsEntity.recipientsPostcode': [
          { validator: vaildNumber, trigger: "blur" }
        ],
        'logisticsEntity.recipients': [
          { validator: vaildRecipients, trigger: "blur" }
        ],
        'logisticsEntity.recipientsPhone': [
          { validator: vaildNumber, trigger: "blur" }
        ],
      },
      tableHeight: "500px",
      fileList: [],
      documentsVisible: false,
      documentsImageUrl: "",
      uploadFiles: [],
      dialogFormVisible: false,
      projectNameLoading: false,
      projectNameArr: [],
      token: "",
      saveLoad: false,
      saveDisable: false,
      saveDrafLoad: false,
      saveDrafDisable: false,
      sceneType: "channelApply"
    };
  },
  watch: {
    'crmForm.depositStatus': {
      handler (val) {
        this.rules.sysFileEntityList[0].required = val === '0' ? true : false
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    totalDeposit () {
      if (this.crmForm.productEntities) {
        return this.crmForm.productEntities.reduce(
          (money, item) => (money += (item.itemDeposit || 0) * item.itemNum),
          0
        );
      }
    },
  },
  mounted () {
    this.token = Lockr.get("access_pp_token");
    // JSON.parse(localStorage.getItem("access_pp_token")).data;
    this.resetFormData();
    this.$nextTick(() => {
      this.uploadFiles = this.$refs.upload && this.$refs.upload.uploadFiles;
    });
  },
  methods: {



    hidenView () {
      this.$emit("hide-view");
    },
    resetFormData () {
      if (this.action.type == "save") {
        this.crmForm.channelName = this.userInfo.channelName;
        this.crmForm.channelId = this.userInfo.channelId;
        this.crmForm.applicantName = this.userInfo.name
          ? (this.userInfo.surname || "") + " " + this.userInfo.name
          : "--";
        this.crmForm.applicantId = this.userInfo.userId;
      } else {
        // 续申请或者编辑
        for (const key in this.action.data) {
          const value = this.action.data[key];
          this.$set(this.crmForm, key, value);
        }
        if (this.action.data.status && this.action.data.status != "0") {
          this.crmForm.filedDate = this.action.data.filedDate;
        }
        this.fileList = this.action.data.sysFileEntityList;
      }
    },
    projectNameRemote (queryString) {
      if (queryString !== "") {
        // 项目搜索
        this.projectNameLoading = true;
        selectPartnerPortal({
          serchName: queryString,
          channelId: this.crmForm.channelId,
        })
          .then((res) => {
            this.projectNameArr = res.rows;
            this.projectNameLoading = false;
          })
          .catch(() => {
            this.projectNameLoading = false;
          });
      } else {
        this.projectNameArr = [];
      }
    },
    itemNumChange (val, row) {
      val = val.replace(/[^\d]/g, "").replace(/^0/g, ""); // 清除“数字”以外
      if (val == "" || val == 0) {
        val = 1;
      }else if(val > 9999){
        val = 9999
      }
      row.itemNum = val;
    },
    handleChange (val, type) {
      if (type === "businessName") {
        if (val) {
          let projectInfo = this.projectNameArr.find(
            (e) => e.business_name == val
          );
          this.crmForm.businessId = projectInfo.id;
          this.crmForm.customerName = projectInfo.customer_name;
          this.crmForm.customerId = projectInfo.customer_id;
          this.crmForm.money = projectInfo.money;
          this.crmForm.percent = projectInfo.percent;
          this.crmForm.businessType = projectInfo.businessType;
        } else {
          this.crmForm.businessId = "";
          this.crmForm.customerName = "";
          this.crmForm.customerId = "";
          this.crmForm.money = "";
          this.crmForm.percent = "";
          this.crmForm.businessType = "";
        }
      } else if (type === "sysFileEntityList") {
        this.crmForm.sysFileEntityList = val.value;
        this.$refs.crmForm.validateField("sysFileEntityList");
      }
    },
    submitUpload () {
      this.$refs.upload.submit();
    },
    handlePictureCardPreview (file) {
      this.documentsImageUrl = file.url;
      this.documentsVisible = true;
    },
    handleRemove (file) {
      const index = this.$refs.upload.uploadFiles.findIndex(
        (item) => item.url == file.url
      );
      this.$refs.upload.uploadFiles.splice(index, 1);
    },
    uploadDocumentsClick () {
      this.$refs.upload.$refs["upload-inner"].handleClick();
    },
    beforeUpload (file) { },
    uploadError (err, file, fileList) {
      this.$message({
        type: "error",
        message: this.$t("filter.f100"),
      });
    },
    uploadSuccess (res, file, fileList) {
      this.fileList = fileList;
      this.crmForm.sysFileEntityList = [
        ...this.crmForm.sysFileEntityList,
        ...res.rows,
      ];
    },
    addProduct () {
      this.dialogFormVisible = true;
    },
    deleteProduct (scope) {
      this.$confirm(this.$t('other.6') + '?', this.$t('filter.f13'), {
        confirmButtonText: this.$t('common.btn.sure'),
        cancelButtonText: this.$t('common.btn.cancel'),
        type: "warning",
      })
        .then(() => {
          this.crmForm.productEntities.splice(scope.$index, 1);
        })
        .catch(() => { });
    },
    handleSuccess (data) {
      if (this.crmForm.productEntities.length) {
        data.productSelectEntityList.map((item) => {
          let isHasItem = this.crmForm.productEntities.some(
            (ele) => ele.uniqueId == item.uniqueId
          );
          if (isHasItem) {
            this.crmForm.productEntities.map((ele) => {
              if (ele.uniqueId == item.uniqueId) {
                return (ele.itemNum += item.itemNum);
              }
            });
          } else {
            this.crmForm.productEntities.push(item);
          }
        });
      } else {
        this.crmForm.productEntities = [...data.productSelectEntityList];
      }
    },
    getRequest () {
      if (this.action.type == "save") {
        return channelborrowSave;
      } else if (this.action.type == "update") {
        return channelborrowUpdate;
      }
    },
    handleTypeClick (type) {
      if (!this.crmForm.productEntities.length) {
        this.$notify({
          title: this.$t("filter.f13"),
          message: this.$t("filter.f73"),
          type: "warning",
          duration: 5000,
        });
        return;
      }
      this.$refs.crmForm.validate((valid) => {
        if (valid) {
          let params = _.cloneDeep(this.crmForm);
          params.filedDate = moment(params.filedDate).format();
          params.totalDeposit = this.totalDeposit;
          if (type == "save") {
            params.saveType = "1";
            this.saveLoad = true;
            this.saveDrafDisable = true;
          } else if (type == "saveDraft") {
            params.saveType = "0";
            this.saveDrafLoad = true;
            this.saveDisable = true;
          }
          let request = this.getRequest();
          request(params)
            .then((res) => {
              if(res.code === 500){
                this.$message.error(res.msg);
              }else{
                this.$message.success(this.$t("filter.f11"));
              }
              this.$emit("save-success", {
                type: this.action.type,
                saveType: type,
              });
              this.saveLoad = false;
              this.saveDisable = false;
              this.saveDrafLoad = false;
              this.saveDrafDisable = false;
              this.hidenView();
            })
            .catch((err) => {
              window.console.log("err", err);
              this.saveLoad = false;
              this.saveDisable = false;
              this.saveDrafLoad = false;
              this.saveDrafDisable = false;
            });
        } else {
          this.$nextTick(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: "center",
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: "smooth",
            });
          });
          return;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.crm-create-header {
  height: 40px;
  margin-bottom: 20px;
  padding: 0 10px;
  flex-shrink: 0;
  .close {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: -10px;
    padding: 10px;
    cursor: pointer;
  }
}
.crm-create-container {
  position: relative;
  height: 100%;
}
.crm-create-flex {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}
.handle-bar {
  position: relative;
  margin: 0 auto;
  .handle-button {
    margin-top: 5px;
    margin-right: 10px;
  }
}
.el-form-item {
  margin-bottom: 15px;
}
.upload-documents {
  width: 70%;
  height: 160px;
  background: #ffffff;
  border: 2px dashed #a1a4b4;
  opacity: 1;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  &:hover {
    border-color: #409eff;
  }
  .default {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .el-icon-upload {
      font-size: 67px;
      color: #c0c4cc;
      line-height: 50px;
    }
  }
  .upload {
    height: 100%;
    overflow-x: auto;
    /deep/ .el-upload-list {
      display: flex;
      flex-wrap: nowrap;
      width: fit-content;
      height: 100%;
      .el-upload-list__item {
        margin: 20px 10px;
        width: 110px;
        height: 110px;
      }
    }
    /deep/ .el-upload {
      display: none;
    }
    /deep/ .el-progress-circle {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
.productInfo {
  .el-form-item {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 0;
    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }
    &.is-error {
      margin-bottom: 20px;
    }
  }
}
.add-product {
  width: 100%;
  height: 54px;
  border: 1px solid #ebeef5;
  border-top: none;
  text-align: center;
  .el-button {
    color: #556ee6;
    height: 54px;
    /deep/ .el-icon-plus {
      padding: 1px;
      border: 1px solid #556ee6;
      font-size: 12px;
      font-weight: 700;
      vertical-align: middle;
      color: #556ee6;
    }
    /deep/ span {
      vertical-align: middle;
    }
  }
}
</style>