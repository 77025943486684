import store from '@/store'
import Lockr from 'lockr'
import axios from './request'

const cache = {
  /**
   * 载入全部登陆信息
   */
  loadingCache: function () {
    if (Lockr.get('access_pp_token') && !axios.defaults.headers['Authorization']) {
      /** 将用户信息放入缓存 */
      const userInfo = Lockr.get('loginUserInfo')
      if (userInfo) {
        store.commit('SET_USERINFO', userInfo)
      }
    }
  },
  /**
   * 请求和更新登录缓存
   */
  updateAxiosCache: function () {
    axios.defaults.headers['Authorization'] = "Bearer " + Lockr.get('access_pp_token')
    // store.dispatch('GetUserInfo')
  },
  /**
   * 移除登录信息
   * @param {*}
   */
  rmAxiosCache: function () {
    Lockr.rm('access_pp_token')
  }
}

export default cache
