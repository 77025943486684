import request from '@/utils/request'

// 所有设备列表
export function getProjectList (data) {
  return request({
    url: '/sf-partnerportal/pp/project/list',
    method: 'post',
    data: data
  })
}

// 获取项目详情
export function getProjectInfo (data) {
  return request({
    url: '/sf-partnerportal/pp/project/info',
    method: 'post',
    data: data
  })
}
// 获取项目报备审批详情
export function getProjectdetail (data) {
  return request({
    url: '/sf-partnerportal/pp/project/approvalInfo',
    method: 'post',
    data: data
  })
}
// 获取项目续报备审批详情
export function getEpaProjectdetail (data) {
  return request({
    url: '/sf-partnerportal/pp/project/approvalInfoEPA',
    method: 'post',
    data: data
  })
}
// 获取渠道国家信息
export function getByChannleCountry (data) {
  return request({
    url: '/sf-partnerportal/pp/project/getByChannleCountry',
    method: 'post',
    data: data
  })
}

// 获取设备授权日志
export function getAuthlogsInfo (data) {
  return request({
    url: '/sf-partnerportal/pp/authlogs/project/info',
    method: 'post',
    data: data
  })
}

// 新增设备
export function newProject (data) {
  return request({
    url: '/sf-partnerportal/pp/project/application/save',
    method: 'post',
    data: data
  })
}

// 续报备
export function extendApplicationProject (data) {
  return request({
    url: '/sf-partnerportal/pp/project/extendApplication',
    method: 'post',
    data: data
  })
}

// 修改项目信息
export function updateProject (data) {
  return request({
    url: '/sf-partnerportal/pp/project/update',
    method: 'post',
    data: data
  })
}
//项目报备审批详情编辑
export function ppupdateProject (data) {
  return request({
    url: '/sf-partnerportal/pp/project/PA/approveUpdate',
    method: 'post',
    data: data
  })
}
//项目报备-续报备记录
export function projectlogsList (data) {
  return request({
    url: '/sf-partnerportal/pp/projectlogs/list',
    method: 'post',
    data: data
  })
}

// 模糊搜索客戶名字
export function getByProjectName (data) {
  return request({
    url: '/sf-partnerportal/pp/project/getByProjectName',
    method: 'post',
    data: data
  })
}

// 获取客户详情
export function getCustomerInfo (data) {
  return request({
    url: '/sf-partnerportal/pp/project/getCustomerInfo',
    method: 'post',
    data: data
  })
}

// 获取产品集
export function getCategoryList (data) {
  return request({
    url: '/api-gsm/crm/category/interested/list',
    method: 'get',
    data: data
  })
}

// 获取产品集（三级）
export function getNewCategoryList (data) {
  return request({
    url: '/api-gsm/crm/category/productLine/list',
    method: 'post',
    data: data
  })
}

// 获取渠道信息
export function getByChannle (data) {
  return request({
    url: '/sf-partnerportal/pp/project/getByChannle',
    method: 'post',
    data: data
  })
}


//搜索总代或vad
export function getDistribute (data) {
  return request({
    url: '/sf-partnerportal/pp/project/getByChannleClass',
    method: 'post',
    data: data
  })
}
