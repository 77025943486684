<template>
  <div class="registrationHistory">
    <el-timeline>
      <el-timeline-item :hide-timestamp="true"
                        color="#556EE6">
        <div class="doing">
          {{
            info && info.createdDate
              ? moment(info.createdDate).format("YYYY-MM-DD")
              : "--"
          }}
        </div>
      </el-timeline-item>
      <el-timeline-item :hide-timestamp="true"
                        color="#556EE6">
        <div class="title">
          <a>To be re-registed</a><span>Approved Date:
            {{
              tableData[0] && tableData[0].createdDate
                ? moment(tableData[0].createdDate).format("YYYY-MM-DD")
                : "--"
            }}</span>
        </div>
        <el-table :data="tableData"
                  border
                  stripe
                  style="width: 100%">
          <el-table-column sortable
                           label="Date">
            <template slot-scope="scope">
              <span>{{
                scope.row && scope.row.createdDate
                  ? moment(scope.row.createdDate).format("YYYY-MM-DD")
                  : "--"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="updateColumn"
                           :label="$t('allEquipment.fields')">
          </el-table-column>
          <el-table-column :label="$t('allEquipment.oldValue')">
            <template slot-scope="scope">
              <span v-if="scope.row && scope.row.updateColumn == 'Expected PO Date'">
                {{
                  scope.row && scope.row.afterValue
                    ? moment(scope.row.afterValue).format("YYYY-MM-DD")
                    : "--"
                }}
              </span>
              <span v-else-if="scope.row && scope.row.updateColumn == 'Stage'">
                {{ findDIctName("PpProjectStage", scope.row.afterValue) }}
              </span>
              <span v-else>
                {{ scope.row.afterValue }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('allEquipment.newValue')">
            <template slot-scope="scope">
              <span v-if="scope.row && scope.row.updateColumn == 'Expected PO Date'">
                {{
                  scope.row && scope.row.updateValue
                    ? moment(scope.row.updateValue).format("YYYY-MM-DD")
                    : "--"
                }}
              </span>
              <span v-else-if="scope.row && scope.row.updateColumn == 'Stage'">
                {{ findDIctName("PpProjectStage", scope.row.updateValue) }}
              </span>
              <span v-else>
                {{ scope.row.updateValue }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-timeline-item>
      <el-timeline-item :hide-timestamp="true"
                        color="#556EE6">
        <div class="title">
          <a>Re-registration</a><span>Approved
            {{
              afterTableData[0] && afterTableData[0].createdDate
                ? moment(afterTableData[0].createdDate).format("YYYY-MM-DD")
                : "--"
            }}</span>
        </div>
        <p>Reason:</p>
        <el-table :data="afterTableData"
                  border
                  stripe
                  style="width: 100%">
          <el-table-column sortable
                           label="Date">
            <template slot-scope="scope">
              <span>{{
                scope.row.createdDate
                  ? moment(scope.row.createdDate).format("YYYY-MM-DD")
                  : "--"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="updateColumn"
                           :label="$t('allEquipment.fields')">
          </el-table-column>
          <el-table-column :label="$t('allEquipment.oldValue')">
            <template slot-scope="scope">
              <span v-if="scope.row && scope.row.updateColumn.includes('Expected')">
                {{ moment(scope.row.afterValue).format("YYYY-MM-DD") }}
              </span>
              <span v-else-if="scope.row.updateColumn == 'Stage'">
                {{ findDIctName("PpProjectStage", scope.row.afterValue) }}
              </span>
              <span v-else>
                {{ scope.row.afterValue }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('allEquipment.newValue')">
            <template slot-scope="scope">
              <span v-if="scope.row && scope.row.updateColumn == 'Expected PO Date'">
                {{ moment(scope.row.updateValue).format("YYYY-MM-DD") }}
              </span>
              <span v-else-if="scope.row && scope.row.updateColumn == 'Stage'">
                {{ findDIctName("PpProjectStage", scope.row.updateValue) }}
              </span>
              <span v-else>
                {{ scope.row.updateValue }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-timeline-item>
      <el-timeline-item :hide-timestamp="true"
                        color="#556EE6">
        <div class="title">
          <a>Project Registration</a><span>{{
            info && info.createdDate
              ? moment(info.createdDate).format("YYYY-MM-DD")
              : "--"
          }}</span>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { projectlogsList } from "@/api/allProject";
import { findDIctName } from "@/utils";
import moment from "moment";
export default {
  name: "registrationHistory",
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          type: "save",
        };
      },
    },
  },
  data () {
    return {
      tableData: [],
      afterTableData: [],
      params: {},
    };
  },
  methods: {
    getprojectlogsList () {
      let params = {
        projectId: this.info.businessId,
        pageSize: 10,
        pageNumber: 1,
      };
      projectlogsList(params).then((res) => {
        res.rows.rows.sort(function (a, b) {
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
        let tableArr = [];
        if (res.rows.rows[0]) {
          tableArr.push(res.rows.rows[0]);
        }
        this.tableData = tableArr;
        this.afterTableData = res.rows.rows.splice(1) || [];
        // console.log(this.afterTableData[0].updateColumn.substring(0, 8));
      });
    },
  },
  created () {
    console.log(this.info);
    this.getprojectlogsList();
  },
  computed: {
    moment () {
      return moment;
    },
    findDIctName () {
      return findDIctName;
    },
  },
};
</script>

<style lang="less" scoped>
.registrationHistory {
  padding: 20px;

  .doing {
    width: 107px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-family: Alibaba PuHuiTi;
    color: #ffffff;
    text-align: center;
    background: #556ee6;
    border-radius: 4px;
  }

  .title {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 20px;

    & > a {
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      color: #343a40;
    }

    & > span {
      font-size: 14px;
      color: #a1a4b4;
    }
  }

  p {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 20px;
    color: #343a40;
  }
}
</style>