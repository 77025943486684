module.exports = {
  login: {
    userId: '账号登录',
    username: '账号',
    usernamePlaceholder: '请输入账号',
    password: '密码',
    passwordPlaceholder: '请输入密码',
    validCode: '验证码',
    validCodePlaceholder: '请输入验证码',
    signIn: '登录'
  },
  common: {
    btn: {
      edit: '编辑',
      update: '更新',
      delete: '删除',
      more: '更多',
      editFiles: '编辑附件',
      addFiles: '添加附件',
      detail: '详情',
      // new
      sure: '确定',
      cancel: '取消',
      save: '保存',
      down: '下载',
      editFollow: '编辑跟进',
      delFollow: '删除跟进',
      handle: '操作',
      reset: '重置',
      won: "赢单",
      lost: '输单',
      projectWon: '该项目已赢单',
      porjectLost: '该项目已输单',
      inProgress: '项目进行中',
      overdue: '逾期'
    }
  },
  PersonCenter: {
    1: '返回',
    2: '部门：',
    3: '职位',
    4: '基本信息',
    5: '男',
    6: '女',
    7: '姓名',
    8: '工号',
    9: '邮箱',
    10: '部门',
    11: '职位',
    12: '电话'
  },
  sceneCreate: {
    1: '场景名称',
    2: '筛选条件中有重复项！',
    3: '添加筛选条件',
    4: '设置为默认',
    5: '取 消',
    6: '确 定',
    7: '编辑场景',
    8: '请输入场景名称，最多30个字符',
    9: '请选择要筛选的字段名',
    10: '请选择范围',
    11: '请选择筛选条件',
    12: '请输入筛选条件',
    14: '等于',
    15: '不等于',
    16: '包含',
    17: '不包含',
    18: '开始时间',
    19: '结束时间',
    27: '场景名称不能为空！',
    28: '要筛选的字段名称不能为空！',
    29: '请输入筛选条件的值！',
    30: '编辑成功',
    31: '创建成功',
    32: '您确定要删除这一条数据吗?',
    33: '不能为空'
  },
  sceneSet: {
    1: '您可通过拖拽管理标签',
    2: '显示的标签',
    3: '设置为默认标签',
    4: '隐藏的标签',
    5: '取消',
    6: '保存',
    7: '场景管理',
    8: '操作成功',
    9: '删除成功',
    10: '删除设备申请审批失败',
    11: '删除草稿失败'
  },
  filter: {
    scene: '场景',
    advancedFilter: '高级筛选',
    addFilter: '添加筛选条件',
    saveScene: '保存为场景',
    addScene: '新建场景',
    manage: '管理',
    condition: '筛选条件',
    f11: '操作成功',
    f13: '提示',
    f68: '不能为空',
    f69: '请输入数字',
    f73: '请选择产品',
    f79: '审批中',
    f80: '已完成',
    f81: '驳回',
    f100: '上传失败'
  },
  detail: {
    customerName: '客户名称',
    projectName: '项目名称',
    remark: '备注',
    productName: '产品名称',
    productType: '产品类型',
    accountCustomer: '关联客户',
    associatedProject: '关联项目',
    projectInfo: '产品信息',
    basicInfo: '基本信息',
    addProduct: '添加产品',
    files: '相关附件',
    requirement: "需求和应用场景",
    scene: '场景',
    d12: '状态',
    d21: '数量',
    s8: '产品型号',
    s10: '产品说明',
    s13: '单位',
    s25: '提交审批',
    s26: '保存草稿',
    productText: '该产品未启用，如有需要请联系销售助理。',
  },
  components: {
    c1: '附件管理',
    c2: '文件名',
    c3: '上传人',
    c4: '上传时间',
    c5: '操作',
    c6: '下载',
    c7: '删除',
    c8: '将文件拖到此处，或',
    c9: '点击上传',
    c10: '编辑附件',
    c11: '支持格式：.rar .zip .doc .docx .pdf ，单个文件不能超过20MB',
    c12: '附件名称',
    c13: '操作时间',
    c14: '操作人员',
    c15: '操作内容'
  },
  task: {
    16: '选择日期',
    44: '暂无数据',
    57: '提示'
  },
  approval: {
    a1: '审批单号',
    a2: '名称',
    a3: '状态',
    a4: '申请人',
    a5: '申请时间',
    a6: '当前审批人',
    a7: '当前审批环节',
    a8: '请输入审批单号',
    a9: '开始日期',
    a10: '结束日期',
    a11: '请输入申请人',
    a12: '审批',
    a13: '申请理由',
    a14: '审批动态',
    a15: '通过',
    a16: '驳回',
    a17: '编辑',
    a18: '驳回至发起人',
    a19: '审批类型',
    a20: '审批意见',
    a21: '审批成功',
    a22: '驳回成功',
    a23: '删除价格审批',
    a24: '废弃理由',
    a25: '废弃成功',
    a26: '转他人审批'
  },
  allEquipment: {
    message:'项目信息未变更，无需续项目报备',
    isRenew: '是否续费项目',
    reseller: '下游渠道',
    distribute: '总代',
    customer: '客户名称',
    expectedDate: '预计签单日期',
    customerBudget: '客户预算(美元)',
    industry: '行业',
    countryRegion: '国家/地区',
    applicant: '申请人',
    address: '地址',
    mobile: "电话",
    email: "邮箱",
    sangforOffice: '部门',
    projectName: "项目名称",
    contactPerson: '联系人姓名',
    position: '职位',
    telFax: "传真",
    reason: '原因',
    productLine: "产品线",
    specialSetting: "特殊需求/设置",
    action: '操作',
    addProduct: "添加产品线",
    header: {
      mainTitle: '新增',
      eidtTitle: '编辑',
      NO: '序号'
    },
    sn: 'SN码',
    status: '当前状态',
    manageQuota: '管理配额',
    quota: '配额',
    consumedQuota: '已使用配额',
    totalEquipments: '可用设备总数',
    rentedEquipments: '已外借设备数',
    availableEquipments: '空闲设备数',
    toBeReturnCM: '本月待还设备数',
    overdue: '超期未还设备数',
    allEquipments: '所有设备',
    allAccessories: '所有配件',
    equipmentName: '设备名称',
    unitQuota: '单件配额($)',
    channelsCurrentlyUsed: '当前使用渠道',
    expectedReturnDate: '预计归还日期',
    accessoryName: '配件名称',
    totalInventory: '总库存',
    noOfRented: '已外借数量',
    applyEquipment: '申请设备',
    quotaInformation: '配额信息',
    availableQuota: '当前可用配额',
    consumedQuotaOfApplication: '本次申请消耗配额',
    remainingQuota: '本次申请后剩余配额',
    logisticsInformaion: '物流信息',
    postcode: '邮编',
    shippingAddress: '收货地址',
    deliveryMethod: '发货方式',
    normalMode: '正常发货',
    sfExpress: '顺丰发货',
    sfOther: '（顺非费用高，非紧急情况请勿使用，深信服不承担费用）',
    selfPickUp: "自提",
    recipient: '收件人',
    product: '产品',
    accessory: '配件',
    productType: '类型',
    courierCompany: '快递公司',
    shipmentNumber: '物流单号',
    day: '天',
    firstAuthorizationPeriod: '首次授权期限',
    renewalPeriod: '续授权期限',
    scrapReason: '报废原因',
    totalQuantity: '总数量',
    availableQuantity: '空闲数量',
    deposit: '押金',
    confirm: '确认',
    unconfirmed: '未确认',
    allConfirmed: '全部确认',
    modify: '修改',
    packageStatus: '批次状态',
    scrap: '报废',
    packageOwner: '批次负责人',
    depositStatus: '押金状态',
    equipmentOwner: '设备负责人',
    release: '释放',
    rentEquipment: '借测试设备',
    lockingReason: '手动锁定原因',
    rentHistory: '借用记录',
    rentalStartDate: '借用开始日期',
    rentalEndDate: '借用截止日期',
    rentalChannel: '借用渠道',
    authorizationStartDate: '授权开始日期',
    authorizationEndDate: '授权截止日期',
    authorizationChannel: '授权渠道',
    salesRep: '关联负责人',
    projectStatus: '项目状态',
    projectAmount: '项目金额',
    noOfAuthorization: '授权次数',
    licenseFile: '授权文件',
    view: '查看',
    authorizationHistory: '授权记录',
    channel: '渠道商',
    rentalPeriod: '借用期限',
    projectInformation: '项目信息',
    projectStage: '项目所属阶段',
    financialInformation: '财务信息',
    paid: '已交',
    noDepositRequired: '无需押金',
    toBeRefuned: '待退还',
    totalDeposit: '总押金',
    allocateEquipment: '设备分配',
    EPstatus: '设备/配件状态',
    authorizationStatus: '授权状态',
    unauthorizated: '未授权',
    authorized: '已授权',
    authorizateInProgress: '授权申请中',
    reAuthorize: '续授权',
    authorize: '设备授权',
    inventoryStatus: '库存状态',
    shortage: '库存不足',
    adequate: '有库存',
    equipmentType: '设备类型',
    equipmentApply: '设备使用申请',
    applyReason: '申请原因',
    applyLicense: '设备授权申请',
    itemId: '网关id',
    renewLicense: '设备续授权申请',
    previousAuthorizations: '以往授权次数',
    confirmReturn: '确认归还',
    equipmentReturn: '设备归还',
    requiredDeposit: '所需押金',
    requiredRefundAmount: '需退押金',
    actualRefundAmount: '实际退还押金',
    applyDepositRefund: '退押金申请',
    borrowType: '借测类型',
    noDepositRequired: '不支付押金',
    depositRequired: '支付押金',
    uploadVoucher: '上传凭证',
    projectRegistration: "项目报备",
    partner: "申请方",
    fields: '更新的字段',
    oldValue: '更新前的值',
    newValue: '更新后的值',
    channelProjectOwner: '渠道项目负责人',
    channelProjectOwnerEmail: '渠道项目负责人邮箱',
    resellerEmail: '下游渠道邮箱',
    distributeEmail: '总代邮箱',
    depositVoucher: "押金凭证"
  },
  placeholder: {
    sceneOne: '请选择要筛选的字段',
    sceneTwo: '请输入筛选条件',
    select: '请选择',
    input: '请输入',
    search: '输入关键字,远程搜索',
    selectDate: '请选择日期'
  },
  other: {
    1: '提示',
    2: '退出登录',
    3: '退出登录？',
    4: '确 定',
    5: '取 消',
    6: '确认删除',
    7: "个人中心",
    13: '确定要删除这些数据吗?',
    52: '请选择或输入关键字搜索',
    76: '联系电话',
    102: '请输入package编码进行搜索',
    103: '请输入SN码、设备型号进行搜索',
    105: '服务器内部错误，请联系管理员',
    106: '你已被登出，请重新登录。',
    107: '重新登录',
  },
  caselist: {
    porduct: '产 品',
    status: '状 态',
    issustitle: '工单号/问题标题/问题描述',
    // issusdescription:'问题描述',
    startdate: '开始时间',
    enddate: '结束时间',
    search: '搜 索',
    reset: '重 置',
    create: '创 建',
    searchUser: "姓名、邮箱、电话",
    detail: '详情',
    resetPassword: '重置密码',
    firstName: '名',
    lastName: '姓',
    Email: '邮箱',
    Phone: '电话',
    Role: '角色',
    Status: '状态',
    newPassword: '新密码',
    confirmPassword: '确认密码',
    Cancel: '取消',
    Submit: '提交',
    checkPassword: "请再次输入密码",
    checkPassword2: "请输入密码",
    checkPassword3: "请检查两次密码是否相同？",
    name: '姓名',
    operation: '操作',
    registrationDate: '注册时间',
    userDetails: '用户详情',
    snid: 'SN/Gateway ID/Key ID',
  },
  createcase: {
    createcase: '创建工单',
    companyname: '公司名称',
    companynameplaceholder: '请输入公司名称。',
    checkcompanyname: '请输入公司名称。',
    customer: '客户名称',
    customerplaceholder: '请输入客户名称',
    region: '区域',
    regionplaceholder: '请输入区域。',
    checkregion: '请输入区域。',
    product: '产品',
    productplaceholder: '请选择产品。',
    checkproduct: '请选择产品。',
    version: '版本号',
    versionplaceholder: '请选择版本号。',
    checkversion: '请选择版本号。',
    gatewayid: '设备ID/软件ID',
    gatewayplaceholder: '请输入设备ID/软件ID。',
    checkgateway: '请输入正确的设备ID/软件ID（ 8/10/11/16/40）。',
    sn: 'SN',
    snPlaceholder: '请输入SN。',
    snCorrectPlaceholder: '请输入正确的SN.',
    checksn: '请输入正确的SN（ 8/10/11/16/40）',
    poc: 'POC',
    checkpoc: 'Please select POC',
    issuetitle: '问题标题',
    issusplaceholder: '请输入问题标题。',
    checkissustitle: '请输入问题标题。',
    issuedescriptions: '问题描述',
    descriptionsplaceholder: '请输入问题描述。',
    checkissuedescriptions: '请输入问题描述。',
    checkissuedescriptions2: '问题描述不能超过4000个字符。',
    checkissuedescriptions3: '问题标题不能超过255字符',
    enter: '进入',
    article: '相关文章',
    moreraticles: '更多文章',
    contact: '联系人',
    contactplaceholder: '请输入联系人。',
    checkcontact: '请输入联系人。',
    tel: '电话',
    telplaceholder: '请输入电话号码。',
    checktel: '请输入电话号码。',
    checktel2: "请输入正确的电话号码",
    email: '邮箱',
    emailplaceholder: '请输入邮箱。',
    checkemail: '请输入正确的邮箱格式。',
    ccemail: "抄送邮箱",
    timezone: '时 区',
    zoneplaceholder: '请选择时区。',
    checkzone: '请选择时区。',
    dete: '日期时间',
    dateplaceholder: '请选择日期和时间。',
    checkdate: '请选择日期和时间。',
    cancel: '取 消',
    submit: '提 交',
    contactadmin: '会及时处理该问题。',
    uploadtext1: '将文件拖到此处，或',
    uploadtext2: '点击上传',
    uploadtext3: '只能上传 cxg,jpg,gif,bmp,tiff,png,jpeg,rm,mvb,avi,asf,wmv,mov,mpg,mpeg,vob,fiv,mp4,zip,rar,gz,bz2,tgz,cab,7z,bz,Z,doc，docx,xls,xlsx,pdf,txt文件，并且不超过10m',
    islt10M: '文件最大不能超过10M',
    Preferredcontactway: '期望联系方式',
    Preferredcontactway2: '请选择期望的联系方式',
    caseNo: '工单号',
    issueSeverity: '问题严重性',
    status: '状态',
    uploadFiles: '上传附件',
    submitter: "提交人",
    updateTime: '更新时间',
    createdTime: '创建时间',
    escalationTime: "升级时间",
    closedTime: "关单时间",
    dailyContent: "日报内容",
    handler: '处理人',
    operation: '操作',
    date: '日期时间',
    from: '发件人',
    to: "收件人",
    cc: "抄送",
    Attachments: '附件',
    emailTitle: '邮箱标题',
    emailContent: '邮箱内容',
    Point: '分',
    veryDissatisfied: '非常不满意',
    verySatisfed: '非常满意',
    PleaseRateUs: '请进行评价',
    ReasonsOfLow: '低分原因（最多可选择三个）',
    Comment: '评论',
    ishelpful: '对您未来的设备维护是否有帮助？',
    yes: '是',
    no: '否',
    dailySummariesDetail: '日报详情',
    emailDetail: '邮件详情',
    escalationReasons: '升级原因',
    detailReason: '详情描述',
    title: '标题',
    description: '描述',
    Severity: "严重性",
    Evaluation: '评价',
    checkCountryCode: '请选择国家/地域区号.'
  },
  casetitle: {
    1: '你好，有什么可以帮助您的？',
    2: '您的产品是哪些？',
    3: '您的问题是什么？',
    4: '这个问题对您的业务的影响是怎样的？',
    5: '如何联系您？',
    6: '什么时间联系您比较方便？',
  },
  case: {
    back: '返 回',
    search: '搜 索',
    product: '产 品',
    status: '状 态',
    refesh: '刷 新',
    export: '导 出',
    search: '刷 新',
    delete: '删 除',
    cancel: '取 消',
    save: '保 存',
    submit: '提 交',
    create: '创 建',
    more: '更 多',
    article: '相关文章',
    upgrade: '升级工单',
    createcase: '新建工单',
    records: '处理中心',
    daily: '日报记录',
    evaluation: '评 价',
    rate: '未评价',
    rated: '已评价',
    updateTime: '更新时间',
    closedTime: '关闭时间',
    escalationTime: '升级时间',
    severity: '问题严重性',
    submitter: '提交人',
    orderdetail: '工单详情',
    productDetail: '产品详情',
    contactDetail: '联系信息',
    othersDetail: '其他信息',
    preferredDate: '首选日期和时间',
    uploadFiles: '上传附件',
    escalate: '升级',
    escalated: '已升级',
    hasEscalated: '该工单已升级过',
    noData: '没有数据',
    fileTips: '具体附件请查看您原邮件',
    assigning: '分配角色',
    uploadtext1: '将文件拖到此处，或',
    uploadtext2: '点击上传',
    uploadtext3: '只能上传 cxg,jpg,gif,bmp,tiff,png,jpeg,rm,mvb,avi,asf,wmv,mov,mpg,mpeg,vob,fiv,mp4,zip,rar,gz,bz2,tgz,cab,7z,bz,Z,doc，docx,xls,xlsx,pdf,txt文件，并且不超过10m',
    dateplaceholder: '请选择日期和时间',
    gateplaceholder: '请输入 Gateway ID.',
    snplaceholder: '请输入 sn.',
    productplaceholder: '请选择产品线',
    versionplaceholder: '请选择版本号',
    statusplaceholder: '请选择状态',
    pocplaceholder: '请选择POC',
    issusplaceholder: '请输入问题标题',
    descriptionsplaceholder: '请输入问题内容',
    telplaceholder: '请输入电话号码',
    zoneplaceholder: '请选择时区',
    contactplaceholder: '请输入联系人',
    aoreraticles: '前往知识库',
    keyword: '问题内容',
    emailplaceholder: '请输入正确的邮箱格式',
    emailplaceholder2: '请输入邮箱',
    phoneplaceholder: '请输入电话号码',
    keyword: '问题内容',
    contactadmin: '请联系TAC(+6012 711 7129 (7511)以确保问题更快解决',
    detailReason: '请输入详细原因',
    detailReason2: '详细原因不能超过1000字符',
    upgradeReason: "请选择升级原因",
    lastName: '请输入姓',
    lastName2: '姓不能超过50个字符',
    firstName: '名不能超过50个字符',
    role: '请选择角色',
    serviceProviderNotice: '服务商通知',
    confirmCancel: "确认取消吗？",
    confirmSubmit: "确认提交吗？",
    notice: '提示',
    confirm: '确认',
    escalated: '已升级',
    escalatedinfo: '该工单已升级过',
    escalate: '升级工单',
    viewEmail: '具体附件请查看您原邮件。',
    findGateway: '如何找到设备ID/软件ID？',
    upgradeErro: '工单升级失败，请在两分钟后再试',
  },
  productSelection: {
    projectName: '项目名称',
    Throughput: 'Throughput(Mbps)',
    IPsecThroughput: 'IPsec Throughput(Mbps)',
    SelectTheScenario: '请选择核心场景',
    Consideration: '您是否需要以下功能？',
    ThroughputRequired: '请输入所需支持的带宽',
    Models: '系统建议的型号',
    notice: '注：请先填写项目名称，再进行产品选型',
    CompetingGoodsCompany: '竞品公司',
    CompetingGoodsModel: '竞品型号',
    configuration: '产品配置',
    ProductModel: '产品型号',
    ProductList: '产品列表',
    EquipmentSets: '设备台数',
    productName: '产品名称',
    productType: '产品类型',
    productDescription: '产品说明',
    PurchaseQuantity: '购买数量',
    TaketheNumber: '自带数量',
    ProductDetails: '产品详情',
    ReplaceTheProject: '更换项目',
    SelectionOfRecord: '选型记录',
    beenDownloaded: '已下载BOM表',
    SaveSelectionResults: '保存选型结果',
    number: '序号',
    SpecialEffects: '关键特性',
    PerformanceParameters: '性能参数',
    HardwareParameters: '硬件参数',
    PreviewFormat: 'BOM表格式预览',
    BOMPreview: 'BOM预览',
    PreviewNotice: 'BOM下载后格式如下图所示 ，下载后请核对具体信息是否有误。',
    download: '确定下载',
    prompt: '不再提示',
    save: '保存',
    saveAdd: '保存并新增',
    productSelection: '产品选型',
    Cart: '购物车',
    submit: '提交',
    downloadBOM: '下载BOM表',
    deleteAll: '全部删除',
    selectAll: '全选',
    Forward: '创建时间',
    Reverse: '创建时间',
    ExpandAll: '全部展开',
    Customization: '产品配置',
    OtherBOMPreview: 'BOM表预览',
    deleteProject: '删除项目',
    Edit: '配置',
    Downloadkit: '产品高清图片下载',
    AddtoCart: '加入购物车',
    notAllowed: '不能为空',
    notAllowedMsg: '参数必须为大于0的整数且须少于12位',
    configureAllowedMsg: '参数必须为正整数',
    downloadTime: '最新下载时间',
    inputText: '请输入项目名称',
    nodata: '暂无数据',
    enterSuccess: '加入购物车成功!',
    Performance: '基于场景&性能选型',
    Competitor: '基于竞争对手选型',
    confirmMsg: '您尚未保存选型结果，当前操作将清空购物车，是否确认?',
    prompt: '不再提示',
    saveSuccess: '保存成功!',
    delete: '删除成功!',
    SelectResult: '请选择最符合您需求的场景，这将会影响选型结果',
    clickTitle: '点击进入详情',
    noZip: '该产品无高清照片进行下载',
    deleteProjectTips: '仅可删除项目',
    showNoneTips: '未匹配到详细设备，如有需要，请联系本地办事处!',
    promptTips: '提示',
    None: '暂无数据'
  },
  formVaild: {
    head: '不能超过',
    tail: '个字符'
  }
}
