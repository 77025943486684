<template>
  <el-container>
    <el-aside width="auto" class="aside-container">
      <div class="sidebar-container" @click="toggleSideBarClick">
        <i
          :class="collapse ? 'el-icon-extendshouqi1' : 'el-icon-extendzhankai'"
          class="collapse-button"
        />
      </div>
      <el-menu
        :default-active="activeIndex"
        :style="{
          'border-right-color': backgroundColor,
          'padding-top': '40px'
        }"
        :text-color="textColor"
        :background-color="backgroundColor"
        :active-text-color="activeTextColor"
        :collapse="collapse"
        class="el-menu-vertical"
      >
        <template v-for="(item, index) in this.crmMenu">
          <router-link v-if="!item.children" :key="index" :to="item.fullPath">
            <el-menu-item
              :index="item.fullPath"
              :class="{ 'menu-item-select': activeIndex == item.fullPath }"
              class="menu-item-defalt"
            >
              <i
                :class="'el-icon-' + item.icon"
                :style="{
                  color:
                    activeIndex == item.fullPath ? activeTextColor : textColor,
                  fontSize: item.fontSize || '16px'
                }"
                class="el-icon"
              />
              <span slot="title" class="menuTitle">{{ item.title }}</span>
            </el-menu-item>
          </router-link>
          <el-submenu v-else :key="index" :index="item.fullPath">
            <template v-if="!item.hidden" slot="title">
              <i
                :class="'el-icon-' + item.icon"
                :style="{ fontSize: item.fontSize || '16px' }"
                class="el-icon menuIcon"
              />
              <span slot="title" class="menuTitle">{{ item.title }}</span>
            </template>
            <router-link
              v-for="(subitem, subindex) in getShowMenu(item.children)"
              :key="subindex"
              :to="subitem.fullPath"
            >
              <el-menu-item
                :index="subitem.fullPath"
                :class="{ 'menu-item-select': activeIndex == subitem.fullPath }"
                class="menu-item-defalt"
              >
                <i
                  :class="'el-icon-' + subitem.icon"
                  :style="{ fontSize: subitem.fontSize || '16px' }"
                  class="el-icon menuIcon"
                />
                <span slot="title" class="menuTitle">{{ subitem.title }}</span>
                <el-badge
                  v-if="
                    $store.state.user.countMyTask > 0 &&
                      subitem.path == 'needMyApproval'
                  "
                  :max="99"
                  :value="$store.getters.taskCount"
                />
              </el-menu-item>
            </router-link>
          </el-submenu>
        </template>
        <!-- <el-submenu index="1">
          <template slot="title">
            <span>项目管理</span>
          </template>
          <el-menu-item
            index="/project"
            :class="{'menu-item-select': activeIndex == '/project' }"
            route="/project"
          >所有项目</el-menu-item>
        </el-submenu>-->
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="navbar">
        <div class="navbar-left">
          <img
            :src="navlogo"
            @click="goHome"
            style="cursor: pointer"
            class="logo"
          />
        </div>
        <div class="navbar-right">
          <el-dropdown
            trigger="click"
            @visible-change="(val) => (userShow = val)"
            @command="handleCommand"
          >
            <div class="user-container">
              <!-- <el-avatar
                :size="36"
                :src="circleUrl"
                style="margin-right: 8px"
              ></el-avatar> -->
              {{ userInfo.surname || '' }} {{ userInfo.name }}
              <i
                :class="userShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                class="mark"
              />
            </div>
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item command="user" icon="el-icon-extendziliao">
                {{ $t('other.7') }}
              </el-dropdown-item>
              <!-- <el-dropdown-item command="edit" icon="el-icon-extendlock"
                >修改密码</el-dropdown-item
              > -->
              <!-- <el-dropdown-item
                command="loginLog"
                icon="el-icon-extendlishijilu_huaban"
                class="loginLog"
                >登录记录</el-dropdown-item
              > -->
              <el-dropdown-item command="loginOut" icon="el-icon-extendtuichu">
                {{ $t('other.2') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main
        style="height: 100%; padding: 0; margin: 15px; position: relative"
      >
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { logout } from '@/api/login'
import Lockr from 'lockr'
import axios from '@/utils/request'
import { removeAuth } from '@/utils/auth'
export default {
  data() {
    return {
      backgroundColor: '#2D3037',
      textColor: '#bebec0',
      activeTextColor: '#fff',
      collapse: false, // 菜单开关
      buttonNameCollapse: false,
      userShow: false, // 用户下拉展开
      circleUrl: '',
      navlogo: require('@/assets/img/sangForLogo.png')
    }
  },
  computed: {
    ...mapGetters(['activeIndex', 'crmMenu', 'activeName', 'userInfo'])
  },
  watch: {
    collapse: function(val) {
      if (val) {
        this.buttonNameCollapse = val
      } else {
        setTimeout(() => {
          this.buttonNameCollapse = val
        }, 300)
      }
    },
    $route(to, from) {
      console.log(to)
      if (to.path == '/approvalLog/needMyApproval') {
        this.getCountTask()
      }
    }
  },
  created() {
    this.getCountTask()
  },
  methods: {
    getCountTask() {
      this.getCountMyTask().then((res) => {
        this.$store.commit('SET_COUNTTASK', res.rows)
      })
    },
    toggleSideBarClick() {
      this.collapse = !this.collapse
    },
    goHome() {
      // this.$router.push("/");
      window.open(process.env.VUE_APP_JUMP_URL)
    },
    // idt注销
    logOutIdt(){
      if (localStorage.getItem("idt_token") && localStorage.getItem("access_pp_token")) {
          logout(localStorage.getItem("idt_token"),localStorage.getItem("access_pp_token")).then(res=>{
            localStorage.removeItem('access_token_mh');
            localStorage.removeItem('idt_token');
            Lockr.set('access_pp_token', '')
            localStorage.removeItem('sp_info')
            removeAuth();
            localStorage.removeItem('access_pp_token');
            localStorage.removeItem('userInfo')
            // 清除store
            this.$store.commit("SET_USERINFO", {});
            this.$store.commit("SET_SPUSERINFO", {});
            if (process.env.NODE_ENV == 'development') {
              this.$router.replace('/login')
            } else {
              window.location.replace(process.env.VUE_APP_JUMP_URL+'?status=logOutIdt')
            }
          }).catch(()=>{
            this.$message({
              message: "Unregistration failed.",
              type: "warning",
            });
          })
      }else{
        this.$message({
          message: "Exiting status, we will refresh the page for you later.",
          type: "warning",
          onClose: () => {
            window.location.reload();
          },
        });
      }
    },
    handleCommand(val) {
      if (val == 'user') {
        this.$router.push({
          name: 'person'
        })
      } else if (val == 'loginOut') {
        this.$confirm(this.$t('other.3'), this.$t('other.1'), {
          confirmButtonText: this.$t('other.4'),
          cancelButtonText: this.$t('other.5'),
          type: 'warning'
        })
          .then(() => {
            // logout(Lockr.get('access_pp_token'))
            //   .then((res) => {
            //     Lockr.set('access_pp_token', '')
            //     delete axios.defaults.headers['Authorization']
            //     if (process.env.NODE_ENV == 'development') {
            //       this.$router.replace('/login')
            //     } else {
            //       window.location.replace(process.env.VUE_APP_JUMP_URL)
            //     }
            //   })
            //   .catch((error) => {
            //     Lockr.set('access_pp_token', '')
            //     delete axios.defaults.headers['Authorization']
            //     if (process.env.NODE_ENV == 'development') {
            //       this.$router.replace('/login')
            //     } else {
            //       window.location.replace(process.env.VUE_APP_JUMP_URL)
            //     }
            //   })
            this.logOutIdt();
          })
          .catch(() => {})
      }
    },
    getFullPath(path) {
      return `/${this.mainRouter}/${path}`
    },

    getShowMenu(array) {
      return array.filter((item) => {
        return !item.hidden
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-container {
  min-height: 0;
}
.el-menu--vertical {
  .menuIcon {
    font-size: 16px;
    color: #a6b0cf;
    margin-right: 8px;
  }
}
.aside-container {
  position: relative;
  background-color: #2d3037;
  box-sizing: border-box;
  border-right: solid 1px #e6e6e6;
  height: 100%;
  .sidebar-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    // padding: 15px 24px 15px 20px;
    cursor: pointer;
    .collapse-button {
      color: white;
      font-size: 20px;
      vertical-align: middle;
    }
  }
  .el-menu-vertical:not(.el-menu--collapse) {
    width: 230px;
    min-height: 400px;
  }
  .el-menu-vertical {
    height: 100%;
    overflow: auto;
    padding-bottom: 48px;
    .menuIcon {
      font-size: 16px;
      color: #a6b0cf;
      margin-right: 8px;
    }
    .menuTitle {
      color: #a6b0cf;
    }
    .el-submenu.is-active {
      .el-submenu__title {
        .el-icon {
          color: white;
        }
        .menuTitle {
          color: white;
        }
      }
    }
  }
  // .menu-item-icon-container {
  //   display: inline-block;
  //   margin-right: 10px;
  //   .menu-item-icon-flex {
  //     width: 22px;
  //     height: 22px;
  //     position: relative;
  //     .menu-item-icon {
  //       display: block;
  //     }
  //   }
  // }
  .menu-item-defalt {
    border-left: 2px solid transparent;
    height: 46px;
    line-height: 46px;
  }
  .menu-item-select {
    border-left: 2px solid #3e84e9;
    background-color: #454e57 !important;
    .el-icon {
      color: white;
    }
    .menuTitle {
      color: white;
    }
  }
}
.handel-items {
  padding: 10px 0 18px 0;
  .handel-item {
    padding: 5px 20px;
    font-size: 14px;
    color: #aaa;
    cursor: pointer;
    i {
      margin-right: 8px;
      font-size: 15px;
    }
  }
  .handel-item:hover {
    background-color: #f7f8fa;
    color: #3e84e9;
  }
  .handel-box {
    padding: 0 15px;
    .handel-button {
      width: 100%;
      border-radius: 4px;
      border-color: #009df0;
      background-color: #009df0;
    }
  }
}
.user-dropdown {
  .el-dropdown-menu__item {
    i {
      font-size: 16px;
      margin-right: 10px !important;
    }
  }
}
.el-badge {
  position: absolute;
  right: 15px;
  top: 0px;
  /deep/ .el-badge__content {
    border-width: 0;
  }
}
.navbar {
  height: 70px !important;
  min-height: 60px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 25px 0 0 !important;
  .navbar-left {
    display: flex;
    align-items: center;
    .logo {
      width: 150px;
      height: 42px;
      display: block;
      flex-shrink: 0;
      margin: 0 25px;
    }
    .search {
      width: 214px;
      height: 38px;
      .el-input__inner {
        border-radius: 19px;
        background: rgb(243, 243, 249);
      }
      .el-input__prefix {
        color: #74788d;
      }
    }
  }
  .navbar-right {
    display: flex;
    align-items: center;
    .user-container {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;
      &:hover {
        .mark {
          color: #2486e4;
        }
      }
      .user-img {
        display: block;
        width: 32px;
        min-width: 32px;
        min-height: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 50%;
      }
      .mark {
        margin-left: 8px;
        font-size: 15px;
        color: #74788d;
      }
    }
  }
}
</style>
