<template>
  <div id="app" v-if="loading">
    <router-view class="router-view" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Lockr from "lockr";
import { addAuth } from "@/utils/auth";
export default {
  name: "App",
  data() {
    return {
      loading: false,
      timeInter: "",
    };
  },
  created() {
    let token = location.href.match(/token=[0-9a-zA-Z_%]+/)
      ? location.href.match(/token=[0-9a-zA-Z_%-]+/)[0].split("=")[1]
      : "";
    let hash = location.href.match(/\/#\/[0-9a-zA-Z\/]+/)
      ? location.href.match(/\/#\/[0-9a-zA-Z\/]+/)[0].split("#")[1]
      : "";
    let open = location.href.match(/open=[0-9a-zA-Z_%]+/)
      ? location.href.match(/open=[0-9a-zA-Z_%]+/)[0].split("=")[1]
      : "";
    window.console.log(process.env.NODE_ENV);
    if (token && !Lockr.get("access_pp_token")) {
      token = decodeURIComponent(token);
      delete axios.defaults.headers["Authorization"];
      new Promise((resolve, reject) => {
        Lockr.set("access_pp_token", token);
        addAuth(token).then(() => {
          resolve();
        });
      })
        .then(() => {
          // this.loading = true;
          // let replaceUrl =
          //   location.origin +
          //   (process.env.NODE_ENV == "production" ? "/" : "/pp");
          // location.replace(replaceUrl);
          this.loading = true;
          this.timeInter = window.setInterval(() => {
            if (hash != this.$route.path) {
              if (open) {
                this.$router.push({ path: hash, query: { open } });
              } else {
                this.$router.push({ path: hash });
              }
            } else {
              window.clearInterval(this.timeInter);
            }
          }, 100);
        })
        .catch(() => {});
    } else {
      this.loading = true;
      // if (hash) {
      //   this.$nextTick(() => {

      //   })
      // }
    }
  },
  mounted() {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "EN");
    }
  },
  beforeDestroy() {
    window.clearInterval(this.timeInter)
  },
  watch: {
    $route(to, from) {
      if (to.meta.menuIndex) {
        this.$store.commit("SET_ACTIVEINDEX", to.meta.menuIndex);
      } else {
        this.$store.commit("SET_ACTIVEINDEX", to.path);
      }
      this.$store.commit("SET_ACTIVENAME", to.meta.fullName);
    },
  },
};
</script>
<style lang="less">
#app {
  width: 100%;
  position: relative;
  height: 100%;
}
.el-popover--plain,
.el-message-box__message {
  white-space: pre-wrap;
}
</style>
