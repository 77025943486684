<template>
  <div class="section">
    <div v-if="title && title.length > 0" class="section-header">
      <div style="display: flex; align-items: center">
        <slot name="left">
          <div class="section-mark" v-if="!noShowMark" />
          <span v-if="showMust" style="color:#F56C6C;">*</span>
          <div :class="!noShowMark ? 'section-title1' : 'section-title2'">
            {{ title }}
          </div>
        </slot>
      </div>
      <div>
        <slot name="right" />
      </div>
    </div>
    <div class="content">
      <slot />
    </div>
    <el-divider v-if="!noShowLine"></el-divider>
  </div>
</template>

<script>
export default {
  name: "CreateSections",
  props: {
    title: {
      type: String,
      default: "",
    },
    // 不显示圆圈
    noShowMark: {
      type: Boolean,
      default: false,
    },
    // 不显示分割线
    noShowLine: {
      type: Boolean,
      default: false,
    },
    //必填标志
    showMust:{
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="less" scoped>
.section {
  position: relative;
  background-color: white;
  margin-top: 8px;
  &:first-child {
    margin-top: 0;
  }
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    .section-mark {
      width: 14px;
      height: 14px;
      background: #ffffff;
      border: 2px solid #556ee6;
      border-radius: 50%;
      opacity: 1;
      margin-right: 8px;
    }
    .section-title1 {
      font-size: 16px;
      font-family: Alibaba PuHuiTi;
      font-weight: 600;
      line-height: 22px;
      color: #343a40;
      opacity: 1;
    }
    .section-title2 {
      font-size: 18px;
      font-family: Alibaba PuHuiTi;
      font-weight: 500;
      line-height: 25px;
      color: #343a40;
      opacity: 1;
    }
  }
  .content {
    padding: 0 20px;
  }
  .el-divider {
    width: 99%;
  }
}
</style>