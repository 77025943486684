import Vue from 'vue'
import { Message } from 'element-ui'
import { getMenu, getUserInfo } from '@/api/common'
import { removeAuth } from '@/utils/auth'
// 处理动态路由数组
function formatRoutes (aMenu = []) {
  const aRouter = []
  if (aMenu.length === 0) return
  for (let i = 0; i < aMenu.length; i++) {
    const oMenu = aMenu[i]
    const children = oMenu.children
    const isChild = children && children.length !== 0
    const oRouter = {
      path: oMenu.path,
      icon: oMenu.icon,
      name: oMenu.name,
      redirect: oMenu.redirect ?? '',
      component (resolve) {
        require([`@/${oMenu.component}.vue`], resolve)
      },
      children: isChild
        ? (() => {
          let child = []
          children.map(ele => {
            child.push({
              component (resolve) {
                require([`@/${ele.component}.vue`], resolve)
              },
              path: ele.path,
              name: oMenu.name,
              title: ele.title,
              meta: {
                fullName: [oMenu.title, ele.title]
              }
            })
          })
          return child
        })()
        : []
    }
    aRouter.push(oRouter)
  }
  return aRouter
}

const permission = {
  state: {
    addRouters: [],
    crmMenu: {}
  },
  mutations: {
    SET_MENU: (state, menuList) => {
      state.crmMenu = menuList
    },
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        getUserInfo().then(res => {
          let permissions = res.user.permissions
          console.log('===========res.user======>>>>>>>', res.user)
          commit('SET_USERINFO', res.user)
          commit('SET_PERMISSIN', permissions)
        })
        getMenu({
          clientId: 'gsm-pp'
        }).then(res => {
          let menuList = []
          if (Array.isArray(res.menuList) && res.menuList.length > 0) {
            // 多级菜单可以用上
            // function getLastChildrenRedirect (arr) {
            //   let path = ''
            //   path += arr.path
            //   if (Array.isArray(arr.children) && arr.children.length > 0) {
            //     path += '/' + getLastChildrenRedirect(arr.children[0])
            //   }
            //   return path
            // }
            let redirect = ''
            // let fff=res.menuList
            menuList = res.menuList.map((e, index) => {
              console.log(e);
              if (index == 0) {
                if (Array.isArray(e.children) && e.children.length > 0) {
                  redirect = e.path + '/' + e.children[0].path
                } else if (e.path) {
                  redirect = e.path
                } else {
                  e.redirect = '/404'
                }
              }
              e.name = e.path.split('/')[1]
              e.fullPath = e.path
              if (Array.isArray(e.children) && e.children.length > 0) {
                e.redirect = e.path + '/' + e.children[0].path
                e.children.forEach(item => {
                  item.fullPath = e.path + '/' + item.path
                })
              } else if (e.path) {
                e.redirect = e.path
              } else {
                e.redirect = '/404'
              }
              return e
            })
            let accessedRouters = formatRoutes(menuList)
            accessedRouters.push({
              path: '/',
              redirect: redirect,
              hidden: true
            })
            commit('SET_MENU', menuList)
            commit('SET_ROUTERS', accessedRouters)
            resolve()
          } else {
            Message({
              message: 'No permissions',
              type: 'error',
              duration: 10 * 1000
            })
            setTimeout(() => {
              removeAuth()
                .then(() => {
                  location.reload() //  重新实例化vue-router对象
                })
                .catch(() => {
                  location.reload()
                })
            }, 350)
          }
        })
      })
    }
  }
}

export default permission
