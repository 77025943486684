<template>
  <flexbox class="CRMDetailHeader"
           direction="column">
    <div class="header">
      <div style="display: flex; align-items: center">
        <slot name="left">
          <div class="header-title">
            {{ title }}
          </div>
        </slot>
      </div>
      <div class="right">
        <slot name="right" />
        <img class="t-close"
             src="@/assets/img/task_close.png"
             @click="hideView"
             :style="{ 'z-index': zIndex }" />
      </div>
    </div>
    <div class="content">
      <slot name="content">
        <flexbox v-if="!showTitle"
                 class="h-section"
                 align="stretch">
          <flexbox-item v-for="(item, index) in headDetails"
                        :key="index"
                        class="h-item"
                        span="300">
            <div class="h-title">{{ item.title }}</div>
            <div class="h-value text-one-line"
                 :style="item.style">
              {{ item.value || "--" }}
            </div>
          </flexbox-item>
        </flexbox>
      </slot>
    </div>
    <slot />
  </flexbox>
</template>

<script>
import { getMaxIndex } from "@/utils/index";
export default {
  name: "CRMDetailHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
    headDetails: {
      type: Array,
      default: () => {
        return [];
      },
    },
    crmType: {
      type: String,
      default: "",
    },
  },
  computed: {
    showTitle () {
      if (
        this.crmType == "approvalEdit" ||
        this.crmType == "MyDraft" ||
        this.crmType == "packageDetail" ||
        this.crmType == "deviceDetail"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  data () {
    return {
      zIndex: getMaxIndex(),
    };
  },
  methods: {
    hideView () {
      this.$emit("hideView");
    },
  },
};
</script>

<style lang="less" scoped>
.CRMDetailHeader {
  position: relative;
  background-color: white;
  margin-top: 0;
  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 10px 17px;
    min-height: 60px;
    .header-title {
      font-size: 18px;
      font-family: Alibaba PuHuiTi;
      font-weight: 600;
      line-height: 25px;
      color: #343a40;
      opacity: 1;
    }
    .right {
      display: flex;
      align-items: center;
      .t-close {
        // display: block;
        width: 40px;
        height: 40px;
        margin-left: 20px;
        padding: 10px;
        cursor: pointer;
      }
    }
  }
  .content {
    width: 100%;
    padding: 0 20px;
  }
  .h-section {
    position: relative;
    padding: 8px 17px 15px 17px;
    min-height: 58px;
    .h-item {
      .h-title {
        font-size: 12px;
        color: #777;
      }
      .h-value {
        min-height: 14px;
        margin-top: 8px;
        font-size: 13px;
        color: #333333;
      }
    }
  }
  /deep/ .t-section .t-more {
    height: 30px;
  }
  /deep/ .vux-flexbox .vux-flexbox-item {
    flex: 1 !important;
  }
}
</style>