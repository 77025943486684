import request from '@/utils/request'

// 所有借测设备 Package 列表
export function getPackageList (data) {
  return request({
    url: '/sf-partnerportal/pp/package/list',
    method: 'post',
    data: data
  })
}

// 所有借测设备 Package 详情
export function packageInfo (data) {
  return request({
    url: '/sf-partnerportal/pp/package/info',
    method: 'post',
    data: data
  })
}

// 所有借测设备 Device 列表
export function getDeviceList (data) {
  return request({
    url: '/sf-partnerportal/pp/device/list',
    method: 'post',
    data: data
  })
}

// 所有借测设备 Device 详情
export function getDeviceInfo (data) {
  return request({
    url: '/sf-partnerportal/pp/device/info',
    method: 'post',
    data: data
  })
}

// 模糊匹配商机信息
export function getByLikeProject (data) {
  return request({
    url: '/sf-partnerportal/pp/project/getByLikeProject',
    method: 'post',
    data: data
  })
}

// 模糊匹配package
export function getPackageByLikeName (data) {
  return request({
    url: '/sf-partnerportal/pp/package/getPackageByLikeName',
    method: 'post',
    data: data
  })
}

// 获取产品三级结构
export function getProductMultiStruction (data) {
  return request({
    url: '/api-gsm/crm/category/pp/list',
    method: 'post',
    data
  })
}
// 根据产品 Code 获取产品对应可选购物料
export function getProductInit (code) {
  return request({
    url: '/api-gsm/crm/productUnit/pp/' + code,
    method: 'get'
  })
}

// 渠道借测 根据产品 Code 获取产品对应可选购物料
export function getChannelProductUnit (code) {
  return request({
    url: '/api-gsm/crm/productUnit/ppChannel/' + code,
    method: 'get'
  })
}

/** 设备借测申请流程相关 */
// 设备借测申请新建接口
export function channelborrowSave (data) {
  return request({
    url: '/sf-partnerportal/pp/filedchannel/save',
    method: 'post',
    data
  })
}
// 设备借测申请编辑接口
export function channelborrowUpdate (data) {
  return request({
    url: '/sf-partnerportal/pp/filedchannel/update',
    method: 'post',
    data
  })
}
// 设备借测申请查项目
export function selectPartnerPortal (data) {
  return request({
    url: '/api-gsm/pp/channelborrow/selectPartnerPortal',
    method: 'post',
    data
  })
}
// 设备使用申请详情接口
export function channelborrowInfo (data) {
  return request({
    url: '/sf-partnerportal/pp/filedchannel/info',
    method: 'post',
    data
  })
}
// 设备使用申请详情删除接口
export function channelborrowRemove (data) {
  return request({
    url: '/api-gsm/pp/channelborrow/remove',
    method: 'post',
    data
  })
}
/** 设备借测申请流程相关 */

/** 设备授权相关接口  */
// 新增设备授权申请
export function equipmentauthSave (data) {
  return request({
    url: '/sf-partnerportal/pp/equipmentauth/save',
    method: 'post',
    data
  })
}
// 设备授权申请详情
export function equipmentauthInfo (data) {
  return request({
    url: '/sf-partnerportal/pp/equipmentauth/info',
    method: 'post',
    data
  })
}
// 设备授权申请编辑接口
export function equipmentauthUpdate (data) {
  return request({
    url: '/sf-partnerportal/pp/equipmentauth/update',
    method: 'post',
    data
  })
}
// 设备授权申请通过
export function equipmentauthDoApproveEAF (data) {
  return request({
    url: '/sf-partnerportal/pp/equipmentauth/doApproveEAF',
    method: 'post',
    data
  })
}
// 设备授权记录
export function authlogsList (data) {
  return request({
    url: '/sf-partnerportal/pp/authlogs/list',
    method: 'post',
    data
  })
}
/** 设备授权相关接口  */

// 设备归还
export function packageReturn (data) {
  return request({
    url: '/sf-partnerportal/pp/package/return',
    method: 'post',
    data
  })
}
// 设备归还编辑
export function packageReturnUpdate (data) {
  return request({
    url: '/sf-partnerportal/pp/package/update/return',
    method: 'post',
    data
  })
}
// 设备退还、退押金详情
export function packageApproveInfo (data) {
  return request({
    url: '/sf-partnerportal/pp/package/approveInfo',
    method: 'post',
    data
  })
}
// 设备授权申请页面拿项目信息
export function getBusinessByBorrowId (data) {
  return request({
    url: '/sf-partnerportal/pp/equipmentauth/getByBorrowId',
    method: 'post',
    data
  })
}
// 设备退押金
export function packageRefund (data) {
  return request({
    url: '/sf-partnerportal/pp/package/refund',
    method: 'post',
    data
  })
}
// 设备退押金编辑
export function packageRefundUpdate (data) {
  return request({
    url: '/sf-partnerportal/pp/package/update/refund',
    method: 'post',
    data
  })
}
