import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  },
  {
    path: '/person',
    component: () => import('@/views/PersonCenter/index'),
    name: 'person',
    hidden: true,
    meta: {
      title: '个人中心'
    }
  }
]
if (process.env.NODE_ENV == 'development') {
  routes.push(
    {
      path: '/login',
      component: () => import('@/views/login/index'),
      hidden: true
    },
    {
      path: '/redictLogin',
      component: () => import('@/views/login/index'),
      hidden: true
    }
  )
}

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0
  }),
  routes
})
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default router
